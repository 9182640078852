import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type RenameTaskFolderTemplateMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  name: Types.Scalars['String']['input'];
}>;

export type RenameTaskFolderTemplateMutation = {
  __typename?: 'Mutation';
  renameTaskFolderTemplate?: { __typename?: 'TaskFolderTemplate'; id: string } | null;
};

export const RenameTaskFolderTemplateDocument = gql`
  mutation RenameTaskFolderTemplate($id: ID!, $name: String!) {
    renameTaskFolderTemplate(id: $id, name: $name) {
      id
    }
  }
`;

export function useRenameTaskFolderTemplateMutation() {
  return Urql.useMutation<
    RenameTaskFolderTemplateMutation,
    RenameTaskFolderTemplateMutationVariables
  >(RenameTaskFolderTemplateDocument);
}
