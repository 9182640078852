import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AcceptCustomerPortalEventDocumentMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;

export type AcceptCustomerPortalEventDocumentMutation = {
  __typename?: 'Mutation';
  acceptCustomerPortalEventDocument: {
    __typename?: 'ResponseMessage';
    success?: boolean | null;
    message?: string | null;
  };
};

export const AcceptCustomerPortalEventDocumentDocument = gql`
  mutation AcceptCustomerPortalEventDocument($id: ID!) {
    acceptCustomerPortalEventDocument(id: $id) {
      success
      message
    }
  }
`;

export function useAcceptCustomerPortalEventDocumentMutation() {
  return Urql.useMutation<
    AcceptCustomerPortalEventDocumentMutation,
    AcceptCustomerPortalEventDocumentMutationVariables
  >(AcceptCustomerPortalEventDocumentDocument);
}
