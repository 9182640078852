import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CreateTaskLogMutationVariables = Types.Exact<{
  taskId: Types.Scalars['ID']['input'];
  title: Types.Scalars['String']['input'];
}>;

export type CreateTaskLogMutation = {
  __typename?: 'Mutation';
  createTaskLog: {
    __typename?: 'TaskLog';
    id: string;
    title: string;
    client?: { __typename?: 'Client'; name: string } | null;
  };
};

export const CreateTaskLogDocument = gql`
  mutation CreateTaskLog($taskId: ID!, $title: String!) {
    createTaskLog(task_id: $taskId, input: { title: $title }) {
      id
      title
      client {
        name
      }
    }
  }
`;

export function useCreateTaskLogMutation() {
  return Urql.useMutation<CreateTaskLogMutation, CreateTaskLogMutationVariables>(
    CreateTaskLogDocument,
  );
}
