import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CheckIfIdentifierExistsQueryVariables = Types.Exact<{
  identifier: Types.Scalars['String']['input'];
}>;

export type CheckIfIdentifierExistsQuery = {
  __typename?: 'Query';
  checkIfIdentifierExists: boolean;
};

export const CheckIfIdentifierExistsDocument = gql`
  query CheckIfIdentifierExists($identifier: String!) {
    checkIfIdentifierExists(identifier: $identifier)
  }
`;

export function useCheckIfIdentifierExistsQuery(
  options: Omit<Urql.UseQueryArgs<CheckIfIdentifierExistsQueryVariables>, 'query'>,
) {
  return Urql.useQuery<CheckIfIdentifierExistsQuery, CheckIfIdentifierExistsQueryVariables>({
    query: CheckIfIdentifierExistsDocument,
    ...options,
  });
}
