import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ReorderTaskCalculationItemsMutationVariables = Types.Exact<{
  order: Array<Types.Scalars['Int']['input']> | Types.Scalars['Int']['input'];
}>;

export type ReorderTaskCalculationItemsMutation = {
  __typename?: 'Mutation';
  reorderTaskCalculationItems: Array<{
    __typename?: 'TaskCalculationItem';
    id: string;
    title?: string | null;
    description?: string | null;
    accepted?: number | null;
    amount?: number | null;
    single_price?: number | null;
    sort_index?: number | null;
    time_value?: number | null;
    unit?: { __typename?: 'ServiceCatalogUnit'; id: string; title: string } | null;
    category?: {
      __typename?: 'TaskCalculationCategory';
      id: string;
      title: string;
      sort_index: number;
    } | null;
  }>;
};

export const ReorderTaskCalculationItemsDocument = gql`
  mutation ReorderTaskCalculationItems($order: [Int!]!) {
    reorderTaskCalculationItems(order: $order) {
      id
      title
      description
      unit {
        id
        title
      }
      category {
        id
        title
        sort_index
      }
      accepted
      amount
      single_price
      sort_index
      time_value
    }
  }
`;

export function useReorderTaskCalculationItemsMutation() {
  return Urql.useMutation<
    ReorderTaskCalculationItemsMutation,
    ReorderTaskCalculationItemsMutationVariables
  >(ReorderTaskCalculationItemsDocument);
}
