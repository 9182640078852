import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type SendInterfaceMessageFromTemplateMutationVariables = Types.Exact<{
  taskId: Types.Scalars['ID']['input'];
  templateId: Types.Scalars['ID']['input'];
}>;

export type SendInterfaceMessageFromTemplateMutation = {
  __typename?: 'Mutation';
  sendDirectMessageFromInterfaceMessageTemplate: {
    __typename?: 'ResponseMessage';
    success?: boolean | null;
    message?: string | null;
  };
};

export const SendInterfaceMessageFromTemplateDocument = gql`
  mutation SendInterfaceMessageFromTemplate($taskId: ID!, $templateId: ID!) {
    sendDirectMessageFromInterfaceMessageTemplate(
      task_id: $taskId
      interface_message_template_id: $templateId
    ) {
      success
      message
    }
  }
`;

export function useSendInterfaceMessageFromTemplateMutation() {
  return Urql.useMutation<
    SendInterfaceMessageFromTemplateMutation,
    SendInterfaceMessageFromTemplateMutationVariables
  >(SendInterfaceMessageFromTemplateDocument);
}
