import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateTaskCalculationCategoryMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  title: Types.Scalars['String']['input'];
  sort_index: Types.Scalars['Int']['input'];
}>;

export type UpdateTaskCalculationCategoryMutation = {
  __typename?: 'Mutation';
  updateTaskCalculationCategory: {
    __typename?: 'TaskCalculationCategory';
    id: string;
    title: string;
    sort_index: number;
  };
};

export const UpdateTaskCalculationCategoryDocument = gql`
  mutation UpdateTaskCalculationCategory($id: ID!, $title: String!, $sort_index: Int!) {
    updateTaskCalculationCategory(id: $id, input: { title: $title, sort_index: $sort_index }) {
      id
      title
      sort_index
    }
  }
`;

export function useUpdateTaskCalculationCategoryMutation() {
  return Urql.useMutation<
    UpdateTaskCalculationCategoryMutation,
    UpdateTaskCalculationCategoryMutationVariables
  >(UpdateTaskCalculationCategoryDocument);
}
