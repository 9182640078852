import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import { TaskProposalFragmentDoc } from './taskProposalFragment.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type TaskProposalsQueryVariables = Types.Exact<{
  task_id: Types.Scalars['ID']['input'];
}>;

export type TaskProposalsQuery = {
  __typename?: 'Query';
  taskProposals: {
    __typename?: 'TaskProposalsPaginator';
    data: Array<{
      __typename?: 'TaskProposal';
      id: string;
      task_id: string;
      external_proposal_id?: string | null;
      external_proposal_number?: string | null;
      external_proposal_url?: string | null;
      external_id?: number | null;
      date: string;
      state_id: Types.TaskProposalStateId;
      note?: string | null;
      external_number: string;
      identifier: string;
      address: string;
      sum_gross: number;
      sum_net: number;
      sum_tax: number;
      url_preview: string;
      permissions: Array<Types.Permission>;
      validity_date?: string | null;
      custom_name?: string | null;
      custom_street?: string | null;
      custom_city?: string | null;
      custom_postcode?: string | null;
      task: { __typename?: 'Task'; identifier?: string | null };
      state: { __typename?: 'TaskProposalState'; id: string; title: string; color: string };
      items: Array<{
        __typename?: 'TaskProposalItem';
        id: string;
        position: string;
        title: string;
        unit?: string | null;
        unit_price: number;
        amount: number;
        sum_net: number;
        sum_gross: number;
        tax: number;
      }>;
      file?: { __typename?: 'FileItem'; id: string; file_name: string; url: string } | null;
      custom_salutation?: { __typename?: 'Salutation'; id: string; title: string } | null;
      custom_country?: { __typename?: 'Country'; id: string; title: string } | null;
    }>;
  };
};

export const TaskProposalsDocument = gql`
  query TaskProposals($task_id: ID!) {
    taskProposals(task_id: $task_id) {
      data {
        ...TaskProposal
      }
    }
  }
  ${TaskProposalFragmentDoc}
`;

export function useTaskProposalsQuery(
  options: Omit<Urql.UseQueryArgs<TaskProposalsQueryVariables>, 'query'>,
) {
  return Urql.useQuery<TaskProposalsQuery, TaskProposalsQueryVariables>({
    query: TaskProposalsDocument,
    ...options,
  });
}
