import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AssignTaskLeaderMutationVariables = Types.Exact<{
  task_id: Types.Scalars['ID']['input'];
  leader_client_id?: Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;

export type AssignTaskLeaderMutation = {
  __typename?: 'Mutation';
  assignTaskLeader?: { __typename?: 'Task'; id: string } | null;
};

export const AssignTaskLeaderDocument = gql`
  mutation AssignTaskLeader($task_id: ID!, $leader_client_id: ID) {
    assignTaskLeader(task_id: $task_id, leader_client_id: $leader_client_id) {
      id
    }
  }
`;

export function useAssignTaskLeaderMutation() {
  return Urql.useMutation<AssignTaskLeaderMutation, AssignTaskLeaderMutationVariables>(
    AssignTaskLeaderDocument,
  );
}
