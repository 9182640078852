import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ReworkTaskMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;

export type ReworkTaskMutation = {
  __typename?: 'Mutation';
  reworkTask?: {
    __typename?: 'ResponseMessage';
    success?: boolean | null;
    message?: string | null;
  } | null;
};

export const ReworkTaskDocument = gql`
  mutation ReworkTask($id: ID!) {
    reworkTask(id: $id) {
      success
      message
    }
  }
`;

export function useReworkTaskMutation() {
  return Urql.useMutation<ReworkTaskMutation, ReworkTaskMutationVariables>(ReworkTaskDocument);
}
