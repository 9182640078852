import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import { AdditionalTaskFormFragmentDoc } from './additionalTaskFormFragment.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type TaskFormsQueryVariables = Types.Exact<{
  taskId: Types.Scalars['ID']['input'];
  typeIds?: Types.InputMaybe<Array<Types.TaskFormTypeId> | Types.TaskFormTypeId>;
  withRemoved?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;

export type TaskFormsQuery = {
  __typename?: 'Query';
  taskForms: {
    __typename?: 'TaskFormsResponse';
    data: Array<{
      __typename?: 'TaskForm';
      id: string;
      updated_at: string;
      title: string;
      sort_index: number;
      removed_at?: string | null;
      type: { __typename?: 'TaskFormType'; id: string; type_id: Types.TaskFormTypeId };
      template: {
        __typename?: 'TaskTemplate';
        id: string;
        title: string;
        description?: string | null;
        type_id: Types.TaskTemplateTypeId;
      };
      tags?: Array<{
        __typename?: 'Tag';
        id: string;
        title: string;
        color: string;
        type_id: string;
        type: { __typename?: 'TagType'; id: string; title: string; color: string };
      }> | null;
    }>;
  };
};

export const TaskFormsDocument = gql`
  query TaskForms($taskId: ID!, $typeIds: [TaskFormTypeId!], $withRemoved: Boolean) {
    taskForms(task_id: $taskId, type_ids: $typeIds, with_removed: $withRemoved) {
      data {
        ...AdditionalTaskForm
      }
    }
  }
  ${AdditionalTaskFormFragmentDoc}
`;

export function useTaskFormsQuery(
  options: Omit<Urql.UseQueryArgs<TaskFormsQueryVariables>, 'query'>,
) {
  return Urql.useQuery<TaskFormsQuery, TaskFormsQueryVariables>({
    query: TaskFormsDocument,
    ...options,
  });
}
