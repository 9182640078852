import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DeleteTaskFormQuestionCommentMutationVariables = Types.Exact<{
  task_form_id: Types.Scalars['ID']['input'];
  question_id: Types.Scalars['String']['input'];
}>;

export type DeleteTaskFormQuestionCommentMutation = {
  __typename?: 'Mutation';
  deleteTaskFormQuestionComment: {
    __typename?: 'TaskQuestion';
    id: string;
    comment?: string | null;
  };
};

export const DeleteTaskFormQuestionCommentDocument = gql`
  mutation DeleteTaskFormQuestionComment($task_form_id: ID!, $question_id: String!) {
    deleteTaskFormQuestionComment(task_form_id: $task_form_id, question_id: $question_id) {
      id
      comment
    }
  }
`;

export function useDeleteTaskFormQuestionCommentMutation() {
  return Urql.useMutation<
    DeleteTaskFormQuestionCommentMutation,
    DeleteTaskFormQuestionCommentMutationVariables
  >(DeleteTaskFormQuestionCommentDocument);
}
