import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ToggleReworkDocumentVisibilityMutationVariables = Types.Exact<{
  taskId: Types.Scalars['ID']['input'];
  visible: Types.Scalars['Boolean']['input'];
}>;

export type ToggleReworkDocumentVisibilityMutation = {
  __typename?: 'Mutation';
  toggleReworkDocumentVisibility: {
    __typename?: 'ResponseMessage';
    success?: boolean | null;
    message?: string | null;
  };
};

export const ToggleReworkDocumentVisibilityDocument = gql`
  mutation ToggleReworkDocumentVisibility($taskId: ID!, $visible: Boolean!) {
    toggleReworkDocumentVisibility(task_id: $taskId, visible: $visible) {
      success
      message
    }
  }
`;

export function useToggleReworkDocumentVisibilityMutation() {
  return Urql.useMutation<
    ToggleReworkDocumentVisibilityMutation,
    ToggleReworkDocumentVisibilityMutationVariables
  >(ToggleReworkDocumentVisibilityDocument);
}
