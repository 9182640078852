import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import { AppModuleFragmentDoc } from './appModuleFragment.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateAppModuleMutationVariables = Types.Exact<{
  app_module_id: Types.Scalars['ID']['input'];
  input: Types.UpdateAppModuleInput;
}>;

export type UpdateAppModuleMutation = {
  __typename?: 'Mutation';
  updateAppModule: {
    __typename?: 'AppModule';
    id: string;
    title: string;
    description?: string | null;
    active: boolean;
    variables: Array<{
      __typename?: 'AppModuleSetting';
      id: string;
      title: string;
      description?: string | null;
      type: Types.AppModuleSettingType;
      key: string;
      value?: string | null;
    }>;
  };
};

export const UpdateAppModuleDocument = gql`
  mutation UpdateAppModule($app_module_id: ID!, $input: UpdateAppModuleInput!) {
    updateAppModule(app_module_id: $app_module_id, input: $input) {
      ...AppModule
    }
  }
  ${AppModuleFragmentDoc}
`;

export function useUpdateAppModuleMutation() {
  return Urql.useMutation<UpdateAppModuleMutation, UpdateAppModuleMutationVariables>(
    UpdateAppModuleDocument,
  );
}
