/**
 * Inserts a style element at a fixed position to retain the order of rules / specificity.
 *
 * ATTENTION: This code won't be transpiled, so stick to features that are available in every
 * browser environment we support.
 */
function insertThemeStyle(styleElement) {
  // eslint-disable-next-line no-undef
  const insertionNode = document.querySelector('#theme-insertion');

  if (!insertionNode) {
    throw new Error('Theme insertion element is missing.');
  }

  insertionNode.parentNode.insertBefore(styleElement, insertionNode.nextSibling);
}

export default insertThemeStyle;
