import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type RevertCancelTaskMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;

export type RevertCancelTaskMutation = {
  __typename?: 'Mutation';
  revertCancelTask?: {
    __typename?: 'ResponseMessage';
    success?: boolean | null;
    message?: string | null;
  } | null;
};

export const RevertCancelTaskDocument = gql`
  mutation RevertCancelTask($id: ID!) {
    revertCancelTask(id: $id) {
      success
      message
    }
  }
`;

export function useRevertCancelTaskMutation() {
  return Urql.useMutation<RevertCancelTaskMutation, RevertCancelTaskMutationVariables>(
    RevertCancelTaskDocument,
  );
}
