import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type TaskTagsResolveArrayQueryVariables = Types.Exact<{
  task_id: Types.Scalars['ID']['input'];
  tags: Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input'];
}>;

export type TaskTagsResolveArrayQuery = {
  __typename?: 'Query';
  taskTagsResolveArray: {
    __typename?: 'TaskTagsResolveArrayResponse';
    data: Array<{ __typename?: 'TaskTagResolveItem'; value?: string | null }>;
  };
};

export const TaskTagsResolveArrayDocument = gql`
  query TaskTagsResolveArray($task_id: ID!, $tags: [String!]!) {
    taskTagsResolveArray(task_id: $task_id, tags: $tags) {
      data {
        value
      }
    }
  }
`;

export function useTaskTagsResolveArrayQuery(
  options: Omit<Urql.UseQueryArgs<TaskTagsResolveArrayQueryVariables>, 'query'>,
) {
  return Urql.useQuery<TaskTagsResolveArrayQuery, TaskTagsResolveArrayQueryVariables>({
    query: TaskTagsResolveArrayDocument,
    ...options,
  });
}
