import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ExportTaskCalculationsMutationVariables = Types.Exact<{
  task_id: Types.Scalars['ID']['input'];
  config: Types.ExportConfigInput;
}>;

export type ExportTaskCalculationsMutation = {
  __typename?: 'Mutation';
  exportTaskCalculations: { __typename?: 'Export'; file_name: string; url: string };
};

export const ExportTaskCalculationsDocument = gql`
  mutation ExportTaskCalculations($task_id: ID!, $config: ExportConfigInput!) {
    exportTaskCalculations(task_id: $task_id, config: $config) {
      file_name
      url
    }
  }
`;

export function useExportTaskCalculationsMutation() {
  return Urql.useMutation<ExportTaskCalculationsMutation, ExportTaskCalculationsMutationVariables>(
    ExportTaskCalculationsDocument,
  );
}
