import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DuplicateTaskMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;

export type DuplicateTaskMutation = {
  __typename?: 'Mutation';
  duplicateTask: { __typename?: 'Task'; id: string };
};

export const DuplicateTaskDocument = gql`
  mutation DuplicateTask($id: ID!) {
    duplicateTask(id: $id) {
      id
    }
  }
`;

export function useDuplicateTaskMutation() {
  return Urql.useMutation<DuplicateTaskMutation, DuplicateTaskMutationVariables>(
    DuplicateTaskDocument,
  );
}
