import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateInvoiceMetaDataMutationVariables = Types.Exact<{
  file_id: Types.Scalars['ID']['input'];
  input: Types.FileItemInvoiceMetaDataInput;
}>;

export type UpdateInvoiceMetaDataMutation = {
  __typename?: 'Mutation';
  updateTaskFileProperties: Array<{ __typename?: 'FileItem'; id: string }>;
};

export const UpdateInvoiceMetaDataDocument = gql`
  mutation UpdateInvoiceMetaData($file_id: ID!, $input: FileItemInvoiceMetaDataInput!) {
    updateTaskFileProperties(files: [{ file_id: $file_id, properties: { invoice: $input } }]) {
      id
    }
  }
`;

export function useUpdateInvoiceMetaDataMutation() {
  return Urql.useMutation<UpdateInvoiceMetaDataMutation, UpdateInvoiceMetaDataMutationVariables>(
    UpdateInvoiceMetaDataDocument,
  );
}
