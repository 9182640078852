import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type TaskCustomReportSettingQueryVariables = Types.Exact<{
  task_id: Types.Scalars['ID']['input'];
}>;

export type TaskCustomReportSettingQuery = {
  __typename?: 'Query';
  taskCustomReportSetting?: {
    __typename?: 'TaskCustomReportSetting';
    expanded: boolean;
    title: string;
    manual_task_id?: string | null;
    use_alternate_logo: boolean;
    has_cover: boolean;
    has_summary: boolean;
    has_questions: boolean;
    has_calculation: boolean;
    has_images: boolean;
    has_documents: boolean;
    has_freetext: boolean;
    has_room_plan: boolean;
    has_rework_documents: boolean;
    questions_hide_description: boolean;
    has_toc: boolean;
    questions_alternate_title?: string | null;
    freetext_on_first_page: boolean;
    images_per_page: number;
    show_questions_without_answers: boolean;
    freetext_title?: string | null;
    freetext_description?: string | null;
    additional_form_ids: Array<string>;
    rework_form_ids: Array<string>;
    question_filter: Types.ReportQuestionFilterType;
    report_template_id: string;
    hidden_questions_id?: Array<{
      __typename?: 'TaskCustomReportSettingQuestion';
      question_id: string;
    }> | null;
  } | null;
};

export const TaskCustomReportSettingDocument = gql`
  query TaskCustomReportSetting($task_id: ID!) {
    taskCustomReportSetting(task_id: $task_id) {
      expanded
      title
      manual_task_id
      use_alternate_logo
      has_cover
      has_summary
      has_questions
      has_calculation
      has_images
      has_documents
      has_freetext
      has_room_plan
      has_rework_documents
      questions_hide_description
      has_toc
      questions_alternate_title
      freetext_on_first_page
      hidden_questions_id {
        question_id
      }
      images_per_page
      show_questions_without_answers
      freetext_title
      freetext_description
      additional_form_ids
      rework_form_ids
      question_filter
      report_template_id
    }
  }
`;

export function useTaskCustomReportSettingQuery(
  options: Omit<Urql.UseQueryArgs<TaskCustomReportSettingQueryVariables>, 'query'>,
) {
  return Urql.useQuery<TaskCustomReportSettingQuery, TaskCustomReportSettingQueryVariables>({
    query: TaskCustomReportSettingDocument,
    ...options,
  });
}
