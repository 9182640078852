import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CompleteTaskMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;

export type CompleteTaskMutation = {
  __typename?: 'Mutation';
  completeTask?: {
    __typename?: 'ResponseMessage';
    message?: string | null;
    success?: boolean | null;
  } | null;
};

export const CompleteTaskDocument = gql`
  mutation CompleteTask($id: ID!) {
    completeTask(id: $id) {
      message
      success
    }
  }
`;

export function useCompleteTaskMutation() {
  return Urql.useMutation<CompleteTaskMutation, CompleteTaskMutationVariables>(
    CompleteTaskDocument,
  );
}
