import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ReorderTaskDocumentsMutationVariables = Types.Exact<{
  taskId: Types.Scalars['ID']['input'];
  order: Array<Types.Scalars['Int']['input']> | Types.Scalars['Int']['input'];
}>;

export type ReorderTaskDocumentsMutation = {
  __typename?: 'Mutation';
  reorderTaskDocuments: {
    __typename?: 'TaskDocumentsPaginator';
    data: Array<{ __typename?: 'FileItem'; id: string }>;
  };
};

export const ReorderTaskDocumentsDocument = gql`
  mutation ReorderTaskDocuments($taskId: ID!, $order: [Int!]!) {
    reorderTaskDocuments(task_id: $taskId, order: $order) {
      data {
        id
      }
    }
  }
`;

export function useReorderTaskDocumentsMutation() {
  return Urql.useMutation<ReorderTaskDocumentsMutation, ReorderTaskDocumentsMutationVariables>(
    ReorderTaskDocumentsDocument,
  );
}
