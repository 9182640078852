import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ExportTaskFormMutationVariables = Types.Exact<{
  task_form_id: Types.Scalars['ID']['input'];
  config: Types.ExportConfigInput;
}>;

export type ExportTaskFormMutation = {
  __typename?: 'Mutation';
  exportTaskForm: {
    __typename?: 'ExportResponse';
    success: boolean;
    message: string;
    download?: {
      __typename?: 'Download';
      id: string;
      client_id: string;
      state_id: Types.DownloadStateId;
      filename: string;
      file_ids: Array<string>;
      url_download: string;
    } | null;
  };
};

export const ExportTaskFormDocument = gql`
  mutation ExportTaskForm($task_form_id: ID!, $config: ExportConfigInput!) {
    exportTaskForm(task_form_id: $task_form_id, config: $config) {
      success
      message
      download {
        id
        client_id
        state_id
        filename
        file_ids
        url_download
      }
    }
  }
`;

export function useExportTaskFormMutation() {
  return Urql.useMutation<ExportTaskFormMutation, ExportTaskFormMutationVariables>(
    ExportTaskFormDocument,
  );
}
