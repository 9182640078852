import { noop } from '@progress/kendo-react-common';

export function loadJsonFromLocalStorage<T>(key: string): T | undefined {
  try {
    const json = localStorage.getItem(key);
    if (json) {
      return JSON.parse(json);
    }
  } catch {
    noop();
  }
  return undefined;
}

export function saveJsonToLocalStorage<T>(key: string, data: T) {
  localStorage.setItem(key, JSON.stringify(data));
}
