import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DeleteTaskFolderTemplateMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;

export type DeleteTaskFolderTemplateMutation = {
  __typename?: 'Mutation';
  deleteTaskFolderTemplate?: { __typename?: 'TaskFolderTemplate'; id: string } | null;
};

export const DeleteTaskFolderTemplateDocument = gql`
  mutation DeleteTaskFolderTemplate($id: ID!) {
    deleteTaskFolderTemplate(id: $id) {
      id
    }
  }
`;

export function useDeleteTaskFolderTemplateMutation() {
  return Urql.useMutation<
    DeleteTaskFolderTemplateMutation,
    DeleteTaskFolderTemplateMutationVariables
  >(DeleteTaskFolderTemplateDocument);
}
