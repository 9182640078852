import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type PreviewTaskReportMutationVariables = Types.Exact<{
  task_id: Types.Scalars['ID']['input'];
  input: Types.PreviewTaskReportInput;
  options: Types.TaskReportOptionsInput;
}>;

export type PreviewTaskReportMutation = {
  __typename?: 'Mutation';
  previewTaskReport: {
    __typename?: 'TaskReportGeneration';
    id: string;
    filename: string;
    title: string;
    url_download?: string | null;
    url_preview?: string | null;
    state_id: Types.TaskReportStateId;
  };
};

export const PreviewTaskReportDocument = gql`
  mutation PreviewTaskReport(
    $task_id: ID!
    $input: PreviewTaskReportInput!
    $options: TaskReportOptionsInput!
  ) {
    previewTaskReport(task_id: $task_id, input: $input, options: $options) {
      id
      filename
      title
      url_download
      url_preview
      state_id
    }
  }
`;

export function usePreviewTaskReportMutation() {
  return Urql.useMutation<PreviewTaskReportMutation, PreviewTaskReportMutationVariables>(
    PreviewTaskReportDocument,
  );
}
