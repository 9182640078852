import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateTaskDriveLogMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  input: Types.UpdateTaskDriveLogInput;
}>;

export type UpdateTaskDriveLogMutation = {
  __typename?: 'Mutation';
  updateTaskDriveLog: {
    __typename?: 'Task';
    drive_log_distance?: number | null;
    drive_log_note?: string | null;
    initial_mileage?: number | null;
    final_mileage?: number | null;
  };
};

export const UpdateTaskDriveLogDocument = gql`
  mutation UpdateTaskDriveLog($id: ID!, $input: UpdateTaskDriveLogInput!) {
    updateTaskDriveLog(id: $id, input: $input) {
      drive_log_distance
      drive_log_note
      initial_mileage
      final_mileage
    }
  }
`;

export function useUpdateTaskDriveLogMutation() {
  return Urql.useMutation<UpdateTaskDriveLogMutation, UpdateTaskDriveLogMutationVariables>(
    UpdateTaskDriveLogDocument,
  );
}
