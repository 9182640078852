import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ExportMagicplanMutationVariables = Types.Exact<{
  taskId: Types.Scalars['ID']['input'];
}>;

export type ExportMagicplanMutation = {
  __typename?: 'Mutation';
  exportMagicPlan: { __typename?: 'TaskRoomMagicPlanJSON'; data?: any | null };
};

export const ExportMagicplanDocument = gql`
  mutation ExportMagicplan($taskId: ID!) {
    exportMagicPlan(task_id: $taskId) {
      data
    }
  }
`;

export function useExportMagicplanMutation() {
  return Urql.useMutation<ExportMagicplanMutation, ExportMagicplanMutationVariables>(
    ExportMagicplanDocument,
  );
}
