import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CreateTaskCalculationItemsFromCatalogMutationVariables = Types.Exact<{
  task_calculation_id: Types.Scalars['ID']['input'];
  task_calculation_category_id: Types.Scalars['ID']['input'];
  service_catalog_item_ids: Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input'];
}>;

export type CreateTaskCalculationItemsFromCatalogMutation = {
  __typename?: 'Mutation';
  createTaskCalculationItemsFromCatalog: {
    __typename?: 'ResponseMessage';
    success?: boolean | null;
    message?: string | null;
  };
};

export const CreateTaskCalculationItemsFromCatalogDocument = gql`
  mutation CreateTaskCalculationItemsFromCatalog(
    $task_calculation_id: ID!
    $task_calculation_category_id: ID!
    $service_catalog_item_ids: [ID!]!
  ) {
    createTaskCalculationItemsFromCatalog(
      task_calculation_id: $task_calculation_id
      task_calculation_category_id: $task_calculation_category_id
      service_catalog_item_ids: $service_catalog_item_ids
    ) {
      success
      message
    }
  }
`;

export function useCreateTaskCalculationItemsFromCatalogMutation() {
  return Urql.useMutation<
    CreateTaskCalculationItemsFromCatalogMutation,
    CreateTaskCalculationItemsFromCatalogMutationVariables
  >(CreateTaskCalculationItemsFromCatalogDocument);
}
