import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DeleteTaskMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  input: Types.DeleteTaskInput;
}>;

export type DeleteTaskMutation = {
  __typename?: 'Mutation';
  deleteTask: { __typename?: 'ResponseMessage'; success?: boolean | null; message?: string | null };
};

export const DeleteTaskDocument = gql`
  mutation DeleteTask($id: ID!, $input: DeleteTaskInput!) {
    deleteTask(id: $id, input: $input) {
      success
      message
    }
  }
`;

export function useDeleteTaskMutation() {
  return Urql.useMutation<DeleteTaskMutation, DeleteTaskMutationVariables>(DeleteTaskDocument);
}
