import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateTaskOwnersMutationVariables = Types.Exact<{
  taskId: Types.Scalars['ID']['input'];
  ownerClientId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  subOwnerClientIds: Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input'];
}>;

export type UpdateTaskOwnersMutation = {
  __typename?: 'Mutation';
  assignTaskOwner?: { __typename?: 'Task'; id: string } | null;
  assignTaskSubOwners?: { __typename?: 'Task'; id: string } | null;
};

export const UpdateTaskOwnersDocument = gql`
  mutation UpdateTaskOwners($taskId: ID!, $ownerClientId: ID, $subOwnerClientIds: [ID!]!) {
    assignTaskOwner(task_id: $taskId, owner_client_id: $ownerClientId) {
      id
    }
    assignTaskSubOwners(task_id: $taskId, sub_owner_client_ids: $subOwnerClientIds) {
      id
    }
  }
`;

export function useUpdateTaskOwnersMutation() {
  return Urql.useMutation<UpdateTaskOwnersMutation, UpdateTaskOwnersMutationVariables>(
    UpdateTaskOwnersDocument,
  );
}
