import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateTaskReminderDateMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  reminder_date?: Types.InputMaybe<Types.Scalars['DateTimeTz']['input']>;
}>;

export type UpdateTaskReminderDateMutation = {
  __typename?: 'Mutation';
  updateTaskReminderDate: { __typename?: 'Task'; id: string; reminder_date?: string | null };
};

export const UpdateTaskReminderDateDocument = gql`
  mutation UpdateTaskReminderDate($id: ID!, $reminder_date: DateTimeTz) {
    updateTaskReminderDate(id: $id, input: { reminder_date: $reminder_date }) {
      id
      reminder_date
    }
  }
`;

export function useUpdateTaskReminderDateMutation() {
  return Urql.useMutation<UpdateTaskReminderDateMutation, UpdateTaskReminderDateMutationVariables>(
    UpdateTaskReminderDateDocument,
  );
}
