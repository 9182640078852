import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type TaskRoomItemsQueryVariables = Types.Exact<{
  taskId: Types.Scalars['ID']['input'];
}>;

export type TaskRoomItemsQuery = {
  __typename?: 'Query';
  taskRoomItems: Array<{
    __typename?: 'TaskRoomItem';
    description?: string | null;
    id: string;
    size?: number | null;
    height?: number | null;
    width?: number | null;
    length?: number | null;
    uuid: string;
    task_id: string;
    title?: string | null;
    task_room_category_id: string;
    category?: { __typename?: 'TaskRoomCategory'; id: string; title: string; uuid: string } | null;
    service_catalog_items?: Array<{
      __typename?: 'ServiceCatalogItem';
      id: string;
      identifier: string;
      title: string;
      price?: number | null;
      unit_id: string;
      unit: { __typename?: 'ServiceCatalogUnit'; id: string; title: string };
      category: { __typename?: 'ServiceCatalogCategory'; id: string; title: string };
    }> | null;
  }>;
  taskRoomCategories: Array<{
    __typename?: 'TaskRoomCategory';
    id: string;
    title: string;
    uuid: string;
    task_id: string;
  }>;
};

export const TaskRoomItemsDocument = gql`
  query TaskRoomItems($taskId: ID!) {
    taskRoomItems(task_id: $taskId) {
      category {
        id
        title
        uuid
      }
      description
      id
      size
      height
      width
      length
      uuid
      task_id
      title
      task_room_category_id
      service_catalog_items {
        id
        identifier
        title
        price
        unit_id
        unit {
          id
          title
        }
        category {
          id
          title
        }
      }
    }
    taskRoomCategories(task_id: $taskId) {
      id
      title
      uuid
      task_id
    }
  }
`;

export function useTaskRoomItemsQuery(
  options: Omit<Urql.UseQueryArgs<TaskRoomItemsQueryVariables>, 'query'>,
) {
  return Urql.useQuery<TaskRoomItemsQuery, TaskRoomItemsQueryVariables>({
    query: TaskRoomItemsDocument,
    ...options,
  });
}
