import { string } from 'yup';

// TODO: localize postcode validation rules
export const postcodeSchema = string().trim().length(5, 'validation.postcode').strict();

export const isValidPostcode = (value: unknown): boolean => {
  try {
    postcodeSchema.required().validateSync(value);
  } catch {
    return false;
  }

  return true;
};
