import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UploadLoginBackgroundMutationVariables = Types.Exact<{
  file?: Types.InputMaybe<Types.Scalars['Upload']['input']>;
}>;

export type UploadLoginBackgroundMutation = {
  __typename?: 'Mutation';
  uploadLoginBackground: {
    __typename?: 'AppSettings';
    login_background?: {
      __typename?: 'FileItem';
      file_name: string;
      url: string;
      id: string;
    } | null;
  };
};

export const UploadLoginBackgroundDocument = gql`
  mutation UploadLoginBackground($file: Upload) {
    uploadLoginBackground(file: $file) {
      login_background {
        file_name
        url
        id
      }
    }
  }
`;

export function useUploadLoginBackgroundMutation() {
  return Urql.useMutation<
    UploadLoginBackgroundMutation,
    UploadLoginBackgroundMutationVariables | void
  >(UploadLoginBackgroundDocument);
}
