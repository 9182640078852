import './TooltipHeaderCell.scss';

import { GridHeaderCellProps } from '@progress/kendo-react-grid';
import { Tooltip } from '@progress/kendo-react-tooltip';

export const TooltipHeaderCell = (props: GridHeaderCellProps) => {
  const { title, children, onClick } = props;
  return (
    <div className="k-d-flex">
      <span title={title} className="TooltipHeaderCell k-column-title" onClick={onClick}>
        <Tooltip tooltipClassName="TooltipHeaderCell__tooltip" parentTitle anchorElement="pointer">
          {title}
        </Tooltip>
      </span>
      {children}
    </div>
  );
};
