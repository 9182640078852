import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import { FileClassificationTagFragmentDoc } from '../../../classification-tags/graphql/generated/fileClassificationTagFragment.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type TaskReportFileFragment = {
  __typename?: 'FileItem';
  id: string;
  name: string;
  url: string;
  url_thumb?: string | null;
  size: number;
  mime_type?: string | null;
  is_extracting_pages: boolean;
  permissions: Array<Types.Permission>;
  classification_tags: {
    __typename?: 'FileClassificationTagList';
    data: Array<{
      __typename?: 'FileClassificationTag';
      id: string;
      title: string;
      color: string;
      alias: string;
      type_id: Types.FileClassificationTagTypeId;
    }>;
  };
};

export type TaskReportFieldsFragment = {
  __typename?: 'TaskReport';
  id: string;
  title: string;
  created_at: string;
  file?: {
    __typename?: 'FileItem';
    id: string;
    name: string;
    url: string;
    url_thumb?: string | null;
    size: number;
    mime_type?: string | null;
    is_extracting_pages: boolean;
    permissions: Array<Types.Permission>;
    classification_tags: {
      __typename?: 'FileClassificationTagList';
      data: Array<{
        __typename?: 'FileClassificationTag';
        id: string;
        title: string;
        color: string;
        alias: string;
        type_id: Types.FileClassificationTagTypeId;
      }>;
    };
  } | null;
};

export type TaskReportsQueryVariables = Types.Exact<{
  task_id: Types.Scalars['ID']['input'];
}>;

export type TaskReportsQuery = {
  __typename?: 'Query';
  taskReports: {
    __typename?: 'TaskReportPaginator';
    data: Array<{
      __typename?: 'TaskReport';
      id: string;
      title: string;
      created_at: string;
      file?: {
        __typename?: 'FileItem';
        id: string;
        name: string;
        url: string;
        url_thumb?: string | null;
        size: number;
        mime_type?: string | null;
        is_extracting_pages: boolean;
        permissions: Array<Types.Permission>;
        classification_tags: {
          __typename?: 'FileClassificationTagList';
          data: Array<{
            __typename?: 'FileClassificationTag';
            id: string;
            title: string;
            color: string;
            alias: string;
            type_id: Types.FileClassificationTagTypeId;
          }>;
        };
      } | null;
    }>;
  };
};

export const TaskReportFileFragmentDoc = gql`
  fragment TaskReportFile on FileItem {
    id
    name
    url
    url_thumb
    size
    mime_type
    is_extracting_pages
    classification_tags {
      data {
        ...FileClassificationTag
      }
    }
    permissions
  }
  ${FileClassificationTagFragmentDoc}
`;
export const TaskReportFieldsFragmentDoc = gql`
  fragment TaskReportFields on TaskReport {
    id
    title
    created_at
    file {
      ...TaskReportFile
    }
  }
  ${TaskReportFileFragmentDoc}
`;
export const TaskReportsDocument = gql`
  query TaskReports($task_id: ID!) {
    taskReports(task_id: $task_id) {
      data {
        ...TaskReportFields
      }
    }
  }
  ${TaskReportFieldsFragmentDoc}
`;

export function useTaskReportsQuery(
  options: Omit<Urql.UseQueryArgs<TaskReportsQueryVariables>, 'query'>,
) {
  return Urql.useQuery<TaskReportsQuery, TaskReportsQueryVariables>({
    query: TaskReportsDocument,
    ...options,
  });
}
