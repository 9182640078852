import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ResetTaskAppointmentMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;

export type ResetTaskAppointmentMutation = {
  __typename?: 'Mutation';
  resetTaskAppointment?: {
    __typename?: 'ResponseMessage';
    message?: string | null;
    success?: boolean | null;
  } | null;
};

export const ResetTaskAppointmentDocument = gql`
  mutation ResetTaskAppointment($id: ID!) {
    resetTaskAppointment(id: $id) {
      message
      success
    }
  }
`;

export function useResetTaskAppointmentMutation() {
  return Urql.useMutation<ResetTaskAppointmentMutation, ResetTaskAppointmentMutationVariables>(
    ResetTaskAppointmentDocument,
  );
}
