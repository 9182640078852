// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.97.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.97.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ``, "",{"version":3,"sources":[],"names":[],"mappings":"","sourceRoot":""}]);
// Exports
export var primaryColor = `#5f5566`;
export var secondaryColor = `#475467`;
export var tertiaryColor = `#7d89b0`;
export var infoColor = `#2676cc`;
export var successColor = `#027a48`;
export var errorColor = `#d92d20`;
export var warningColor = `#dc6803`;
export var lightColor = `#ebebeb`;
export var darkColor = `#2b303b`;
export var inverseColor = `#2b303b`;
export default ___CSS_LOADER_EXPORT___;
