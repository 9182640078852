import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UploadLoginLogoMutationVariables = Types.Exact<{
  file?: Types.InputMaybe<Types.Scalars['Upload']['input']>;
}>;

export type UploadLoginLogoMutation = {
  __typename?: 'Mutation';
  uploadLoginLogo: {
    __typename?: 'AppSettings';
    login_logo?: { __typename?: 'FileItem'; file_name: string; url: string; id: string } | null;
  };
};

export const UploadLoginLogoDocument = gql`
  mutation UploadLoginLogo($file: Upload) {
    uploadLoginLogo(file: $file) {
      login_logo {
        file_name
        url
        id
      }
    }
  }
`;

export function useUploadLoginLogoMutation() {
  return Urql.useMutation<UploadLoginLogoMutation, UploadLoginLogoMutationVariables | void>(
    UploadLoginLogoDocument,
  );
}
