import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ActivateFileForInterfaceMutationVariables = Types.Exact<{
  file_ids: Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input'];
}>;

export type ActivateFileForInterfaceMutation = {
  __typename?: 'Mutation';
  activateTaskFileForInterface: {
    __typename?: 'ResponseMessage';
    success?: boolean | null;
    message?: string | null;
  };
};

export const ActivateFileForInterfaceDocument = gql`
  mutation ActivateFileForInterface($file_ids: [ID!]!) {
    activateTaskFileForInterface(file_ids: $file_ids) {
      success
      message
    }
  }
`;

export function useActivateFileForInterfaceMutation() {
  return Urql.useMutation<
    ActivateFileForInterfaceMutation,
    ActivateFileForInterfaceMutationVariables
  >(ActivateFileForInterfaceDocument);
}
