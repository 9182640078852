import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AutocompleteCityQueryVariables = Types.Exact<{
  postcode: Types.Scalars['String']['input'];
}>;

export type AutocompleteCityQuery = {
  __typename?: 'Query';
  autocomplete_city: { __typename?: 'PostCodeAutocomplete'; city: string };
};

export const AutocompleteCityDocument = gql`
  query AutocompleteCity($postcode: String!) {
    autocomplete_city(postcode: $postcode) {
      city
    }
  }
`;

export function useAutocompleteCityQuery(
  options: Omit<Urql.UseQueryArgs<AutocompleteCityQueryVariables>, 'query'>,
) {
  return Urql.useQuery<AutocompleteCityQuery, AutocompleteCityQueryVariables>({
    query: AutocompleteCityDocument,
    ...options,
  });
}
