import { CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';
import { useMemo } from 'react';

import { InputMaybe, Scalars, SQLOperator } from '../../../../../types/graphql.generated';
import { isArray, isEmptyString, isNullish, isString } from '../../../helpers';
import { ColumnsMap, GridState } from './types';

export function isFilterDescriptor(
  filter: CompositeFilterDescriptor | FilterDescriptor,
): filter is FilterDescriptor {
  return 'operator' in filter;
}

export function isCompositeFilterDescriptor(
  filter: CompositeFilterDescriptor | FilterDescriptor,
): filter is CompositeFilterDescriptor {
  return 'filters' in filter;
}

export interface GridFilter<TColumnsEnum> {
  AND?: InputMaybe<Array<GridFilter<TColumnsEnum>>>;
  OR?: InputMaybe<Array<GridFilter<TColumnsEnum>>>;
  column?: TColumnsEnum;
  operator?: InputMaybe<SQLOperator>;
  value?: InputMaybe<Scalars['Mixed']['input']>;
}

export type GridFilterArray<TColumnsEnum> = GridFilter<TColumnsEnum>[];

export interface GridFilterSimple<TColumnEnum> extends GridFilter<TColumnEnum> {
  column: TColumnEnum;
  operator: InputMaybe<SQLOperator>;
  value: InputMaybe<Scalars['Mixed']['input']>;
}

export type GridFilterSimpleArray<TColumnEnum> = GridFilterSimple<TColumnEnum>[];

function isGridFilterSimple<TColumnEnum>(
  filter: GridFilter<TColumnEnum>,
): filter is GridFilterSimple<TColumnEnum> {
  return filter.column && filter.operator && filter.value;
}

/**
 * The filter operator (comparison).
 *
 * The supported operators are:
 * * `"eq"` (equal to)
 * * `"neq"` (not equal to)
 * * `"isnull"` (is equal to null)
 * * `"isnotnull"` (is not equal to null)
 * * `"lt"` (less than)
 * * `"lte"` (less than or equal to)
 * * `"gt"` (greater than)
 * * `"gte"` (greater than or equal to)
 *
 * The following operators are supported for string fields only:
 * * `"startswith"`
 * * `"endswith"`
 * * `"contains"`
 * * `"doesnotcontain"`
 * * `"isempty"`
 * * `"isnotempty"`
 */

enum KendoFilterOperators {
  EQ = 'eq',
  NEQ = 'neq',
  LT = 'lt',
  LTE = 'lte',
  GT = 'gt',
  GTE = 'gte',
  IS_NULL = 'isnull',
  IS_NOT_NULL = 'isnotnull',
  STARTS_WITH = 'startswith',
  ENDS_WITH = 'endswith',
  CONTAINS = 'contains',
  DOES_NOT_CONTAIN = 'doesnotcontain',
  IS_EMPTY = 'isempty',
  IS_NOT_EMPTY = 'isnotempty',
}

export function convertToGridFilter<TColumnsEnum>(
  filter: CompositeFilterDescriptor | FilterDescriptor,
  columns: ColumnsMap<TColumnsEnum>,
): GridFilter<TColumnsEnum> | undefined {
  // convert composite filter descriptor to AND or OR arrays
  if (isCompositeFilterDescriptor(filter)) {
    const filters =
      filter.filters
        .map((filter) => {
          return convertToGridFilter(filter, columns);
        })
        .filter((gridFilter): gridFilter is GridFilter<TColumnsEnum> => gridFilter !== undefined) ??
      [];

    if (filter.logic === 'and') {
      return {
        AND: filters,
      };
    }
    if (filter.logic === 'or') {
      return {
        OR: filters,
      };
    }
  }

  // convert filter descriptor
  if (isFilterDescriptor(filter)) {
    if (!isString(filter.field)) {
      throw new Error('Invalid filter field must be a string');
    }
    if (!(filter.field in columns)) {
      throw new Error(`Invalid filter field ${filter.field} not defined in columns`);
    }

    // omit filters with empty string values
    if (isEmptyString(filter.value)) {
      return undefined;
    }

    if (filter.operator === KendoFilterOperators.STARTS_WITH) {
      return {
        column: columns[filter.field],
        operator: SQLOperator.LIKE,
        value: filter.value + '%',
      };
    }
    if (filter.operator === KendoFilterOperators.ENDS_WITH) {
      return {
        column: columns[filter.field],
        operator: SQLOperator.LIKE,
        value: '%' + filter.value,
      };
    }
    if (filter.operator === KendoFilterOperators.CONTAINS) {
      return {
        column: columns[filter.field],
        operator: SQLOperator.LIKE,
        value: '%' + filter.value + '%',
      };
    }
    if (filter.operator === KendoFilterOperators.DOES_NOT_CONTAIN) {
      return {
        column: columns[filter.field],
        operator: SQLOperator.NOT_LIKE,
        value: '%' + filter.value + '%',
      };
    }

    if (filter.operator === KendoFilterOperators.IS_NULL) {
      return {
        column: columns[filter.field],
        operator: SQLOperator.IS_NULL,
        value: undefined,
      };
    }

    if (filter.operator === KendoFilterOperators.IS_NOT_NULL) {
      return {
        column: columns[filter.field],
        operator: SQLOperator.IS_NOT_NULL,
        value: undefined,
      };
    }

    if (filter.operator === KendoFilterOperators.IS_EMPTY) {
      return {
        column: columns[filter.field],
        operator: SQLOperator.LIKE,
        value: '',
      };
    }

    if (filter.operator === KendoFilterOperators.IS_NOT_EMPTY) {
      return {
        column: columns[filter.field],
        operator: SQLOperator.NOT_LIKE,
        value: '',
      };
    }

    if (filter.operator === KendoFilterOperators.EQ) {
      return {
        column: columns[filter.field],
        operator: SQLOperator.EQ,
        value: filter.value,
      };
    }

    if (filter.operator === KendoFilterOperators.NEQ) {
      return {
        column: columns[filter.field],
        operator: SQLOperator.NEQ,
        value: filter.value,
      };
    }

    if (filter.operator === KendoFilterOperators.LT) {
      return {
        column: columns[filter.field],
        operator: SQLOperator.LT,
        value: filter.value,
      };
    }

    if (filter.operator === KendoFilterOperators.LTE) {
      return {
        column: columns[filter.field],
        operator: SQLOperator.LTE,
        value: filter.value,
      };
    }

    if (filter.operator === KendoFilterOperators.GT) {
      return {
        column: columns[filter.field],
        operator: SQLOperator.GT,
        value: filter.value,
      };
    }

    if (filter.operator === KendoFilterOperators.GTE) {
      return {
        column: columns[filter.field],
        operator: SQLOperator.GTE,
        value: filter.value,
      };
    }

    if (
      filter.operator === 'in' &&
      !isNullish(filter.value) &&
      isArray(filter.value, isString) &&
      filter.value.length > 0
    ) {
      return {
        column: columns[filter.field],
        operator: SQLOperator.IN,
        value: filter.value,
      };
    }

    if (
      filter.operator === 'between' &&
      !isNullish(filter.value) &&
      filter.value.start &&
      filter.value.end
    ) {
      return {
        column: columns[filter.field],
        operator: SQLOperator.BETWEEN,
        value: [filter.value.start, filter.value.end],
      };
    }

    return undefined;
  }

  throw new Error('Invalid filter must be FilterDescriptor or CompositeFilterDescriptor');
}

export function useGridFilter<TColumnsEnum>(
  filter: CompositeFilterDescriptor | undefined,
  columns: ColumnsMap<TColumnsEnum>,
): GridFilter<TColumnsEnum> {
  return useMemo(() => {
    return convertToGridFilter(filter ?? defaultCompositeFilter, columns) ?? { AND: [] };
  }, [columns, filter]);
}

const defaultCompositeFilter: CompositeFilterDescriptor = { logic: 'and', filters: [] };

export function useGridFilters<TColumnsEnum>(
  gridState: GridState,
  columns: ColumnsMap<TColumnsEnum>,
): GridFilterSimpleArray<TColumnsEnum> {
  const filter = useGridFilter(gridState.filter ?? defaultCompositeFilter, columns);

  return useMemo(() => {
    return filter.AND?.filter(isGridFilterSimple) ?? [];
  }, [filter.AND]);
}

export function useGridFiltersWhereAnd<TColumnsEnum>(
  gridState: GridState,
  columns: ColumnsMap<TColumnsEnum>,
) {
  const gridFilters = useGridFilters(gridState, columns);
  return useMemo(() => {
    return gridFilters.length > 0 ? { AND: gridFilters } : undefined;
  }, [gridFilters]);
}

export function useGridFiltersWhereOr<TColumnsEnum>(
  gridState: GridState,
  columns: ColumnsMap<TColumnsEnum>,
) {
  const gridFilters = useGridFilters(gridState, columns);
  return useMemo(() => {
    return gridFilters.length > 0 ? { OR: gridFilters } : undefined;
  }, [gridFilters]);
}
