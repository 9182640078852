import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UploadTaskFileMutationVariables = Types.Exact<{
  task_id: Types.Scalars['ID']['input'];
  folder_id: Types.Scalars['ID']['input'];
  file: Types.UploadFileInput;
}>;

export type UploadTaskFileMutation = {
  __typename?: 'Mutation';
  uploadTaskFiles: {
    __typename?: 'TaskFilesPaginator';
    data: Array<{ __typename?: 'FileItem'; id: string }>;
  };
};

export const UploadTaskFileDocument = gql`
  mutation UploadTaskFile($task_id: ID!, $folder_id: ID!, $file: UploadFileInput!) {
    uploadTaskFiles(task_id: $task_id, folder_id: $folder_id, files: [$file]) {
      data {
        id
      }
    }
  }
`;

export function useUploadTaskFileMutation() {
  return Urql.useMutation<UploadTaskFileMutation, UploadTaskFileMutationVariables>(
    UploadTaskFileDocument,
  );
}
