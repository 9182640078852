import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateTaskCalculationMutationVariables = Types.Exact<{
  task_calculation_id: Types.Scalars['ID']['input'];
  input: Types.UpdateTaskCalculationInput;
}>;

export type UpdateTaskCalculationMutation = {
  __typename?: 'Mutation';
  updateTaskCalculation: {
    __typename?: 'TaskCalculation';
    title?: string | null;
    task_id: string;
    sum_alias?: string | null;
    status_alias?: string | null;
    accepted_alias?: string | null;
    tax_deductible?: boolean | null;
    time_value_alias?: string | null;
    time_value_column_active?: boolean | null;
    hide_column_status: boolean;
    hide_column_accepted: boolean;
    hide_column_payout_state: boolean;
    group_by?: Array<{
      __typename?: 'TaskCalculationGroupType';
      group_type_id: Types.CalculationGroupType;
      id: string;
      task_calculation_id: string;
    } | null> | null;
    categories?: Array<{
      __typename?: 'TaskCalculationCategory';
      id: string;
      title: string;
      sort_index: number;
    }> | null;
    items?: Array<{
      __typename?: 'TaskCalculationItem';
      id: string;
      title?: string | null;
      description?: string | null;
      accepted?: number | null;
      amount?: number | null;
      single_price?: number | null;
      sort_index?: number | null;
      time_value?: number | null;
      unit?: { __typename?: 'ServiceCatalogUnit'; id: string; title: string } | null;
      category?: { __typename?: 'TaskCalculationCategory'; id: string; title: string } | null;
    }> | null;
  };
};

export const UpdateTaskCalculationDocument = gql`
  mutation UpdateTaskCalculation($task_calculation_id: ID!, $input: UpdateTaskCalculationInput!) {
    updateTaskCalculation(task_calculation_id: $task_calculation_id, input: $input) {
      title
      task_id
      sum_alias
      status_alias
      accepted_alias
      tax_deductible
      time_value_alias
      time_value_column_active
      hide_column_status
      hide_column_accepted
      hide_column_payout_state
      group_by {
        group_type_id
        id
        task_calculation_id
      }
      categories {
        id
        title
        sort_index
      }
      items {
        id
        title
        description
        unit {
          id
          title
        }
        category {
          id
          title
        }
        accepted
        amount
        single_price
        sort_index
        time_value
      }
    }
  }
`;

export function useUpdateTaskCalculationMutation() {
  return Urql.useMutation<UpdateTaskCalculationMutation, UpdateTaskCalculationMutationVariables>(
    UpdateTaskCalculationDocument,
  );
}
