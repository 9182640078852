import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CreateCustomerPortalEventMutationVariables = Types.Exact<{
  task_id: Types.Scalars['ID']['input'];
  input: Types.CreateCustomerPortalEventInput;
}>;

export type CreateCustomerPortalEventMutation = {
  __typename?: 'Mutation';
  createCustomerPortalEvent?: {
    __typename?: 'CustomerPortalEvent';
    allow_customer_upload: boolean;
    allow_customer_form: boolean;
    contact_person_email: string;
    contact_person_name: string;
    contact_person_phone: string;
    customer_email?: string | null;
    customer_lat?: number | null;
    customer_lon?: number | null;
    customer_name: string;
    customer_phone?: string | null;
    from?: string | null;
    has_room: boolean;
    has_user_position?: boolean | null;
    hash: string;
    id: string;
    identifier: string;
    notes?: string | null;
    room_identifier?: string | null;
    send_email: boolean;
    send_email_date?: string | null;
    send_sms: boolean;
    send_sms_date?: string | null;
    to?: string | null;
    url?: string | null;
    task_id: string;
  } | null;
};

export const CreateCustomerPortalEventDocument = gql`
  mutation CreateCustomerPortalEvent($task_id: ID!, $input: CreateCustomerPortalEventInput!) {
    createCustomerPortalEvent(task_id: $task_id, input: $input) {
      allow_customer_upload
      allow_customer_form
      contact_person_email
      contact_person_name
      contact_person_phone
      customer_email
      customer_lat
      customer_lon
      customer_name
      customer_phone
      from
      has_room
      has_user_position
      hash
      id
      identifier
      notes
      room_identifier
      send_email
      send_email_date
      send_sms
      send_sms_date
      to
      url
      task_id
    }
  }
`;

export function useCreateCustomerPortalEventMutation() {
  return Urql.useMutation<
    CreateCustomerPortalEventMutation,
    CreateCustomerPortalEventMutationVariables
  >(CreateCustomerPortalEventDocument);
}
