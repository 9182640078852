import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import { AdditionalTaskFormFragmentDoc } from './additionalTaskFormFragment.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DuplicateTaskFormMutationVariables = Types.Exact<{
  task_form_id: Types.Scalars['ID']['input'];
}>;

export type DuplicateTaskFormMutation = {
  __typename?: 'Mutation';
  duplicateTaskForm: {
    __typename?: 'TaskForm';
    id: string;
    updated_at: string;
    title: string;
    sort_index: number;
    removed_at?: string | null;
    type: { __typename?: 'TaskFormType'; id: string; type_id: Types.TaskFormTypeId };
    template: {
      __typename?: 'TaskTemplate';
      id: string;
      title: string;
      description?: string | null;
      type_id: Types.TaskTemplateTypeId;
    };
    tags?: Array<{
      __typename?: 'Tag';
      id: string;
      title: string;
      color: string;
      type_id: string;
      type: { __typename?: 'TagType'; id: string; title: string; color: string };
    }> | null;
  };
};

export const DuplicateTaskFormDocument = gql`
  mutation DuplicateTaskForm($task_form_id: ID!) {
    duplicateTaskForm(task_form_id: $task_form_id) {
      ...AdditionalTaskForm
    }
  }
  ${AdditionalTaskFormFragmentDoc}
`;

export function useDuplicateTaskFormMutation() {
  return Urql.useMutation<DuplicateTaskFormMutation, DuplicateTaskFormMutationVariables>(
    DuplicateTaskFormDocument,
  );
}
