import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AssignTaskOwnerMutationVariables = Types.Exact<{
  task_id: Types.Scalars['ID']['input'];
  owner_client_id?: Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;

export type AssignTaskOwnerMutation = {
  __typename?: 'Mutation';
  assignTaskOwner?: { __typename?: 'Task'; id: string } | null;
};

export const AssignTaskOwnerDocument = gql`
  mutation AssignTaskOwner($task_id: ID!, $owner_client_id: ID) {
    assignTaskOwner(task_id: $task_id, owner_client_id: $owner_client_id) {
      id
    }
  }
`;

export function useAssignTaskOwnerMutation() {
  return Urql.useMutation<AssignTaskOwnerMutation, AssignTaskOwnerMutationVariables>(
    AssignTaskOwnerDocument,
  );
}
