import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type SendTaskRouteLinkMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;

export type SendTaskRouteLinkMutation = {
  __typename?: 'Mutation';
  sendTaskRouteLink: {
    __typename?: 'ResponseMessage';
    success?: boolean | null;
    message?: string | null;
  };
};

export const SendTaskRouteLinkDocument = gql`
  mutation SendTaskRouteLink($id: ID!) {
    sendTaskRouteLink(id: $id) {
      success
      message
    }
  }
`;

export function useSendTaskRouteLinkMutation() {
  return Urql.useMutation<SendTaskRouteLinkMutation, SendTaskRouteLinkMutationVariables>(
    SendTaskRouteLinkDocument,
  );
}
