import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateTaskFolderPermissionsMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  input: Types.UpdateTaskFolderPermissionsInput;
}>;

export type UpdateTaskFolderPermissionsMutation = {
  __typename?: 'Mutation';
  updateTaskFolderPermissions?: { __typename?: 'TaskFolder'; id: string } | null;
};

export const UpdateTaskFolderPermissionsDocument = gql`
  mutation UpdateTaskFolderPermissions($id: ID!, $input: UpdateTaskFolderPermissionsInput!) {
    updateTaskFolderPermissions(id: $id, input: $input) {
      id
    }
  }
`;

export function useUpdateTaskFolderPermissionsMutation() {
  return Urql.useMutation<
    UpdateTaskFolderPermissionsMutation,
    UpdateTaskFolderPermissionsMutationVariables
  >(UpdateTaskFolderPermissionsDocument);
}
