import './styles/_main.scss';
import './modules/layout/utils/dvhPolyfill';

import { LOCALE, SENTRY_DSN, SENTRY_SAMPLE_RATE } from '@module/common/config/env';
import * as Sentry from '@sentry/react';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

import App from './App';

Sentry.init({
  release: process.env.SENTRY_RELEASE,
  dsn: SENTRY_DSN,
  integrations: [
    Sentry.breadcrumbsIntegration({
      console: process.env.NODE_ENV === 'production',
    }),
    Sentry.browserTracingIntegration(),
  ],
  tracesSampleRate: SENTRY_SAMPLE_RATE,
});

Sentry.setTag('locale', LOCALE.replace(/&quot;/g, ''));

const container = document.getElementById('root');

if (!container) {
  throw new Error('Missing root container element');
}

const root = createRoot(container);

root.render(
  <StrictMode>
    <App />
  </StrictMode>,
);
