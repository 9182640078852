import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type SendEmailFromTemplateMutationVariables = Types.Exact<{
  taskId: Types.Scalars['ID']['input'];
  templateId: Types.Scalars['ID']['input'];
}>;

export type SendEmailFromTemplateMutation = {
  __typename?: 'Mutation';
  sendDirectMessageFromEmailTemplate: {
    __typename?: 'ResponseMessage';
    success?: boolean | null;
    message?: string | null;
  };
};

export const SendEmailFromTemplateDocument = gql`
  mutation SendEmailFromTemplate($taskId: ID!, $templateId: ID!) {
    sendDirectMessageFromEmailTemplate(task_id: $taskId, email_template_id: $templateId) {
      success
      message
    }
  }
`;

export function useSendEmailFromTemplateMutation() {
  return Urql.useMutation<SendEmailFromTemplateMutation, SendEmailFromTemplateMutationVariables>(
    SendEmailFromTemplateDocument,
  );
}
