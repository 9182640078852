import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CreateTaskFolderMutationVariables = Types.Exact<{
  task_id: Types.Scalars['ID']['input'];
  input: Types.TaskFolderInput;
}>;

export type CreateTaskFolderMutation = {
  __typename?: 'Mutation';
  createTaskFolder?: {
    __typename?: 'TaskFolder';
    id: string;
    task_id: string;
    parent_id?: string | null;
    name: string;
  } | null;
};

export const CreateTaskFolderDocument = gql`
  mutation CreateTaskFolder($task_id: ID!, $input: TaskFolderInput!) {
    createTaskFolder(task_id: $task_id, input: $input) {
      id
      task_id
      parent_id
      name
    }
  }
`;

export function useCreateTaskFolderMutation() {
  return Urql.useMutation<CreateTaskFolderMutation, CreateTaskFolderMutationVariables>(
    CreateTaskFolderDocument,
  );
}
