import { useCallback, useEffect, useState } from 'react';

export function useCheckForFileDrag() {
  const [dragging, setDragging] = useState(false);

  const handleDragIn = useCallback(
    (event: DragEvent) => {
      event.stopPropagation();
      event.preventDefault();
      if (
        event.clientX >= 10 &&
        event.clientX <= document.body.clientWidth - 10 &&
        event.clientY >= 10 &&
        event.clientY <= document.body.clientHeight - 10
      ) {
        if (!dragging) {
          setDragging(true);
        }
      }
    },
    // eslint-disable-next-line
      [],
  );

  const handleDragOut = useCallback(
    (event: DragEvent) => {
      event.stopPropagation();
      event.preventDefault();
      if (
        event.clientX <= 10 ||
        event.clientX >= document.body.clientWidth - 10 ||
        event.clientY <= 10 ||
        event.clientY >= document.body.clientHeight - 10
      ) {
        if (!dragging) {
          setDragging(false);
        }
      }
    },
    // eslint-disable-next-line
      [],
  );

  // Hide overlay if file is drop inside of drop zone
  const handleDrop = useCallback((event: DragEvent) => {
    event.stopPropagation();
    event.preventDefault();
    setDragging(false);
  }, []);

  // Hide overlay if file is drop outside of drop zone and tab is changed
  const handleTabChange = useCallback((event: Event) => {
    event.stopPropagation();
    event.preventDefault();
    setDragging(false);
  }, []);

  useEffect(() => {
    document.body.addEventListener('dragenter', handleDragIn);
    document.body.addEventListener('dragleave', handleDragOut);
    document.body.addEventListener('drop', handleDrop);
    document.addEventListener('visibilitychange', handleTabChange);
    return () => {
      document.body.removeEventListener('dragenter', handleDragIn);
      document.body.removeEventListener('dragleave', handleDragOut);
      document.body.removeEventListener('drop', handleDrop);
      document.removeEventListener('visibilitychange', handleTabChange);
    };
  }, [handleDragIn, handleDragOut, handleDrop, handleTabChange]);

  return dragging;
}
