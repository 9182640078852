import { FieldWrapper } from '@progress/kendo-react-form';
import {
  ColorPicker,
  ColorPickerChangeEvent,
  ColorPickerProps,
} from '@progress/kendo-react-inputs';
import { colord } from 'colord';
import { useId } from 'react';

import { Label } from '../components/Label';
import { FieldError } from './FieldError';
import { FieldHint } from './FieldHint';
import { CommonFieldRenderProps } from './types';

type ColorPickerInputProps = CommonFieldRenderProps &
  Pick<ColorPickerProps, 'id' | 'value' | 'disabled' | 'onChange' | 'onFocus' | 'size'>;

export const ColorPickerInput = (props: ColorPickerInputProps) => {
  const defaultId = useId();
  const {
    label,
    name,
    value,
    id = defaultId,
    valid,
    size = 'medium',
    onFocus,
    onChange,
    tooltip,
    tooltipPosition,
  } = props;
  const labelId = label && `${id}_label`;

  const onColorChange = (e: ColorPickerChangeEvent) => {
    const hex = colord(e.value).toHex();

    onChange?.({ ...e, value: hex });
  };

  return (
    <FieldWrapper>
      {label && (
        <Label
          id={labelId}
          tooltip={tooltip}
          tooltipPosition={tooltipPosition}
          label={label}
          name={name}
          editorId={id}
          editorValid={valid}
        >
          {label}
        </Label>
      )}

      <div>
        <ColorPicker
          id={id}
          ariaLabelledBy={labelId}
          value={value ? colord(value).toRgbString() : undefined}
          gradientSettings={{ opacity: false }}
          views={['gradient']}
          size={size}
          onChange={onColorChange}
          onFocus={onFocus}
        />
      </div>
      <div className="HintAndError">
        <FieldHint {...props} />
        <FieldError {...props} />
      </div>
    </FieldWrapper>
  );
};
