import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type TaskProposalItemTemplateFragment = {
  __typename?: 'TaskProposalItemTemplate';
  title: string;
  unit?: string | null;
  unit_price: number;
  amount: number;
  sum_net: number;
  sum_gross: number;
  tax: number;
};

export type SuggestTaskProposalItemsMutationVariables = Types.Exact<{
  taskId: Types.Scalars['ID']['input'];
}>;

export type SuggestTaskProposalItemsMutation = {
  __typename?: 'Mutation';
  suggestTaskProposalItems: Array<{
    __typename?: 'TaskProposalItemTemplate';
    title: string;
    unit?: string | null;
    unit_price: number;
    amount: number;
    sum_net: number;
    sum_gross: number;
    tax: number;
  }>;
};

export const TaskProposalItemTemplateFragmentDoc = gql`
  fragment TaskProposalItemTemplate on TaskProposalItemTemplate {
    title
    unit
    unit_price
    amount
    sum_net
    sum_gross
    tax
  }
`;
export const SuggestTaskProposalItemsDocument = gql`
  mutation SuggestTaskProposalItems($taskId: ID!) {
    suggestTaskProposalItems(task_id: $taskId) {
      ...TaskProposalItemTemplate
    }
  }
  ${TaskProposalItemTemplateFragmentDoc}
`;

export function useSuggestTaskProposalItemsMutation() {
  return Urql.useMutation<
    SuggestTaskProposalItemsMutation,
    SuggestTaskProposalItemsMutationVariables
  >(SuggestTaskProposalItemsDocument);
}
