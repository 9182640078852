import './DialogHeader.scss';

import { classNames } from '@progress/kendo-react-common';
import { ReactNode } from 'react';

interface DialogHeaderProps {
  title?: string | ReactNode;
  description?: string | ReactNode;
  align?: 'left' | 'center' | 'right';

  className?: string;
  classNameTitle?: string;
  classNameDescription?: string;
}

export const DialogHeader = (props: DialogHeaderProps) => {
  const {
    title,
    description,
    align = 'left',
    className,
    classNameTitle,
    classNameDescription,
  } = props;
  return (
    <div
      className={classNames(
        'dialog-header',
        'k-display-flex k-flex-column k-gap-2',
        {
          'k-text-left': align === 'left',
          'k-text-center': align === 'center',
          'k-text-right': align === 'right',
        },
        className,
      )}
    >
      {title && (
        <h2
          className={classNames(
            'dialog-header-title',
            'u-text-base-600 u-font-semibold u-text-xl k-m-0',
            classNameTitle,
          )}
        >
          {title}
        </h2>
      )}
      {description && (
        <span
          className={classNames(
            'dialog-header-description',
            'u-text-base-700 u-font-normal u-text-base',
            classNameDescription,
          )}
        >
          {description}
        </span>
      )}
    </div>
  );
};

interface DialogHeaderBarProps {
  className?: string;
  children?: ReactNode;
  align?: 'left' | 'center' | 'right';
  layout?: 'fixed' | 'flex';
}

export const DialogHeaderBar = (props: DialogHeaderBarProps) => {
  const { className, align = 'left', layout = 'flex' } = props;
  return (
    <div
      className={classNames(
        'dialog-header-bar',
        {
          'dialog-header-bar-flex': layout === 'flex',
          'dialog-header-bar-fixed': layout === 'fixed',
        },
        'k-display-flex k-flex-column',
        'k-flex-shrink-0 k-flex-grow-0',
        {
          'k-align-items-start': align === 'left',
          'k-align-items-center': align === 'center',
          'k-align-items-end': align === 'right',
        },
        className,
      )}
    >
      {props.children}
    </div>
  );
};
