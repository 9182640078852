export default {
  tasks: {
    generalSection: {
      labels: {
        auto_accept_task:
          'Automatische Auftragsannahme - der Auftrag wird automatisch um 22:00 von den Sachverständigen auf “angenommen” gesetzt.',
        adjuster_can_create_tasks: 'Sachverständiger kann Aufträge erstellen',
      },
    },
    tasksSection: {
      labels: {
        allow_adjusters_rename_files:
          'Sachverständiger darf alle Dokumente in seinen Aufträgen umbenennen',
        allow_adjusters_update_file_classification_tags:
          'Sachverständiger darf Datei-Klassifikationen für alle Dokumente in seinen Aufträgen setzen',
      },
    },
  },
};
