import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type TestAppMailTransportMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;

export type TestAppMailTransportMutation = {
  __typename?: 'Mutation';
  testAppMailTransport: {
    __typename?: 'ResponseMessage';
    success?: boolean | null;
    message?: string | null;
  };
};

export const TestAppMailTransportDocument = gql`
  mutation TestAppMailTransport($id: ID!) {
    testAppMailTransport(id: $id) {
      success
      message
    }
  }
`;

export function useTestAppMailTransportMutation() {
  return Urql.useMutation<TestAppMailTransportMutation, TestAppMailTransportMutationVariables>(
    TestAppMailTransportDocument,
  );
}
