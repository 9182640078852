import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type SendCustomerInvitationLinkMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  type: Types.CustomerPortalInvitationType;
}>;

export type SendCustomerInvitationLinkMutation = {
  __typename?: 'Mutation';
  sendCustomerInvitationLink: {
    __typename?: 'ResponseMessage';
    success?: boolean | null;
    message?: string | null;
  };
};

export const SendCustomerInvitationLinkDocument = gql`
  mutation SendCustomerInvitationLink($id: ID!, $type: CustomerPortalInvitationType!) {
    sendCustomerInvitationLink(input: { customer_portal_event_id: $id, type: $type }) {
      success
      message
    }
  }
`;

export function useSendCustomerInvitationLinkMutation() {
  return Urql.useMutation<
    SendCustomerInvitationLinkMutation,
    SendCustomerInvitationLinkMutationVariables
  >(SendCustomerInvitationLinkDocument);
}
