import { useMemo } from 'react';

import * as bootstrapVariables from '../../../styles/bootstrap/_export.scss';
import { useMatchMedia } from './useMatchMedia';

export const useMediaQueries = () => {
  const smUp = useMatchMedia(`(min-width: ${bootstrapVariables.smBreakpoint})`);
  const mdUp = useMatchMedia(`(min-width: ${bootstrapVariables.mdBreakpoint})`);
  const lgUp = useMatchMedia(`(min-width: ${bootstrapVariables.lgBreakpoint})`);
  const xlUp = useMatchMedia(`(min-width: ${bootstrapVariables.xlBreakpoint})`);
  const xxlUp = useMatchMedia(`(min-width: ${bootstrapVariables.xxlBreakpoint})`);
  return useMemo(() => ({ smUp, mdUp, lgUp, xlUp, xxlUp }), [lgUp, mdUp, smUp, xlUp, xxlUp]);
};
