import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type SetTaskInitialContactMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  description?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type SetTaskInitialContactMutation = {
  __typename?: 'Mutation';
  setTaskInitialContact?: {
    __typename?: 'ResponseMessage';
    success?: boolean | null;
    message?: string | null;
  } | null;
};

export const SetTaskInitialContactDocument = gql`
  mutation SetTaskInitialContact($id: ID!, $description: String) {
    setTaskInitialContact(id: $id, description: $description) {
      success
      message
    }
  }
`;

export function useSetTaskInitialContactMutation() {
  return Urql.useMutation<SetTaskInitialContactMutation, SetTaskInitialContactMutationVariables>(
    SetTaskInitialContactDocument,
  );
}
