import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type RestoreTaskMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;

export type RestoreTaskMutation = {
  __typename?: 'Mutation';
  restoreTask: {
    __typename?: 'ResponseMessage';
    success?: boolean | null;
    message?: string | null;
  };
};

export const RestoreTaskDocument = gql`
  mutation RestoreTask($id: ID!) {
    restoreTask(id: $id) {
      success
      message
    }
  }
`;

export function useRestoreTaskMutation() {
  return Urql.useMutation<RestoreTaskMutation, RestoreTaskMutationVariables>(RestoreTaskDocument);
}
