import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import { TaskQuestionFragmentDoc } from './taskQuestionFragment.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type TaskFormQuestionsQueryVariables = Types.Exact<{
  task_form_id: Types.Scalars['ID']['input'];
}>;

export type TaskFormQuestionsQuery = {
  __typename?: 'Query';
  taskFormQuestions: {
    __typename?: 'TaskQuestionsResponse';
    data: Array<{
      __typename?: 'TaskQuestion';
      id: string;
      question_id: string;
      task_form_id: string;
      answer?: string | null;
      answer_code?: string | null;
      note?: string | null;
      comment?: string | null;
      images?: Array<string> | null;
      highlighted: boolean;
      force_page_break_after: boolean;
      active_pages?: Array<number> | null;
      rows?: Array<{
        __typename?: 'TaskQuestionTableRow';
        row_id: string;
        question_id: string;
        column1?: string | null;
        column2?: string | null;
        column3?: string | null;
        column4?: string | null;
        column5?: string | null;
        column6?: string | null;
        column7?: string | null;
        column8?: string | null;
      } | null> | null;
      file?: { __typename?: 'FileItem'; id: string } | null;
    }>;
  };
};

export const TaskFormQuestionsDocument = gql`
  query TaskFormQuestions($task_form_id: ID!) {
    taskFormQuestions(task_form_id: $task_form_id) {
      data {
        ...TaskQuestion
      }
    }
  }
  ${TaskQuestionFragmentDoc}
`;

export function useTaskFormQuestionsQuery(
  options: Omit<Urql.UseQueryArgs<TaskFormQuestionsQueryVariables>, 'query'>,
) {
  return Urql.useQuery<TaskFormQuestionsQuery, TaskFormQuestionsQueryVariables>({
    query: TaskFormQuestionsDocument,
    ...options,
  });
}
