import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type TaskEmailContactsQueryVariables = Types.Exact<{
  task_id: Types.Scalars['ID']['input'];
}>;

export type TaskEmailContactsQuery = {
  __typename?: 'Query';
  taskEmailContacts: {
    __typename?: 'TaskEmailContactsResponse';
    data: Array<{
      __typename?: 'TaskEmailContact';
      default: boolean;
      role?: string | null;
      email: string;
      name?: string | null;
    }>;
  };
};

export const TaskEmailContactsDocument = gql`
  query TaskEmailContacts($task_id: ID!) {
    taskEmailContacts(task_id: $task_id) {
      data {
        default
        role
        email
        name
      }
    }
  }
`;

export function useTaskEmailContactsQuery(
  options: Omit<Urql.UseQueryArgs<TaskEmailContactsQueryVariables>, 'query'>,
) {
  return Urql.useQuery<TaskEmailContactsQuery, TaskEmailContactsQueryVariables>({
    query: TaskEmailContactsDocument,
    ...options,
  });
}
