import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import { TaskInvoiceFragmentDoc } from './taskInvoiceFragment.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CompleteTaskInvoiceMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;

export type CompleteTaskInvoiceMutation = {
  __typename?: 'Mutation';
  completeTaskInvoice: {
    __typename?: 'TaskInvoice';
    id: string;
    task_id: string;
    external_invoice_id?: string | null;
    external_invoice_number?: string | null;
    external_invoice_url?: string | null;
    external_id?: number | null;
    date: string;
    state_id: Types.TaskInvoiceStateId;
    reminder_note?: string | null;
    reminder?: boolean | null;
    external_number: string;
    identifier: string;
    address: string;
    sum_gross: number;
    sum_net: number;
    sum_tax: number;
    url_preview: string;
    note?: string | null;
    supply_date?: string | null;
    overdue_date?: string | null;
    custom_name?: string | null;
    custom_street?: string | null;
    custom_city?: string | null;
    custom_postcode?: string | null;
    task: { __typename?: 'Task'; identifier?: string | null };
    task_proposal?: {
      __typename?: 'TaskProposal';
      id: string;
      task_id: string;
      external_proposal_id?: string | null;
      external_proposal_number?: string | null;
      external_proposal_url?: string | null;
      external_id?: number | null;
      date: string;
      state_id: Types.TaskProposalStateId;
      note?: string | null;
      external_number: string;
      identifier: string;
      address: string;
      sum_gross: number;
      sum_net: number;
      sum_tax: number;
      url_preview: string;
      permissions: Array<Types.Permission>;
      validity_date?: string | null;
      custom_name?: string | null;
      custom_street?: string | null;
      custom_city?: string | null;
      custom_postcode?: string | null;
      task: { __typename?: 'Task'; identifier?: string | null };
      state: { __typename?: 'TaskProposalState'; id: string; title: string; color: string };
      items: Array<{
        __typename?: 'TaskProposalItem';
        id: string;
        position: string;
        title: string;
        unit?: string | null;
        unit_price: number;
        amount: number;
        sum_net: number;
        sum_gross: number;
        tax: number;
      }>;
      file?: { __typename?: 'FileItem'; id: string; file_name: string; url: string } | null;
      custom_salutation?: { __typename?: 'Salutation'; id: string; title: string } | null;
      custom_country?: { __typename?: 'Country'; id: string; title: string } | null;
    } | null;
    state: { __typename?: 'TaskInvoiceState'; id: string; title: string; color: string };
    items: Array<{
      __typename?: 'TaskInvoiceItem';
      id: string;
      position: string;
      title: string;
      unit?: string | null;
      unit_price: number;
      amount: number;
      sum_net: number;
      sum_gross: number;
      tax: number;
    }>;
    payouts?: Array<{
      __typename?: 'TaskInvoicePayout';
      id: string;
      title: string;
      sum_net: number;
      state: {
        __typename?: 'TaskInvoicePayoutState';
        id: string;
        title: string;
        color: string;
        state_id: Types.TaskInvoicePayoutStateId;
      };
    }> | null;
    file?: { __typename?: 'FileItem'; id: string; file_name: string; url: string } | null;
    custom_salutation?: { __typename?: 'Salutation'; id: string; title: string } | null;
    custom_country?: { __typename?: 'Country'; id: string; title: string } | null;
  };
};

export const CompleteTaskInvoiceDocument = gql`
  mutation CompleteTaskInvoice($id: ID!) {
    completeTaskInvoice(id: $id) {
      ...TaskInvoice
    }
  }
  ${TaskInvoiceFragmentDoc}
`;

export function useCompleteTaskInvoiceMutation() {
  return Urql.useMutation<CompleteTaskInvoiceMutation, CompleteTaskInvoiceMutationVariables>(
    CompleteTaskInvoiceDocument,
  );
}
