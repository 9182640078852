import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DeleteTaskFolderMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;

export type DeleteTaskFolderMutation = {
  __typename?: 'Mutation';
  deleteTaskFolder?: { __typename?: 'TaskFolder'; id: string } | null;
};

export const DeleteTaskFolderDocument = gql`
  mutation DeleteTaskFolder($id: ID!) {
    deleteTaskFolder(id: $id) {
      id
    }
  }
`;

export function useDeleteTaskFolderMutation() {
  return Urql.useMutation<DeleteTaskFolderMutation, DeleteTaskFolderMutationVariables>(
    DeleteTaskFolderDocument,
  );
}
