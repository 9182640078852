import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateTaskQualificationsMutationVariables = Types.Exact<{
  task_id: Types.Scalars['ID']['input'];
  qualification_ids: Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input'];
}>;

export type UpdateTaskQualificationsMutation = {
  __typename?: 'Mutation';
  updateTaskQualifications: {
    __typename?: 'Task';
    id: string;
    qualifications: {
      __typename?: 'TaskQualificationList';
      data: Array<{ __typename?: 'Qualification'; title: string; color: string }>;
    };
  };
};

export const UpdateTaskQualificationsDocument = gql`
  mutation UpdateTaskQualifications($task_id: ID!, $qualification_ids: [ID!]!) {
    updateTaskQualifications(task_id: $task_id, qualification_ids: $qualification_ids) {
      id
      qualifications {
        data {
          title
          color
        }
      }
    }
  }
`;

export function useUpdateTaskQualificationsMutation() {
  return Urql.useMutation<
    UpdateTaskQualificationsMutation,
    UpdateTaskQualificationsMutationVariables
  >(UpdateTaskQualificationsDocument);
}
