import { ReactNode, useLayoutEffect, useMemo, useRef } from 'react';

import * as variablesCadetGrey from '../../../../styles/theme/cadet-grey/_export.scss';
import * as variablesDarkLiver from '../../../../styles/theme/dark-liver/_export.scss';
import * as variablesDarkSeaGreen from '../../../../styles/theme/dark-sea-green/_export.scss';
import * as variablesIndigo from '../../../../styles/theme/indigo/_export.scss';
import * as variablesLightBlue from '../../../../styles/theme/light-blue/_export.scss';
import * as variablesMediumBlue from '../../../../styles/theme/medium-blue/_export.scss';
import * as variablesMediumGreen from '../../../../styles/theme/medium-green/_export.scss';
import * as variablesModernGreen from '../../../../styles/theme/modern-green/_export.scss';
import * as variablesOrange from '../../../../styles/theme/orange/_export.scss';
import * as variablesPurple from '../../../../styles/theme/purple/_export.scss';
import styleSheetPurple from '../../../../styles/theme/purple/index.dynscss';
import * as variablesQuickSilver from '../../../../styles/theme/quick-silver/_export.scss';
import * as variablesRed from '../../../../styles/theme/red/_export.scss';
import * as variablesYellow from '../../../../styles/theme/yellow/_export.scss';
import { Theme } from '../../../../types/graphql.generated';
import { useAppSettingsPublicQuery } from '../../../common/graphql';
import { ThemeContext } from './context';
import { createThemeConfig } from './helpers';
import { ThemeConfig, ThemeStyle } from './types';

interface ThemeProviderProps {
  children: ReactNode;
}

// The default theme is not loaded via dynamic import to avoid initial layout glitches.
const defaultThemeConfig = createThemeConfig(Theme.PURPLE, variablesPurple, () => ({
  default: styleSheetPurple,
}));

const themeConfigs: Array<ThemeConfig> = [
  defaultThemeConfig,
  createThemeConfig(
    Theme.CADET_GREY,
    variablesCadetGrey,
    () => import('../../../../styles/theme/cadet-grey/index.dynscss'),
  ),
  createThemeConfig(
    Theme.DARK_LIVER,
    variablesDarkLiver,
    () => import('../../../../styles/theme/dark-liver/index.dynscss'),
  ),
  createThemeConfig(
    Theme.DARK_SEA_GREEN,
    variablesDarkSeaGreen,
    () => import('../../../../styles/theme/dark-sea-green/index.dynscss'),
  ),
  createThemeConfig(
    Theme.INDIGO,
    variablesIndigo,
    () => import('../../../../styles/theme/indigo/index.dynscss'),
  ),
  createThemeConfig(
    Theme.MEDIUM_BLUE,
    variablesMediumBlue,
    () => import('../../../../styles/theme/medium-blue/index.dynscss'),
  ),
  createThemeConfig(
    Theme.MEDIUM_GREEN,
    variablesMediumGreen,
    () => import('../../../../styles/theme/medium-green/index.dynscss'),
  ),
  createThemeConfig(
    Theme.MODERN_GREEN,
    variablesModernGreen,
    () => import('../../../../styles/theme/modern-green/index.dynscss'),
  ),
  createThemeConfig(
    Theme.QUICK_SILVER,
    variablesQuickSilver,
    () => import('../../../../styles/theme/quick-silver/index.dynscss'),
  ),
  createThemeConfig(
    Theme.RED,
    variablesRed,
    () => import('../../../../styles/theme/red/index.dynscss'),
  ),
  createThemeConfig(
    Theme.YELLOW,
    variablesYellow,
    () => import('../../../../styles/theme/yellow/index.dynscss'),
  ),
  createThemeConfig(
    Theme.LIGHT_BLUE,
    variablesLightBlue,
    () => import('../../../../styles/theme/light-blue/index.dynscss'),
  ),
  createThemeConfig(
    Theme.ORANGE,
    variablesOrange,
    () => import('../../../../styles/theme/orange/index.dynscss'),
  ),
];

export const ThemeProvider = (props: ThemeProviderProps) => {
  const [{ data }] = useAppSettingsPublicQuery();

  const themeId = data?.appSettingsPublic.theme ?? defaultThemeConfig.themeId;
  const themeConfig = themeConfigs.find((theme) => theme.themeId === themeId) ?? defaultThemeConfig;
  const loadedStyleSheetRef = useRef<ThemeStyle | undefined>(undefined);

  useLayoutEffect(() => {
    (async () => {
      const styleSheet = await themeConfig.load();
      loadedStyleSheetRef.current?.unuse();
      styleSheet.default.use();
      loadedStyleSheetRef.current = styleSheet.default;
    })();
  }, [themeConfig]);

  const contextValue = useMemo(
    () => ({
      themeConfigs,
      themeConfig,
    }),
    [themeConfig],
  );
  return <ThemeContext.Provider value={contextValue}>{props.children}</ThemeContext.Provider>;
};
