import { TextInput } from '@module/shared/forms';
import { Field } from '@progress/kendo-react-form';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useFillCityByPostcode } from '../../hooks';
import { CountrySelect } from '../inputs';

export interface AddressFormFieldNames {
  street: string;
  street_no: string;
  postcode: string;
  city: string;
  country_id: string;
}

export interface AddressFormFieldsProps {
  fieldNames?: AddressFormFieldNames;
}

export const AddressFormFields = (props: AddressFormFieldsProps) => {
  const { t } = useTranslation();

  const { fieldNames } = props;
  const cityFieldName = fieldNames?.city ?? defaultFieldNames.city;
  const { handlePostcodeChange } = useFillCityByPostcode(cityFieldName);

  return (
    <>
      <div className="row">
        <div className="col-9">
          <Field
            id="contact.create.contact_fields.street"
            name={fieldNames?.street ?? defaultFieldNames.street}
            label={t('common.labels.street')}
            component={TextInput}
          />
        </div>

        <div className="col-3">
          <Field
            id="contact.create.contact_fields.street_no"
            name={fieldNames?.street_no ?? defaultFieldNames.street_no}
            label={t('common.labels.street_no')}
            component={TextInput}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-6">
          <Field
            id="contact.create.contact_fields.postcode"
            name={fieldNames?.postcode ?? defaultFieldNames.postcode}
            label={t('common.labels.postcode')}
            component={TextInput}
            onChange={handlePostcodeChange}
          />
        </div>
        <div className="col-6">
          <Field
            id="contact.create.contact_fields.city"
            name={cityFieldName}
            label={t('common.labels.city')}
            component={TextInput}
          />
        </div>
      </div>

      <Field
        id="contact.create.contact_fields.country_id"
        name={fieldNames?.country_id ?? defaultFieldNames.country_id}
        label={t('common.labels.country')}
        component={CountrySelect}
      />
    </>
  );
};

const defaultFieldNames: AddressFormFieldNames = {
  street: 'street',
  street_no: 'street_no',
  postcode: 'postcode',
  city: 'city',
  country_id: 'country_id',
};

export function useAddressFormFieldNames(prefix: string, separator: string): AddressFormFieldNames {
  return useMemo(() => {
    const copyFieldNames: Record<string, string> = { ...defaultFieldNames };
    for (const key in copyFieldNames) {
      copyFieldNames[key] = prefix + separator + copyFieldNames[key];
    }
    return copyFieldNames as unknown as AddressFormFieldNames;
  }, [prefix, separator]);
}
