import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import { TaskQuestionFragmentDoc } from './taskQuestionFragment.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CompareTaskFragment = {
  __typename?: 'Task';
  id: string;
  identifier?: string | null;
  primary_form?: {
    __typename?: 'TaskForm';
    id: string;
    template: { __typename?: 'TaskTemplate'; id: string; title: string; json?: any | null };
    questions: Array<{
      __typename?: 'TaskQuestion';
      id: string;
      question_id: string;
      task_form_id: string;
      answer?: string | null;
      answer_code?: string | null;
      note?: string | null;
      comment?: string | null;
      images?: Array<string> | null;
      highlighted: boolean;
      force_page_break_after: boolean;
      active_pages?: Array<number> | null;
      rows?: Array<{
        __typename?: 'TaskQuestionTableRow';
        row_id: string;
        question_id: string;
        column1?: string | null;
        column2?: string | null;
        column3?: string | null;
        column4?: string | null;
        column5?: string | null;
        column6?: string | null;
        column7?: string | null;
        column8?: string | null;
      } | null> | null;
      file?: { __typename?: 'FileItem'; id: string } | null;
    }>;
  } | null;
};

export type CompareTaskImageFragment = {
  __typename?: 'FileItem';
  id: string;
  url_thumb?: string | null;
};

export type CompareTasksQueryVariables = Types.Exact<{
  firstTaskId: Types.Scalars['ID']['input'];
  secondTaskId: Types.Scalars['ID']['input'];
}>;

export type CompareTasksQuery = {
  __typename?: 'Query';
  firstTask: {
    __typename?: 'Task';
    id: string;
    identifier?: string | null;
    primary_form?: {
      __typename?: 'TaskForm';
      id: string;
      template: { __typename?: 'TaskTemplate'; id: string; title: string; json?: any | null };
      questions: Array<{
        __typename?: 'TaskQuestion';
        id: string;
        question_id: string;
        task_form_id: string;
        answer?: string | null;
        answer_code?: string | null;
        note?: string | null;
        comment?: string | null;
        images?: Array<string> | null;
        highlighted: boolean;
        force_page_break_after: boolean;
        active_pages?: Array<number> | null;
        rows?: Array<{
          __typename?: 'TaskQuestionTableRow';
          row_id: string;
          question_id: string;
          column1?: string | null;
          column2?: string | null;
          column3?: string | null;
          column4?: string | null;
          column5?: string | null;
          column6?: string | null;
          column7?: string | null;
          column8?: string | null;
        } | null> | null;
        file?: { __typename?: 'FileItem'; id: string } | null;
      }>;
    } | null;
  };
  firstTaskImages: {
    __typename?: 'TaskImagesPaginator';
    data: Array<{ __typename?: 'FileItem'; id: string; url_thumb?: string | null }>;
  };
  secondTask: {
    __typename?: 'Task';
    id: string;
    identifier?: string | null;
    primary_form?: {
      __typename?: 'TaskForm';
      id: string;
      template: { __typename?: 'TaskTemplate'; id: string; title: string; json?: any | null };
      questions: Array<{
        __typename?: 'TaskQuestion';
        id: string;
        question_id: string;
        task_form_id: string;
        answer?: string | null;
        answer_code?: string | null;
        note?: string | null;
        comment?: string | null;
        images?: Array<string> | null;
        highlighted: boolean;
        force_page_break_after: boolean;
        active_pages?: Array<number> | null;
        rows?: Array<{
          __typename?: 'TaskQuestionTableRow';
          row_id: string;
          question_id: string;
          column1?: string | null;
          column2?: string | null;
          column3?: string | null;
          column4?: string | null;
          column5?: string | null;
          column6?: string | null;
          column7?: string | null;
          column8?: string | null;
        } | null> | null;
        file?: { __typename?: 'FileItem'; id: string } | null;
      }>;
    } | null;
  };
  secondTaskImages: {
    __typename?: 'TaskImagesPaginator';
    data: Array<{ __typename?: 'FileItem'; id: string; url_thumb?: string | null }>;
  };
};

export const CompareTaskFragmentDoc = gql`
  fragment CompareTask on Task {
    id
    identifier
    primary_form {
      id
      template {
        id
        title
        json
      }
      questions {
        ...TaskQuestion
      }
    }
  }
  ${TaskQuestionFragmentDoc}
`;
export const CompareTaskImageFragmentDoc = gql`
  fragment CompareTaskImage on FileItem {
    id
    url_thumb
  }
`;
export const CompareTasksDocument = gql`
  query CompareTasks($firstTaskId: ID!, $secondTaskId: ID!) {
    firstTask: task(id: $firstTaskId) {
      ...CompareTask
    }
    firstTaskImages: taskImages(task_id: $firstTaskId) {
      data {
        ...CompareTaskImage
      }
    }
    secondTask: task(id: $secondTaskId) {
      ...CompareTask
    }
    secondTaskImages: taskImages(task_id: $secondTaskId) {
      data {
        ...CompareTaskImage
      }
    }
  }
  ${CompareTaskFragmentDoc}
  ${CompareTaskImageFragmentDoc}
`;

export function useCompareTasksQuery(
  options: Omit<Urql.UseQueryArgs<CompareTasksQueryVariables>, 'query'>,
) {
  return Urql.useQuery<CompareTasksQuery, CompareTasksQueryVariables>({
    query: CompareTasksDocument,
    ...options,
  });
}
