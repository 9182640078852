import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UploadProfileImageMutationVariables = Types.Exact<{
  file?: Types.InputMaybe<Types.Scalars['Upload']['input']>;
}>;

export type UploadProfileImageMutation = {
  __typename?: 'Mutation';
  uploadProfileImage: {
    __typename?: 'User';
    profile_image?: { __typename?: 'FileItem'; file_name: string; url: string; id: string } | null;
  };
};

export const UploadProfileImageDocument = gql`
  mutation UploadProfileImage($file: Upload) {
    uploadProfileImage(file: $file) {
      profile_image {
        file_name
        url
        id
      }
    }
  }
`;

export function useUploadProfileImageMutation() {
  return Urql.useMutation<UploadProfileImageMutation, UploadProfileImageMutationVariables | void>(
    UploadProfileImageDocument,
  );
}
