export default {
  labels: {
    startDocumentation: 'Schreibprozess starten',
    exportReworkDocuments: 'Nacharbeiten exportieren',
    group: 'Gruppierung',
    unassigned: 'Nicht zugewiesen',
    inactive: 'Inaktiv',
    startVideoChat: 'Videochat starten',
    updateVideoChat: 'Videochat bearbeiten',
    external_number: 'Fremdsystem-Nummer',
    identifier: 'Schadennummer',
    created_at: 'Erstellt am',
    updated_at: 'zul. bearbeitet',
    removed_at: 'Gelöscht am',
    restore: 'Wiederherstellen',
    days_until_cleanup: 'Tage bis Löschung',
    policy_number: 'Vers. Nr.',
    customer_name: 'Versicherungsnehmer',
    insurance: 'Versicherung',
    contact_name: 'Ansprechpartner',
    reserve: 'Reserve',
    template_type: 'Schadens-Typ',
    template_id: 'Formular',
    visit_type_id: 'Auftrags-Typ',
    classificationTags: 'Tags',
    subformClassificationTags: 'Unterformular-Tags',
    fileClassificationTags: 'Tags',
    editClassificationTags: 'Tags bearbeiten',
    deleteClassificationTags: 'Tags löschen',
    newFolder: 'Neues Verzeichnis',
    newFolderTemplate: 'Neue Verzeichnis-Vorlage',
    uploadFile: 'Datei hochladen',
    home: 'Home',
    download: 'Herunterladen',
    downloadAllFiles: 'Alle Dateien herunterladen',
    state: 'Status',
    stateNotShownInMapView: 'Dieser Status wird in der Kartenansicht nicht unterstützt',
    owner: 'Regulierer',
    file_size: 'Größe',
    mime_type: 'Typ',
    newEmail: 'Neue E-Mail',
    actions: 'Aktionen',
    call: 'Anrufen',
    claimant: 'Anspruchsteller',
    acceptTask: 'Annehmen',
    declineTask: 'Ablehnen',
    cancelTask: 'Stornieren',
    revertCancelTask: 'Stornierung rückgängig machen',
    email: 'E-Mail an',
    assign: 'Zuweisen',
    changeAssignment: 'Zuweisung ändern',
    revertAssignment: 'Zuweisung zurücksetzen',
    editColumns: 'Spalten bearbeiten',
    editLockColumns: 'Spalten fixieren',
    enableMultiselect: 'Mehrfachauswahl aktivieren',
    disableMultiselect: 'Mehrfachauswahl deaktivieren',
    selectedTasksCount: '{{couter}} Aufträge ausgewählt',
    leader: 'Teamleiter',
    activateTask: 'Auftrag aktivieren',
    createAppointment: 'Ortstermin festlegen',
    createTaskAppointment: 'Auftrag terminieren',
    id: 'Auftragsnummer',
    route: 'Entfernung: {{distance}}, Reisezeit: {{duration}} h',
    goToAppointment: 'Zum Ortstermin springen',
    appointment: 'Ortstermin',
    open_subtasks: 'Offene Aufgaben',
    assignTask: 'Auftrag zuweisen',
    assignTasks: '{{count}} Aufträge zuweisen',
    resetAppointment: 'Ortstermin zurücksetzen',
    taskControlling: 'Controlling zuweisen',
    revertControlling: 'Auftrag zurück zum Regulierer',
    completeTask: 'Auftrag abschließen',
    confirmSendReport: 'Berichtsversand bestätigen',
    notAssigned: 'Nicht zugewiesen',
    setInitialContact: 'Erstkontakt setzen',
    mobile: 'Mobil',
    client: 'Versicherung',
    print: 'PDF Version anzeigen',
    standardversion: 'Standard',
    printversion: 'Druckversion',
    acceptedDate: 'Akzeptiert',
    setInitialContactDate: 'Erstkontakt',
    setOnSiteAppointmentDate: 'Termin vereinbart',
    setReportSentDate: 'Berichtsversand',
    completionDate: 'Abgeschlossen',
    stateChangedDate: 'Letzte Statusänderung',
    sendFileInterface: 'Per Schnittstelle senden',
    editInvoiceMetaData: 'Metainformationen bearbeiten',
    title: 'Titel',
    filePreviewNotSupported: 'Die Vorschau wird für den gewählten Dateityp nicht unterstützt.',
    followUpDate: 'Wiedervorlage',
    reminderDate: 'Mahnung',
    reworkDate: 'Nachbearbeitung',
    controllingDate: 'Kontrolle',
    invoiceDate: 'Rechnung',
    editTags: 'Tags beabeiten',
    uploadImage: 'Bild hochladen',
    uploadImages: 'Bilder hochladen',
    editReminderDates: 'Erinnerungen bearbeiten',
    hint: 'Hinweis',
    task: 'Auftrag',
    reworkTask: 'Auftrag nachbearbeiten',
    reworkTaskDone: 'Nachbearbeitung abgeschlossen',
    totalAttachmentSize: 'Gesamtgröße der Anhänge: {{size}}',
    type: 'Typ',
    totalAttachmentSizeToBig:
      'Gesamtgröße der Anhänge: {{size}}. Achtung, die Gesamtgröße der Anhänge ist > 30 Mbyte - das erfolgreiche Senden der Nachricht kann nicht garantiert werden. ',
    exportStatistics: 'Statistik exportieren',
    sendMessage: 'Nachricht senden',
    sendSms: 'SMS senden',
    highlighted: 'Hervorgehoben',
    subOwner: 'Vertretung',
    subOwners: 'Vertretungen',
    representation: 'Vertretung',
    customerNumber: 'Kundennummer',
    insurancePolicyNumber: 'Versicherungsscheinnummer',
    classifications: 'Klassifikationen',
    noLeader: 'Ohne Teamleiter',
    largeThumbnail: 'Große Vorschaubilder verwenden',
    smallThumbnail: 'Kleine Vorschaubilder verwenden',
    showInactiveAdjusters: 'Inaktive Regulierer einblenden',
    hideInactiveAdjusters: 'Inaktive Regulierer ausblenden',
    firstContactChannel: 'Erfolgreiche Kontaktaufnahme',
    deleteTask: 'Auftrag löschen',
    setReportSentClientName: 'Bericht verschickt von',
  },
  linkableTypes: {
    'Inspcto\\Models\\TaskQuestion': 'Formular',
  },
  video: {
    customerSMS: 'Es wurde ein Videochat erstellt! Treten sie hier bei {{link}}',
  },
  navigation: {
    sections: {
      common: 'Allgemein',
      process: 'Bearbeitung',
      finish: 'Abschluss',
    },
  },
  fieldMenu: {
    insertPageBreak: 'Seitenumbruch einfügen',
    removePageBreak: 'Seitenumbruch entfernen',
    reset: 'Antwort zurücksetzen',
    history: 'Änderungshistory',
    maximizable: 'In einem neuen Fenster bearbeiten',
    highlight: 'Frage hervorheben',
    removeHighlight: 'Hervorhebung der Frage entfernen',
    confirm: {
      title: 'Antwort zurücksetzen?',
      description: 'Soll die Antwort wirklich zurückgesetzt werden?',
      resetNote: 'Die Notiz ebenfalls zurücksetzen',
    },
    addComment: 'Kommentar hinzufügen',
    updateComment: 'Kommentar bearbeiten',
    deleteComment: 'Kommentar löschen',
    addImage: 'Bild hinzufügen',
    addNote: 'Anmerkung hinzufügen',
    confirmDeleteComment: {
      title: 'Kommentar löschen',
      description: 'Soll der Kommentar wirklich entfernt werden?',
      success: 'Kommentar wurde gelöscht',
    },
    commentDialog: {
      title: 'Kommentar zu „{{questionTitle}}“',
      success: 'Kommentar wurde erstellt.',
    },
  },
  tableField: {
    copyFromTable: 'Aus Tabelle übernehmen',
  },
  fieldComment: {
    label: 'Teamleiter Kommentar:',
  },
  autoSaveIndicator: {
    status: {
      debounced: 'Speichern ausstehend…',
      pending: 'Wird gespeichert…',
      fulfilled: 'Gespeichert',
      rejected: 'Speichern fehlgeschlagen',
    },
    retry: 'Erneut versuchen',
  },
  components: {
    subOwnersField: {
      remainingSubOwners: '(Noch {{count}} weitere möglich)',
      ownerValidation: 'Wähle einen Regulierer um Vertretungen zu verwenden.',
      ownerAlreadySubOwner:
        'Regulierer kann nicht gewählt werden, da dieser bereits eine Vertretung ist.',
    },
    taskSubOwnersCell: {
      furtherRepresentations: 'weitere Vertretungen:',
    },
    taskSubOwnersTooltipBadge: {
      title: 'Vertretungen:',
    },
    fileAddAttachments: {
      title: 'Anhänge hinzufügen',
      note: 'Wählen Sie Dateien aus, die Sie anhängen möchten.',
      submit: 'Auswählen',
    },
    taskFileManager: {
      viewMode: {
        grid: 'Dateien in Raster anzeigen',
        list: 'Dateien als Liste anzeigen',
      },
      errors: {
        uploadDisabledFor: 'Upload für Ordner "{{folder}}" ist deaktiviert.',
      },
    },
    selectServiceCatalogItemsGrid: {
      selectionCount: '{{count}} Leistungen ausgewählt',
      deselectAll: 'Alle entfernen',
    },
    spellChecker: {
      undo: 'Rückgängig',
      suggestions: 'Vorschläge',
      scrollToQuestion: 'Frage anzeigen',
      insert: 'Einfügen',
      misspellingsCount:
        '{{misspellings}} potenzielle Fehler in {{questionCount}} Einträgen gefunden',
    },
    sidebar: {
      basic: 'Auftragsdaten',
      customer: 'Kundendaten',
      details: 'Details',
      show: 'Auftrag öffnen',
    },
    gridColumnMenu: {
      title: 'Spalte editieren',
    },
    gridColumnLockMenu: {
      title: 'Spalte fixieren',
    },
    fileContextMenu: {
      edit_duplicate: 'Bearbeiten',
    },
    fileSelectionMenu: {
      download: 'Herunterladen',
      email: 'Per E-Mail versenden',
      delete: 'Löschen',
      updateTags: 'Tags bearbeiten',
    },
    folderContextMenu: {
      rename: 'Umbenennen',
      permissions: 'Berechtigungen',
      delete: 'Löschen',
    },
    folderContent: {
      enableMultiselect: 'Mehrfachauswahl aktivieren',
      disableMultiselect: 'Mehrfachauswahl deaktivieren',
    },
    folderListView: {
      name: 'Name',
      size: 'Größe',
      created_at: 'Erstellt am',
      creator: 'Ersteller',
      updated_at: 'zul.bearbeitet',
      classification_tags: 'Tags',
    },
    invoiceMetaDataDialog: {
      title: 'Rechnungsdaten',
    },
    invoiceMetaDataForm: {
      fields: {
        identifier: {
          label: 'Rechnungsnummer',
        },
        sum_net: {
          label: 'Nettobetrag',
        },
        sum_tax: {
          label: 'Steuerbetrag',
        },
        sum_gross: {
          label: 'Bruttobetrag',
        },
      },
    },
    noRecords: {
      files: {
        title: 'Keine Dateien gefunden',
        text: 'Noch sind keine Dateien im Auftrag.\nSie können hier gerne neue Dateien von Ihrem Computer hochladen.',
        folder: {
          documents: 'Dokumente',
          images: 'Bilder',
        },
        uploadLockedForFolder:
          'Es sind noch keine Dateien vorhanden. \nSie können {{folder}} unter dem Menüpunkt "{{folder}}" hochladen.',
      },
      images: {
        title: 'Keine Bilder gefunden',
        text: 'Noch sind keine Bilder im Auftrag.\nSie können hier gerne neue Bilder von Ihrem Computer hochladen.',
      },
      emails: {
        title: 'Keine E-Mails gefunden',
        text: 'Noch sind keine E-Mails im Auftrag vorhanden.\nSie können hier gerne neue E-Mails versenden.',
      },
      documents: {
        title: 'Keine Dokumente gefunden',
        text: 'Noch sind keine Dokumente im Auftrag.\nSie können hier gerne neue Dokumente von Ihrem Computer hochladen.',
      },
      invoices: {
        title: 'Keine Rechnungen gefunden',
        text: 'Noch sind keine Rechnungen angelegt.\nSie können hier gerne neue Rechnungen hinzufügen.',
      },
      invoice_items: {
        title: 'Keine Positionen gefunden',
        text: 'Noch sind keine Positionen angegeben.\nSie können hier gerne neue Positionen hinzufügen.',
      },
      proposals: {
        title: 'Keine Angebote gefunden',
        text: 'Noch sind keine Angebote angelegt.\nSie können hier gerne neue Angebote hinzufügen.',
      },
      proposal_items: {
        title: 'Keine Positionen gefunden',
        text: 'Noch sind keine Positionen angegeben.\nSie können hier gerne neue Positionen hinzufügen.',
      },
      reports: {
        title: 'Keine Berichte gefunden',
        text: 'Noch sind keine Berichte im Auftrag.\nSie können hier gerne neue Berichte erstellen.',
      },
    },
    taskFolderTemplatesCard: {
      title: 'Verzeichnis Vorlagen',
    },
    taskReportForm: {
      caption: 'Einen neuen Bericht erstellen',
      description:
        'Hier können Sie einen neuen Bericht nach einer Vorlage erstellen, oder über den Erweitert Button einen individuellen Bericht erstellen.',
      report_template_id: 'Berichts-Vorlage',
      report_template_id_description: 'Wählen Sie eine Berichts-Vorlage aus',
      expanded: 'Erweiterte Einstellungen',
      createReport: 'Bericht erzeugen',
      saveAndCreateReport: 'Speichern und Bericht erzeugen',
      custom_report_settings: {
        choose_button: 'Wählen',
        general: {
          section_title: 'Allgemein',
          section_description: 'Hier können Sie die Allgemeine Berichtseinstellungen wählen',
          manual_task_id: 'Alternative Auftragsnummer',
        },
        title: {
          section_title: 'Titelseite',
          section_description: 'Hier können Sie die Einstellungen zur Titelseite wählen.',
          has_cover: 'Titelseite im Bericht anzeigen',
          use_alternate_logo: 'Alternatives Logo verwenden',
          title: 'Titel des Berichts',
        },
        toc: {
          section_title: 'Inhaltsverzeichnis',
          section_description:
            'Hier können Sie die Einstellungen zum Inhaltsverzeichnis vornehmen.',
          has_toc: 'Inhaltsverzeichnis im Bericht anzeigen',
        },
        calculations: {
          section_title: 'Kalkulation',
          section_description: 'Hier können Sie die Einstellungen zu der Kalkulation wählen.',
          has_calc: 'Kalkulation im Bericht anzeigen',
        },
        menu: {
          simple: 'Einfach',
          advanced: 'Erweiterte Einstellungen',
          word: 'Download MS-Word Version',
        },
        summary: {
          section_title: 'Zusammenfassung',
          section_description: 'Hier können Sie die Einstellungen zur Zusammenfassung wählen.',
          has_summary: 'Zusammenfassung im Bericht anzeigen',
        },
        forms: {
          section_title: 'Formular',
          section_description: 'Hier können Sie wählen, ob Sie das Formular anzeigen wollen.',
          form_ids: 'Auswahl der Formulare',
        },
        additionalForms: {
          section_title: 'Unterformulare',
          section_description:
            'Hier können Sie wählen, ob Sie zusätzlich noch Unterformulare anzeigen wollen. Sie können auch nur ein Unterformular für den Bericht verwenden',
          form_ids: 'Auswahl der Unterformulare',
        },
        reworkForms: {
          section_title: 'Nachtragsformulare',
          section_description:
            'Hier können Sie wählen, ob Sie zusätzlich noch Nachtragsformulare anzeigen wollen. Sie können auch nur ein Nachtragsformular für den Bericht verwenden',
          form_ids: 'Auswahl der Nachtragsformulare',
        },
        questions: {
          section_title: 'Formulare',
          section_description: 'Hier können Sie die Einstellungen zur Fragenliste wählen.',
          has_questions: 'Fragenliste im Bericht anzeigen',
          show_questions_without_answers: 'Zeige Fragen ohne Antworten',
          question_filter: 'Fragen anzeigen',
          button_label: 'Spezifische Fragen ausblenden',
          choose_questions_button: 'Fragen ausblenden',
          questions_hide_description: 'Zeige Beschreibung zu Fragen',
          questions_alternate_title: 'Alternativer Titel für Fragenliste',
          show_all: 'Alle',
          show_internal_only: 'Intern',
          show_external_only: 'Extern',
          dialog: {
            label: 'Wählen Sie hier alle Fragen, welche im Bericht nicht angezeigt werden sollen.',
            title: 'Fragen ausblenden',
            inputLabel: 'Fragentitel',
            placeholder: 'Nach Frage suchen...',
            selectAll: 'Alle',
          },
        },
        images: {
          section_title: 'Fotos',
          section_description: 'Hier können Sie die Einstellungen zu den Fotos wählen.',
          has_images: 'Fotos im Bericht anzeigen',
          images_per_page: 'Anzahl Fotos pro Seite',
          dialog_title: 'Fotos wählen',
        },
        documents: {
          section_title: 'Dokumente',
          section_description: 'Hier können Sie die Einstellungen zu den Dokumenten wählen.',
          simple_section_description:
            'Hier können Sie Dokumente direkt wählen, welche sichtbar sein sollen',
          has_documents: 'Dokumente im Bericht anzeigen',
          dialog_title: 'Dokumente wählen',
        },
        reworkDocuments: {
          section_title: 'Nacharbeiten Dokumente',
          section_description:
            'Hier können Sie die Einstellungen zu den Nacharbeiten Dokumenten vornehmen.',
          simple_section_description:
            'Hier können Nacharbeiten Dokumente wählen, die im Bericht angezeigt werden sollen.',
          has_rework_documents: 'Nacharbeiten Dokumente im Bericht anzeigen',
          dialog_title: 'Nacharbeiten Dokumente wählen',
        },
        roomplan: {
          section_title: 'Raumplan',
          section_description: 'Hier können Sie die Einstellungen zum Raumplan wählen.',
          has_room_plan: 'Raumplan im Bericht anzeigen',
        },
        freeText: {
          section_title: 'Freitext',
          section_description: 'Hier können Sie einen beliebigen Freitext zum Bericht hinzufügen.',
          has_freetext: 'Freitext im Bericht anzeigen',
          freetext_title: 'Titel des Freitextes',
          freetext_description: 'Freitext',
          freetext_on_first_page: 'Zeige Freitext an erster Stelle des Berichtes',
        },
        predefinedText: {
          section_title: 'Vordefinierter Text',
          section_description:
            'Hier können Sie einen beliebigen Vordefinierten Text zum Bericht hinzufügen.',
          predefined_text_title: 'Titel des Vordefinierten Text',
          predefined_text_description: 'Vordefinierter Text',
          has_predefined_text: 'Vordefinierter Text im Bericht anzeigen',
        },
        downloadMSWord: {
          section_title: 'MS-Word Version herunterladen',
          section_description:
            'Erzeugt von den aktuellen Daten eine Version für Microsoft Word. Achtung: Änderungen innerhalb der Word Datei werden nicht mehr zurück in das System übernommen.',
          button: 'Herunterladen',
        },
      },
    },
    taskReportListView: {
      title: 'Gespeicherte Berichte',
      createdAt: 'Erstellt am:',
    },
    taskMapAdjusterPopup: {
      assignmentNote: 'Anmerkung Zuweisung:',
      numTasksOpen: '{{numTasksOpen}} offene Aufträge',
      numTasksRework: '{{numTasksRework}} Aufträge in Nacharbeiten',
      adjusterSelectSuccess: 'Regulierer wurde ausgewählt',
      leader: 'Teamleiter',
      tasksCount: '(Offen {{numTasksOpen}}, Nacharbeiten {{numTasksRework}})',
    },
    taskInvoiceGrid: {
      addItem: 'Position hinzufügen',
    },
    taskProposalGrid: {
      addItem: 'Position hinzufügen',
    },
    taskMapTaskPopup: {
      assignedTo: 'Zugewiesen an',
      reserve: 'Reserve',
      reserveValue: '{{reserve}}€',
      assignedOwner: 'Aktueller Regulierer',
      notAssigned: 'Nicht zugewiesen',
    },
    tasksMapSidebar: {
      nav: {
        assignment: 'Zuweisung',
        tasks: 'Aufträge',
      },
    },
    taskRouteListItem: {
      sendLink: 'Link senden!',
      linkSent: 'Link gesendet!',
      sendingLink: 'Wird gesendet!',
    },
    addressFromContactPicker: {
      dropdown: {
        text: 'Daten übernehmen',
        item: 'Daten von {{title}} übernehmen',
      },
    },
    copyTaskAbbreviation: {
      label: 'Abkürzung kopieren',
      success: 'Abkürzung kopiert',
    },
  },
  operators: {
    collection: {
      whereIn: 'Where In',
    },
  },
  dialogs: {
    confirmStartDocumentation: {
      title: 'Schreibprozess starten?',
      description: 'Sind Sie Sicher, dass Sie den Schreibprozess starten wollen?',
    },
    exportReworkDocuments: {
      title: 'Nacharbeiten Dokumente exportieren',
      description:
        'Wollen Sie eine Liste der Nacharbeiten Dokumente samt Status und Tags herunterladen?',
    },
    assignTaskMembers: {
      title: 'Regulierer & Vertretungen zuweisen',
      description: 'Hier können Sie Regulierer und Vertretungen für die Bearbeitung zuweisen.',
      owner: 'Regulierer',
      subOwners: 'Vertretungen',
      remainingSubOwners: '(Noch {{count}} weitere möglich)',
    },
    reAssignTaskOwner: {
      title: 'Auftrag zuweisen',
      description: 'Hier können Sie den Auftrag einem anderen Regulierer zuweisen.',
      owner: 'Regulierer',
    },
    sendDirectMessageFromTemplate: {
      sendMessage: 'Nachricht senden',
      title: 'Nachricht aus Vorlage senden',
      description:
        'Wählen Sie eine Vorlage aus, die Sie versenden möchten. Achtung, mit Klick auf Senden, wird die Nachricht direkt versendet.',
    },
    exportTasksStatisticsCustom: {
      title: 'Statistik exportieren',
      description: 'Hier können Sie die Statistik exportieren.',
      filter: {
        created_from: 'Von',
        created_until: 'Bis',
        client_ids: 'Ausgewählte Versicherungen',
      },
      availableInsurances: 'Verfügbare Versicherungen',
    },
    reworkTask: {
      title: 'Auftrag nachbearbeiten',
      note: 'Sind Sie sicher, dass Sie den Auftrag nachbearbeiten wollen?',
    },
    reworkTaskDone: {
      title: 'Nachbarbeitung abschließen',
      note: 'Sind Sie sicher, dass Sie die Nachbearbeitung abschließen wollen?',
    },
    sendFileToInterface: {
      title: 'Dateien per Schnittstelle senden',
      note: 'Damit werden diese Dateien zum Versand über eine aktive Schnittstelle markiert.',
      confirm: 'Senden',
      messages: {
        success: 'Erfolgreich markiert!',
      },
    },
    setInitialContact: {
      title: 'Erstkontakt setzen',
      note: 'Hier können Sie noch eine Anmerkung zur Kontaktaufnahme mit dem Kunden mitgeben.',
      description: 'Anmerkung',
    },
    confirmControlling: {
      title: 'Auftrag zur Kontrolle übergeben',
      note: 'Damit übergeben Sie den Auftrag an den zugewiesen Teamleiter. Wollen Sie fortfahren?',
      labelConfirm: 'Ja',
      labelCancel: 'Nein',
    },
    confirmResetTaskAssignment: {
      title: 'Möchten Sie die Zuweisung wirklich zurücksetzen?',
      note: 'Damit ist der Auftrag niemandem mehr zugewiesen. Wollen Sie fortfahren?',
      labelConfirm: 'Ja',
      labelCancel: 'Nein',
    },
    confirmRevertControlling: {
      title: 'Auftrag zurück zum Regulierer übergeben',
      note: 'Damit übergeben Sie den Auftrag wieder an den Regulierer. Wollen Sie fortfahren?',
      labelConfirm: 'Ja',
      labelCancel: 'Nein',
    },
    restoreTask: {
      title: 'Auftrag wiederherstellen',
      note: 'Möchten Sie den Auftrag wirklich wiederherstellen?',
      labelConfirm: 'Ja',
      labelCancel: 'Nein',
    },
    completeTask: {
      title: 'Auftrag abschließen',
      note: 'Achtung, damit setzen Sie den Auftrag als abgeschlossen. Eine weitere Bearbeitung ist damit nicht mehr möglich. Wollen Sie fortfahren?',
    },
    cancelTask: {
      title: 'Auftrag stornieren',
      note: 'Eine weitere Bearbeitung ist nach der Stornierung nicht mehr möglich. Wollen Sie fortfahren?',
    },
    cancelTaskWithReason: {
      title: 'Auftrag stornieren',
      note: 'Geben Sie einen Grund für den Stornierungs-Vorgang an. Bitte beachten Sie, dass die Stornierung nicht rückgängig gemacht werden kann.',
    },
    revertCancelTask: {
      title: 'Stornierung rückgängig machen',
      note: 'Die Stornierung wird zurückgenommen. Wollen Sie fortfahren?',
    },
    drivingDialog: {
      title: 'Fahrzeiten bearbeiten',
      note: 'Hier können Sie die Fahrzeiten bearbeiten.',
    },
    renameFileDialog: {
      title: 'Datei umbenennen',
      note: 'Hier können Sie die Datei umbenennen.',
      name: 'Dateiname',
    },
    changeTaskTemplate: {
      title: 'Formular ändern',
      note: 'Achtung, damit ändern Sie das bestehende Formular. Sofern schon Daten ausgefüllt sind, werden diese mit dem neuen Formular verglichen und gegebenenfalls übernommen. Dieser Vorgang kann nicht rückgängig gemacht werden. Wollen Sie fortfahren?',
      template: 'Formular',
      updateSuccess: 'Formular wurde geändert.',
    },
    createAppointment: {
      title: 'Terminkalender',
      note: 'Hier können Sie einen neuen Termin anlegen.',
      buttonName: 'Ortstermin',
      appointmentTitle: 'Ortstermin zum Auftrag {{identifier}}',
      cannotCreateAppointment: 'Ortstermin wurde bereits geplant.',
      createAppointment: 'Termin erstellen',
      calendarTab: {
        description:
          'Um einen Termin zu wählen, bitte einfach den Ortstermin direkt in den Kalender ziehen.',
      },
      manuallyTab: {
        description: 'Geben Sie bitte ein Datum für den Ortstermin ein.',
      },
      tabs: {
        calendar: 'Kalender',
        manually: 'Manuell',
      },
    },
    changeTaskAssignments: {
      title: 'Zuweisung ändern',
      tasks: 'Aufträge',
      adjuster: 'Regulierer',
      note: 'Wählen Sie einen neuen Regulierer aus, der den Auftrag bearbeiten soll.',
      assignedOwner: 'Aktueller Regulierer: {{owner}}',
      noOwnerAssigned: 'Kein Regulierer zugewiesen',
      selectAdjuster: 'Regulierer auswählen...',
      notAssignable: 'Kann nicht zugewiesen werden',
      notAllTasksReassigned:
        'Aufgrund von Änderungen während ihrer Aktion konnten nicht alle Aufträge zugewiesen werden.',
    },
    declineTask: {
      title: 'Auftrag ablehnen',
      note: 'Geben Sie einen Grund für den Ablehnungs-Vorgang an.',
    },
    deleteTask: {
      title: 'Auftrag löschen',
      note: 'Geben Sie ihr Passwort ein, um diesen Auftrag zu löschen.',
    },
    taskFileUpload: {
      title: 'Datei hochladen',
      clearList: 'Zurücksetzen',
    },
    taskFolderDelete: {
      title: 'Verzeichnis löschen',
      note: 'Verzeichnis löschen?',
    },
    taskFolderRename: {
      title: 'Verzeichnis umbenennen',
      note: 'Neuer Verzeichnisname:',
    },
    updateTaskFolderPermissions: {
      title: 'Verzeichnis-Berechtigungen',
      note: 'Zugriffsberechtigungen für Nutzergruppen',
    },
    taskFolderNew: {
      title: 'Neues Verzeichnis',
      description: 'Legen Sie hier ein neues Verzeichnis in diesem Ordner an.',
      note: 'Neuer Verzeichnisname:',
    },
    createTaskFolderTemplate: {
      title: 'Neue Verzeichnis-Vorlage',
      note: 'Hier können Sie weitere Verzeichnis-Vorlangen anlegen.',
    },
    updateTaskFolderTemplate: {
      title: 'Verzeichnis-Vorlage bearbeiten',
      note: 'Hier können Sie die Verzeichnis-Vorlage bearbeiten.',
    },
    deleteTaskFolderTemplate: {
      title: 'Verzeichnis-Vorlage löschen',
      note: 'Verzeichnis-Vorlage löschen?',
    },
    deleteTaskReport: {
      title: 'Bericht löschen',
      note: 'Möchten Sie den Bericht wirklich löschen?',
    },
    previewTaskReport: {
      saveAndSend: 'Speichern und per E-Mail senden',
    },
    previewTaskCalculationReport: {
      title: 'Vorschau Kalkulation',
      export: 'Exportieren',
      save: 'In Dateien speichern',
    },
    replaceTags: {
      title: 'Formular ausfüllen',
      confirm: 'Ja',
      cancel: 'Nein',
      note: 'Es wurden ersetzbare Variablen in diesem Dokument gefunden, möchten Sie diese jetzt befüllen?',
    },
    imagesField: {
      title: 'Bilder wählen',
      note: 'Wählen Sie ein paar Bilder aus, die für die Frage relevant sind.',
      noRecords: {
        title: 'Keine Bilder vorhanden.',
        text: 'Damit Sie Bilder auswählen können, müssen Sie diese erst ins Bilder Verzeichnis hochladen.',
      },
    },
    exportTasks: {
      title: 'Auftragsliste herunterladen',
      note: 'Laden Sie die exportierte Auftragsliste herunter',
    },
    editTaskClassificationTags: {
      title: 'Auftrags-Klassifizierung bearbeiten',
      note: 'Bearbeiten Sie die Klassifizierung des Auftrags.',
    },
    editTaskFileClassificationTags: {
      title: 'Datei-Klassifizierung bearbeiten',
      note: 'Bearbeiten Sie die Klassifizierung der Datei.',
    },
    editDuplicateFile: {
      title: 'Datei bearbeiten',
      note: 'Die Datei wird zur Bearbeitung dupliziert.',
    },
    compareTaskFiles: {
      title: 'Dateien vergleichen',
      note: 'Wählen Sie eine Datei aus welche Sie vergleichen möchten.',
      selectFileToCompare: 'Wähle eine Datei aus',
      showComparisonIndicator: 'Zeilenhelfer anzeigen',
      toggleSyncScrolling: 'Sync Scrolling',
      openServiceCatalog: 'Leistungsverzeichnis einblenden',
      closeServiceCatalog: 'Leistungsverzeichnis ausblenden',
    },
    calculation: {
      save: {
        title: 'Neue Kalkulations-Vorlage',
        note: 'Hier können Sie Ihre Kalkulation als Vorlage abspeichern',
      },
      load: {
        title: 'Vorlage auswählen',
        note: 'Wählen Sie hier Ihre Vorlage aus',
      },
    },
    questionHistory: {
      title: 'Änderungshistory zu "{{title}}"',
      note: 'Hier Sehen Sie alle Änderungen zu dieser Frage',
      resetButton: 'Antwort wiederherstellen',
      labels: {
        performedDate: 'Änderungsdatum',
        userName: 'Von',
        oldValue: 'Ursprungswert',
        newValue: 'Neuer Wert',
        reset: 'Auf Ursprungswert zurücksetzen',
        application: 'Browser',
        mobile: 'Mobil',
        device: 'Gerät',
      },
    },
    resetAppointment: {
      title: 'Ortstermin zurücksetzen',
      note: 'Möchten Sie den Ortstermin wirklich zurücksetzen?',
    },
    sendReport: {
      title: 'Berichtsversand bestätigen',
      note: 'Hiermit bestätigen Sie, dass Sie den Bericht versendet haben, damit wird das im Auftrag vermerkt.\nWollen Sie fortfahren?',
    },
    deleteClassificationTags: {
      title: 'Klassifizierung löschen',
      description: 'Möchten Sie die Klassifizierung wirklich löschen?',
    },
    createAdditionalTaskForm: {
      title: 'Neues Unterformular',
      note: 'Erstellen Sie ein weiteres Unterformular',
    },
    updateAdditionalTaskForm: {
      title: 'Unterformular ändern',
      note: 'Hier können Sie den Titel oder die Klassifikationen des Unterformulars ändern.',
    },
    createReworkTaskForm: {
      title: 'Neues Nachtragsformular',
      note: 'Erstellen Sie ein weiteres Nachtragsformular',
    },
    updateReworkTaskForm: {
      title: 'Nachtragsformular ändern',
      note: 'Hier können Sie den Titel oder die Klassifikationen des Nachtragsformular ändern.',
    },
    followUpDate: {
      title: 'Wiedervorlage konfigurieren',
    },
    reminderDate: {
      title: 'Mahnung konfigurieren',
    },
    deleteTaskSubTask: {
      title: 'Aufgabe löschen?',
      note: 'Möchten Sie diese Aufgabe wirklich löschen?',
    },
    selectFormToCompare: {
      title: 'Auftragsformulare vergleichen',
      submit: 'Vergleichen',
    },
    serviceLevelTimeline: {
      title: 'Service Level',
      description: 'Hier sehen Sie die wichtigen Daten zu diesem Auftrag.',
      dateLabels: {
        createdAt: 'Erstellt am',
        acceptedDate: 'Akzeptiert am',
        setInitialContactDate: 'Erstkontakt am',
        setOnSiteAppointmentDate: 'Termin vereinbart am',
        setReportSentDate: 'Berichtsversand am',
        completionDate: 'Abgeschlossen am',
      },
    },
    sendSms: {
      title: 'SMS senden',
      note: 'Hier können Sie eine SMS versenden. Bitte erfassen Sie dafür eine Telefonnummer.',
      send: 'Senden',
      success: 'SMS wurde erfolgreich versendet.',
      recipient: 'Empfänger',
      message: 'Nachricht',
    },
    setFirstContactChannelDialog: {
      title: 'Welche Kontaktaufnahme war erfolgreich?',
      contactType: 'Kontaktart',
      contactTypes: {
        email: 'E-Mail',
        mail: 'Post',
        phone: 'Telefon',
        sms: 'SMS',
      },
    },
  },
  notifications: {
    assignmentChanged: 'Zuweisung wurde erfolgreich geändert.',
    taskOwnerReAssigned: 'Auftrag wurde erfolgreich zugewiesen.',
    syncInvoices: {
      success: 'Rechnung erlfolgreich Syncronisieirt',
      error: 'Leider trat ein Fehler bei der Syncronisierung auf.',
    },

    resetToPriorState: 'Eingabe wurde auf einen früheren Wert zurückgesetzt.',
    resetTaskAssignment: {
      success: 'Zuweisung wurde erfolgreich zurückgesetzt!',
      error: 'Beim Zurücksetzen ist ein Fehler aufgetreten.',
    },
    forcePageBreakSuccess: 'Zeilenumbruch wurde eingefügt.',
    suggestTaskInvoiceItems: {
      noSuggestions: 'Es wurden keine Vorschläge gefunden.',
      success: 'Vorschläge wurden erfolgreich übernommen.',
    },
    deleteTaskReport: {
      success: 'Bericht wurde gelöscht.',
      error: 'Bericht konnte nicht gelöscht werden.',
    },
    createCalendarEvent: {
      success: 'Ortstermin erfolgreich angelegt',
      error: 'Ortstermin konnte nicht angelegt werden',
    },
    acceptTask: {
      success: 'Auftrag wurde angenommen',
      error: 'Der Auftrag kann nicht angenommen werden.',
    },
    duplicateTask: {
      success: 'Auftrag wurde dupliziert',
      error: 'Auftrag konnte nicht dupliziert werden.',
    },
    exportTask: {
      success: 'Auftragsliste erfolgreich exportiert.',
      error: 'Auftragsliste konnte nicht exportiert werden.',
    },
    updateTask: {
      success: 'Auftrag wurde gespeichert.',
      error: 'Auftrag konnte nicht gespeichert werden.',
    },
    updateSubTask: {
      success: 'Todo wurde gespeichert.',
      error: 'Todo konnte nicht gespeichert werden.',
    },
    updateTaskContact: {
      success: 'Kontakt wurde gespeichert.',
      error: 'Kontakt konnte nicht gespeichert werden.',
    },
    deleteTaskContact: {
      success: 'Kontakt wurde gelöscht.',
      error: 'Kontakt konnte nicht gelöscht werden.',
    },
    updateTaskLocation: {
      success: 'Ort wurde gespeichert.',
      error: 'Ort konnte nicht gespeichert werden.',
    },
    createTaskReport: {
      success: 'Bericht erfolgreich angelegt!',
    },
    createTaskCalculationReport: {
      success: 'Kalkulations-Bericht erfolgreich angelegt!',
    },
    createTaskFolderTemplate: {
      success: 'Verzeichnis-Vorlage angelegt!',
    },
    updateTaskFolderTemplate: {
      success: 'Verzeichnis-Vorlage aktualisiert!',
    },
    deleteTaskFolderTemplate: {
      success: 'Verzeichnis-Vorlage gelöscht!',
    },
    updateFile: {
      success: 'Datei wurde gespeichert.',
      error: 'Datei konnte nicht gespeichert werden.',
    },
    addPdfTemplate: {
      success: 'Formular wurde hinzugefügt.',
      error: 'Formular konnte nicht hinzugefügt werden.',
    },
    saveTaskInvoice: {
      success: 'Rechnung wurde gespeichert.',
      error: 'Rechnung konnte nicht gespeichert werden.',
    },
    sendTaskInvoice: {
      success: 'Rechnung wurde gesendet.',
      error: 'Rechnung konnte nicht gesendet werden.',
    },
    saveTaskProposal: {
      success: 'Angebot wurde gespeichert.',
      error: 'Angebot konnte nicht gespeichert werden.',
    },
    sendTaskProposal: {
      success: 'Angebot wurde gesendet.',
      error: 'Angebot konnte nicht gesendet werden.',
    },
    deleteTaskImages: {
      success: 'Bilder wurden gelöscht.',
      error: 'Bilder konnten nicht gelöscht werden.',
    },
    deleteTaskDocuments: {
      success: 'Dokumente wurden gelöscht.',
      error: 'Dokumente konnten nicht gelöscht werden.',
    },
    deleteTaskFiles: {
      success: 'Dateien wurden gelöscht.',
      error: 'Dateien konnten nicht gelöscht werden.',
    },
    createTaskFolder: {
      success: 'Verzeichnis wurde erstellt.',
      error: 'Verzeichnis konnte nicht erstellt werden.',
    },
    deleteTaskFolder: {
      success: 'Verzeichnis wurde gelöscht.',
      error: 'Verzeichnis konnte nicht gelöscht werden.',
    },
    updateTaskFolderPermissions: {
      success: 'Berechtigungen wurden gespeichert.',
      error: 'Berechtigungen konnten nicht gespeichert werden.',
    },
    editTaskClassificationTags: {
      success: 'Auftrags-Klassifizierung gespeichert!',
    },
    editTaskFileClassificationTags: {
      success: 'Datei-Klassifizierung gespeichert!',
    },
    moveFileToFolder: {
      error: '{{filename}} konnte nicht in {{folder_name}} verschoben werden!',
      folder_type: '{{filename}} kann nicht in {{folder_name}} verschoben werden!',
      same_folder: '{{filename}} befindet sich bereits in {{folder_name}}!',
      success: '{{filename}} wurde in {{folder_name}} verschoben!',
    },
    editDuplicateFile: {
      success: '{{filename}} wurde dupliziert!',
    },
    createRoomPlanerItem: {
      success: 'Raum wurde erfolgreich erstellt.',
      error: 'Raum konnte nicht erstellt werden.',
    },
    updateRoomPlanerItem: {
      success: 'Raum wurde erfolgreich aktualisiert.',
      error: 'Raum konnte nicht aktualisiert werden.',
    },
    renameFile: {
      success: 'Die Datei wurde umbenannt.',
    },
    createAdditionalTaskForm: {
      success: 'Formular wurde erstellt.',
    },
    updateAdditionalTaskForm: {
      success: 'Formular wurde aktualisiert.',
    },
    createReworkTaskForm: {
      success: 'Formular wurde erstellt.',
    },
    updateReworkTaskForm: {
      success: 'Formular wurde aktualisiert.',
    },
    updateTaskFollowUpDate: {
      success: 'Wiedervorlage wurde gespeichert.',
    },
    updateTaskReminderDate: {
      success: 'Mahnung wurde gespeichert.',
    },
    updateInvoiceMetaData: {
      success: 'Rechnungsdaten wurden gespeichert.',
    },
    updateTaskDocumentVisibility: {
      error: 'Bei der Änderung der Sichtbarkeit des Dokuments ist ein Fehler aufgetreten.',
    },
    setFirstContactChannel: {
      error: 'Erstkontakt konnte nicht gespeichert werden',
      success: 'Erstkontakt wurde gespeichert',
    },
  },
  mediaGrid: {
    toolbar: {
      deleteSelected: 'Auswahl löschen',
      downloadSelected: 'Auswahl herunterladen',
      downloadAll: 'Herunterladen',
      upload: 'Hochladen',
    },
    columns: {
      description: 'Beschreibung',
      visible: 'Sichtbar',
      isCover: 'Titelbild',
      classificationTags: 'Tags',
    },
    rowActions: {
      applyDescription: 'Beschreibung übernehmen',
      delete: 'Löschen',
      rename: 'Datei umbenennen',
      compare: 'Dokumente vergleichen',
      download: 'Herunterladen',
    },
    dialogs: {
      applyDescription: {
        title: 'Beschreibung übernehmen',
        note: 'Sie können hier für mehrere Bilder eine Beschreibung übernehmen. Dadurch werden alle gewählten Bilder mit dem gleichen Text überschrieben.',
        descriptionLabel: 'Beschreibung:',
        submit: 'Beschreibung übernehmen',
      },
      delete: {
        warning: 'Sind Sie sicher, dass sie dieses Dokument löschen wollen?',
      },
    },
    descriptionPlaceholder: 'Beschreibung hinzufügen',
    rowLoader: 'Hochgeladene Datei wird bearbeitet... Bitte warten.',
  },
  invoice: {
    title: 'Rechnung',
    number: 'Rechnungsnummer',
    date: 'Datum',
    state: 'Status',
    sumGross: 'Summe (brutto)',
    externalNumber: 'Schadennummer',
    external_invoice_url: 'Ext. Rechnung',
    proposal_number: 'Angebotsnummer',
    internalNumber: 'Interne Auftragsnummer',
    identifier: 'Rechnungs Nummer',
    address: 'Adresse',
    position: 'Position',
    article: 'Artikel',
    amount: 'Menge',
    unit: 'Einheit',
    singlePrice: 'Einzel',
    amountUser: 'RL (netto)',
    amountLeader: 'TL (netto)',
    sumPosNet: 'Insgesamt (netto)',
    sumNet: 'Summe (netto)',
    sumTax: 'Summe (MwSt.)',
    tax: 'Steuer',
    sum: 'Summe',
    payoutTo: 'Zahlung an',
    generate: 'Entwurfsrechnung generieren',
    searchForArticle: 'Artikel suchen',
    unitPrice: 'Einzel',
    note: 'Anmerkung',
    supplyDate: 'Leistungsdatum',
    overdueDate: 'Fälligkeitsdatum',
    name: 'Name',
    salutation: 'Anrede',
  },
  proposal: {
    title: 'Angebot',
    number: 'Angebotsnummer',
    date: 'Datum',
    state: 'Status',
    sumGross: 'Summe (brutto)',
    externalNumber: 'Schadennummer',
    external_proposal_url: 'Ext. Angebot',
    internalNumber: 'Interne Auftragsnummer',
    identifier: 'Angebots Nummer',
    address: 'Adresse',
    position: 'Position',
    article: 'Artikel',
    amount: 'Menge',
    unit: 'Einheit',
    singlePrice: 'Einzel',
    amountUser: 'RL (netto)',
    amountLeader: 'TL (netto)',
    sumPosNet: 'Insgesamt (netto)',
    sumNet: 'Summe (netto)',
    sumTax: 'Summe (MwSt.)',
    tax: 'Steuer',
    sum: 'Summe',
    payoutTo: 'Zahlung an',
    generate: 'Entwurfsrechnung generieren',
    searchForArticle: 'Artikel suchen',
    unitPrice: 'Einzel',
    validityDate: 'Gültig bis',
    name: 'Name',
    salutation: 'Anrede',
    note: 'Anmerkungen',
  },
  pages: {
    riskCommon: {
      policyHolderData: {
        title: 'Versicherungsnehmer',
        policyHolder: 'Versicherungsnehmer',
        primaryLocation: 'Firmensitz',
        notes: 'Notizen',
      },
      locationData: {
        title: 'Risikoort',
        tripPlanner: 'Routenplaner',
        editDialog: {
          title: 'Besichtigungsort',
          description: 'Hier können Sie die Anschrift des Besichtigungsorts bearbeiten.',
        },
      },
    },
    reworkDocuments: {
      title: 'Nachtragsdokumente',
      description: 'Hier können Sie die Dokumente verwalten, die eine Nachbearbeitung erfordern',
    },
    reworkForms: {
      title: 'Nachtragsformulare',
      description: 'Hier können Sie die Formulare verwalten, die eine Nachbearbeitung erfordern',
      labels: {
        addForm: 'Neues Nachtragsformular',
        goToForm: 'Zum Formular',
        restoreForm: 'Wiederherstellen',
        duplicateForm: 'Duplizieren',
        softDeleteForm: 'In den Papierkorb verschieben',
        hardDeleteForm: 'Endgültig löschen',
        reorderAlphabetically: 'Alphabetisch Ordnen A-Z',
        forms: 'Formulare',
        bin: 'Papierkorb',
        move: 'Verschieben',
        deletionNotice: 'Nachtragsformulare im Papierkorb werden nach {{days}} Tagen gelöscht',
      },
      dialogs: {
        restoreForm: {
          title: 'Nachtragsformular wiederherstellen',
          note: 'Möchten Sie das ausgewählte Nachtragsformular wiederherstellen?',
        },
        softDelete: {
          title: 'In den Papierkorb verschieben',
          note: 'Möchten Sie das ausgewählte Nachtragsformular in den Papierkorb verschieben?',
        },
        hardDelete: {
          title: 'Nachtragsformular endgültig löschen',
          note: 'Möchten Sie das ausgewählte Nachtragsformular endgültig löschen? Dies kann nicht rückgängig gemacht werden.',
        },
      },
      notifications: {
        deleteSuccess: 'Nachtragsformular wurde gelöscht',
        softDeleteForm: {
          success: 'Nachtragsformular wurde in den Papierkorb verschoben',
          error: 'Nachtragsformular konnte nicht in den Papierkorb verschoben werden',
        },
        restoreForm: {
          success: 'Nachtragsformular wurde wiederhergestellt',
          error: 'Nachtragsformular konnte nicht wiederhergestellt werden',
        },
        duplicateForm: {
          success: 'Nachtragsformular wurde dupliziert',
          error: 'Nachtragsformular konnte nicht dupliziert werden',
        },
      },
      noRecords: {
        title: 'Der Papierkorb ist leer',
      },
    },
    createTask: {
      title: 'Neuer Auftrag',
      description: 'Hier können Sie neue Aufträge anlegen.',
      indentifier: 'Vorläufige Auftragsnummer:',
    },
    riskCreateTask: {
      title: 'Neue Besichtigung',
      description: 'Hier können Sie neue Besichtigungen anlegen.',
    },
    folderTemplates: {
      title: 'Standard-Ordner',
      description: 'Hier können sie Standard-Ordner anlegen.',
    },
    videoConference: {
      title: 'Videokonferenz',
      description: 'Hier können Sie eine Videokonferenz mit einem Kunden durchführen.',
    },
    subTasks: {
      title: 'Aufgaben',
      description: 'Hier finden Sie eine Liste mit den Ihnen zugewiesenen Aufgaben.',
    },
    subTasksAdmin: {
      title: 'Aufgaben',
      description: 'Hier finden Sie eine Liste mit allen Aufgaben.',
    },
    events: {
      title: 'Kalender',
      description:
        'Hier finden Sie alle Ihre aktuellen Termine. Ebenfalls können Sie private Termine eintragen.',
    },
    overview: {
      title: 'Aufträge',
      description: 'Hier finden Sie eine Liste mit detailierten Auftrags-Details.',
      list: 'Liste',
      map: 'Karte',
      numTasksFound: '{{numTasks}} Aufträge gefunden',
      toolbar: {
        create: 'Auftrag anlegen',
        filter_placeholder: 'Suchen...',
        openSidebar: 'Seitenleiste einblenden',
        closeSidebar: 'Seitenleiste ausblenden',
        resetFilter: 'Filter löschen',
      },
      mapSettings: {
        appointment: 'Ortstermin',
        adjusterNames: 'Regulierername',
      },
    },
    bin: {
      title: 'Papierkorb',
      description:
        'Hier finden Sie alle gelöschten Aufträge. Diese werden nach {{days}} Tagen endgültig gelöscht.',
    },
    common: {
      title: 'Auftragsdaten',
      description:
        'Hier sehen Sie alle allgemeinen Daten zu dem Auftrag. Notwendige Daten können pro Block noch angepasst werden.',
      baseData: {
        title: 'Basisdaten',
        dialogs: {
          editBaseData: {
            title: 'Basisdaten',
            note: 'Hier können Sie die Basisdaten bearbeiten.',
          },
        },
      },
      contacts: {
        title: 'Kontakte',

        dialogs: {
          createContact: {
            title: 'Kontakt erstellen',
            note: 'Hier können Sie weitere Kontakte erstellen.',
          },
          editContact: {
            title: 'Kontakt bearbeiten',
            note: 'Hier können Sie den Kontakt bearbeiten.',
          },
        },
        deleteDialog: {
          title: 'Kontakt löschen',
          warning: 'Möchten Sie den Kontakt wirklich löschen?',
        },
      },
      location: {
        title: 'Besichtigungsort',
        dialogs: {
          editLocation: {
            title: 'Besichtigungsort',
            note: 'Hier können Sie die Anschrift des Besichtigungsorts bearbeiten.',
          },
        },
        labels: {
          map: 'Routenplanung über Google Maps öffnen',
          tripplanner: 'Routenplaner',
        },
      },
      template: {
        title: 'Formular',
        dialogs: {
          editTemplate: {
            title: 'Formular',
            note: 'Hier können Sie die Formular-Vorlage auswählen und die Zuweisung von Regulierer und Teamleiter anpassen.',
          },
          editForm: {
            title: 'Formular bearbeiten',
            description: 'Hier können Sie das Formular bearbeiten.',
          },
          editLeader: {
            title: 'Teamleiter',
            description: 'Hier können Sie den Teamleiter bearbeiten.',
          },
          editOwner: {
            title: 'Regulierer',
            description: 'Hier können Sie den Regulierer bearbeiten.',
          },
        },
      },
      damage: {
        title: 'Schaden',
        dialogs: {
          editDamage: {
            title: 'Schaden',
            note: 'Hier können Sie den Schaden bearbeiten.',
          },
        },
      },
    },
    todos: {
      title: 'Aufgaben',
      description:
        'Hier finden Sie alle relevanten Aufgaben zu diesem Auftrag. Sie können auch weitere Aufgaben selbst anlegen.',
    },
    form: {
      title: 'Formular',

      notification: {
        description:
          'Dieses Formular kann ebenfalls von anderen Personen, die Zugriff auf das Formular haben, bearbeitet werden.',
        title: 'Bearbeitung des Formulars',
      },
    },
    subforms: {
      title: 'Unterformulare',
      notification: {
        description:
          'Dieses Unterformular kann ebenfalls von anderen Personen, die Zugriff auf das Formular haben, bearbeitet werden.',
        title: 'Bearbeitung des Unterformulars',
      },
    },
    additionalForms: {
      labels: {
        addForm: 'Neues Unterformular',
        goToForm: 'Zum Formular',
        restoreForm: 'Wiederherstellen',
        duplicateForm: 'Duplizieren',
        softDeleteForm: 'In den Papierkorb verschieben',
        hardDeleteForm: 'Endgültig löschen',
        reorderAlphabetically: 'Alphabetisch Ordnen A-Z',
        forms: 'Formulare',
        bin: 'Papierkorb',
        move: 'Verschieben',
        deletionNotice: 'Unterformulare im Papierkorb werden nach {{days}} Tagen gelöscht',
      },
      dialogs: {
        restoreForm: {
          title: 'Unterformular wiederherstellen',
          note: 'Möchten Sie das ausgewählte Unterformular wiederherstellen?',
        },
        softDelete: {
          title: 'In den Papierkorb verschieben',
          note: 'Möchten Sie das ausgewählte Unterformular in den Papierkorb verschieben?',
        },
        hardDelete: {
          title: 'Unterformular endgültig löschen',
          note: 'Möchten Sie das ausgewählte Unterformular endgültig löschen? Dies kann nicht rückgängig gemacht werden.',
        },
      },
      notifications: {
        deleteSuccess: 'Formular wurde gelöscht',
        softDeleteForm: {
          success: 'Formular wurde in den Papierkorb verschoben',
          error: 'Formular konnte nicht in den Papierkorb verschoben werden',
        },
        restoreForm: {
          success: 'Formular wurde wiederhergestellt',
          error: 'Formular konnte nicht wiederhergestellt werden',
        },
        duplicateForm: {
          success: 'Formular wurde dupliziert',
          error: 'Formular konnte nicht dupliziert werden',
        },
      },
      noRecords: {
        title: 'Der Papierkorb ist leer',
      },
    },
    statistics: {
      title: 'Statistik',
      description:
        'Hier finden Sie die Statistik zu dem Auftrag. Diese enthält relevante Fragen für einen Export an Statistikdaten und kann beim Auftrag selbst dynamisch zugewiesen werden.',
    },
    images: {
      title: 'Bilder',
      description:
        'Hier finden Sie alle Bilder zum Auftrag. Sie können auch weitere Bilder auch weitere Bilder ganz einfach mit der Upload-Funktion zum Auftrag hinzufügen.',
      grid: {
        columns: {
          thumb: 'Bild',
          cover: 'Titelbild',
        },
        toolbar: {
          exportPdf: 'PDF exportieren',
        },
      },
      dialogs: {
        delete: {
          title: 'Bilder löschen',
          note: 'Möchten Sie die ausgewählen Bilder wirklich löschen?',
        },
        upload: {
          title: 'Bilder hochladen',
          note: 'Laden Sie hier weitere Bilder für Ihren Auftrag hoch. Bilder werden automatische auf die maximale Größe verkleinert',
          cancel: 'Abbrechen',
          submit: 'Hochladen',
        },
        editImage: {
          cancel: 'Abbrechen',
          submit: 'Bild speichern',
          confirm: {
            title: 'Änderungen speichern?',
            description:
              'Sie haben Änderungen am Bild vorgenommen. Wollen Sie diese verwerfen oder speichern?',
          },
        },
        previewImagesReport: {
          title: 'Vorschau Fotobericht',
          export: 'Exportieren',
        },
      },
      actions: {
        showAllImages: 'Alle Bilder im Bericht anzeigen',
        hideAllImages: 'Alle Bilder im Bericht ausblenden',
        sortAssistant: 'Sortierungs-Assistent',
      },
    },
    documents: {
      title: 'Dokumente',
      description:
        'Hier finden Sie alle PDF Dokumente zum Auftrag. Über Vorlagen können Sie hinterlegte Dokumente hinzufügen und über “Hochladen” können Sie weitere Dokumente auswählen. Dokumente im Ordner “Dokumente” werden nicht in die App übertragen.',
      grid: {
        toolbar: {
          addPdfTemplate: 'Vorlage',
        },
        columns: {
          thumb: 'Dokument',
          exportedPages: {
            title: 'Aktive Seiten',
            noActivePages: 'Keine',
            allActivePages: 'Alle',
            noActivePagesTooltip:
              'Anzahl der Seiten überschreitet das das Maximum von {{max_export_pages}} Seiten für Berichte. Bitte wählen Sie die Seiten manuell aus.',
          },
        },
        rowActions: {
          selectExportedPages: 'Aktive Seiten festlegen',
          selectAllPages: 'Alle Seiten aktivieren',
        },
        pageCount: 'Seitenanzahl: {{count}}',
      },
      dialogs: {
        delete: {
          title: 'Dokumente löschen',
          note: 'Möchten Sie die ausgewählen Dokumente wirklich löschen?',
        },
        deleteLinked: {
          title: 'Dokument Verlinkung vorhanden.',
          description:
            'In der Auswahl die gelöscht werden soll ist eine Verlinkung zu einer anderen Ressource vorhanden. \nWenn Sei mit dem löschen fortfahren, gehen auch solche Verlinkungen verloren. \nWollen Sie mit dem löschen fortfahren?',
          linkedDocuments: 'Verlinkte Dokumente',
        },
        upload: {
          title: 'Dokumente hochladen',
          note: 'Laden Sie hier weitere Dokumente für Ihren Auftrag hoch.',
          submit: 'Hochladen',
        },
        tabs: {
          paperTemplate: 'Papiervorlagen',
          pdfTemplate: 'PDF Formulare',
        },
        addPdfTemplate: {
          title: 'Vorlage hinzufügen',
          note: 'Sie können hier direkt über eine Vorlage weitere Elemente als Basis für den Auftrag hinzufügen.',
          noRecords: {
            title: 'Sie haben noch keine PDF Formulare hochgeladen.',
            buttonLabel: 'Formulare verwalten',
            notice:
              'Es sind noch keine PDF-Vorlagen  hinterlegt worden. Bitte wenden Sie sich an Ihren Systemadministrator, wenn Sie ein PDF-Dokument als Standard-Vorlage hinterlegen möchten.',
          },
        },
        document: {
          submit: 'Dokument speichern',
          currentPage: 'Aktuelle Seite:',
          showInReport: 'Im Bericht anzeigen',
          limit: 'Für Bericht ausgewählt:',
          documentSize: 'Dateigröße:',
          documentSizeTooltip:
            'Die Dateigröße des Dokuments darf {{maxSize}} nicht überschreiten. Sollte durch die Bearbeitung des Dokuments die Dateigröße überschritten werden, kann das Dokument nicht gespeichert werden.',
        },
        exportedPages: {
          title: 'Aktive Seiten festlegen',
          note: 'Hier können Sie die aktiven Seiten des PDF Dokuments für die Nutzung in Berichten festlegen. Beachten Sie, dass es ein Limit von {{max_export_pages}} Seiten für Berichte gibt.',
          pageNumber: 'Seite {{pageNumber}}',
          selectAll: 'Alle auswählen',
          deselectAll: 'Alle abwählen',
          fileCount: 'Seitenanzahl:',
          selectedFiles: 'Für Bericht ausgewählt:',
          selectPagesInput: {
            limitExceeded: 'Zu viele Seiten',
            notFound: 'Seite nicht gefunden',
            exampleInput: 'Bsp.: 1, 3-5, 10',
            exampleInputTooltip:
              'Seiteneingabe mit Trennzeichen ",". Bereichsangabe mit "-". Bsp.: 1, 3-5, 10',
          },
        },
      },
      actions: {
        showAllDocuments: 'Alle Dokumente im Bericht anzeigen',
        hideAllDocuments: 'Alle Dokumente im Bericht ausblenden',
      },
    },
    emails: {
      title: 'E-Mails',
      description:
        'Hier finden Sie alle E-Mails von diesem Auftrag. Man kann auch hier direkt E-Mails an beteiligte Personen verschicken.',
    },
    invoices: {
      title: 'Rechnungen',
      description: 'Hier finden Sie alle Rechnungen zum Auftrag',
      suggestArticles: 'Artikel vorausfüllen',
      positions: 'Positionen',
      toolbar: {
        createInvoice: 'Rechnung erstellen',
      },
      dialogs: {
        generalData: {
          title: 'Allgemeine Rechnungsdaten',
          description: 'Hier können Sie allgemeine Rechnungsdaten eintragen.',
        },
        createInvoice: {
          title: 'Rechnung erstellen',
          generate: 'Entwurfsrechnung generieren',
          generalData: 'Allgemeine Rechnungsdaten',
        },
        deleteInvoice: {
          title: 'Rechnung löschen',
          note: 'Wollen Sie die Rechnung wirklich löschen?',
        },
        editInvoice: {
          title: 'Rechnung bearbeiten - {{external_invoice_number}}',
          generalData: 'Allgemeine Rechnungsdaten',
        },
        previewInvoice: {
          title: 'Rechnung Vorschau - {{external_invoice_number}}',
          sendInvoice: 'Rechnung erzeugen',
        },
      },
      notifications: {
        localStorageLoaded: 'Gespeicherte Rechnungsdaten wurden geladen.',
        localStorageSaved:
          'Die Eingaben wurden lokal gespeichert, um es später nochmal zu versuchen.',
      },
    },
    proposals: {
      title: 'Angebote',
      description: 'Hier finden Sie alle Angebote zum Auftrag',
      suggestArticles: 'Artikel vorausfüllen',
      positions: 'Positionen',
      toolbar: {
        createProposal: 'Angebot erstellen',
      },
      dialogs: {
        generalData: {
          title: 'Allgemeine Angebotsdaten',
          description: 'Hier können Sie die allgemeinen Angebotsdaten anpassen.',
        },
        createProposal: {
          title: 'Angebot erstellen',
          generate: 'Angebotsentwurf generieren',
          generalData: 'Allgemeine Angebotsdaten',
        },
        deleteProposal: {
          title: 'Angebot löschen',
          note: 'Wollen Sie das Angebot wirklich löschen?',
        },
        editProposal: {
          title: 'Angebot bearbeiten - {{external_proposal_number}}',
          generalData: 'Allgemeine Angebotsdaten',
        },
        previewProposal: {
          title: 'Angebots Vorschau - {{external_proposal_number}}',
          sendProposal: 'Angebot erzeugen',
        },
      },
      notifications: {
        localStorageLoaded: 'Gespeicherte Rechnungsdaten wurden geladen.',
        localStorageSaved:
          'Die Eingaben wurden lokal gespeichert, um es später nochmal zu versuchen.',
      },
    },
    calculation: {
      title: 'Kalkulation',
      description:
        'Hier finden Sie die Kalkulation zu diesem Auftrag. Es können hier ganz einfach Gewerke kalkuliert werden und eine Übersicht der Daten als PDF angezeigt werden.',
    },
    files: {
      title: 'Dateien',
      description:
        'Hier finden Sie alle Dateien und Dokumente zu diesem Auftrag in einer Ordnerstruktur. Hier können Sie auch weitere Dokumente hochladen.',
    },
    report: {
      title: 'Berichte',
      description:
        'Hier können Sie weitere Berichte erzeugen und diese an beteiligte Personen zusenden.',
    },
    appointment: {
      title: 'Termine',
      description:
        'Hier finden Sie alle Ihre Termine. Relevante Termine für den Auftrag können hier hinzugefügt werden.',
    },
    roomItems: {
      title: 'Raumübersicht',
      description: 'Hier können Sie eine Übersicht über alle notwendigen Räume erzeugen.',
    },
    logs: {
      title: 'Akte',
      description: 'Hier sehen Sie alle relevanten Statusänderungen zu diesem Auftrag.',
    },
    customerPortal: {
      title: 'Kundenportal',
      description:
        'Hier können Sie weitere beteiligte Personen zu dem Auftrag einladen. Dies können z.B.: Kundenlinks als auch Einladungen für eine Videokonferenz sein.',
    },
    compareTasks: {
      title: 'Auftragsformulare vergleichen',
      flipOrderTooltip: 'Reihenfolge tauschen',
    },
    listTask: {
      title: 'Tabellarisches Auftragsformular',
    },
  },
  customerPortal: {
    table: {
      addItem: 'Meeting erstellen',
      noRecords: {
        title: 'Keine Einträge gefunden',
        description: 'Noch sind keine Einträge vorhanden. Sie können gerne ein Meeting erstellen.',
      },
    },
  },
  roomPlan: {
    table: {
      addItem: 'Neuer Raum',
      noRecords: {
        title: 'Keine Einträge gefunden',
        description: 'Noch sind keine Einträge vorhanden. Sie können gerne einen Raum erstellen.',
      },
    },
  },
  calculation: {
    overview: {
      title: 'Kalkulation',
      noCategory: 'Nicht zugewiesen',
      sumWithoutTax: 'Gesamtsumme ohne Steuer (Netto)',
      onlySumWithoutTax: 'Gesamtsumme',
      tax: 'Gesamte Steuer ({{rate}})',
      sumWithTax: 'Gesamtsumme (Brutto)',
    },
    table: {
      title: 'Titel & Beschreibung',
      category: 'Kategorie',
      description: 'Beschreibung',
      unit: 'Einheit',
      amount: 'Menge',
      pricePerUnit: 'Preis / Einheit',
      taxCalculated: 'Steuern',
      tax: 'MwSt.',
      timeValue: 'Zeitwert',
      sum: 'Summe',
      total: 'Zwischensumme',
      state: 'Status',
      payoutState: 'Bezahlstatus',
      accepted: 'Akzeptiert',
      addItem: 'Gewerk hinzufügen',
      addDescription: 'Beschreibung hinzufügen...',
      addTitleOrService: 'Titel oder Leistung hinzufügen...',
      resetItem: 'Alle Werte auf 0 setzen',
      ownContribution: {
        enabled: 'Eigenleistung aktiviert',
        enable: 'Eigenleistung aktivieren',
        enableConfirm: {
          title: 'Eigenleistung aktivieren',
          description:
            'Sind Sie sicher, dass Sie die Eigenleistung für diese Position aktivieren möchten?',
        },
        enableSuccess: 'Eigenleistung aktiviert',
        disable: 'Eigenleistung deaktivieren',
        disableConfirm: {
          title: 'Eigenleistung deaktivieren',
          description:
            'Sind Sie sicher, dass Sie die Eigenleistung für diese Position deaktivieren möchten?',
        },
        disableSuccess: 'Eigenleistung deaktiviert',
      },
      changeDescriptionDialog: {
        title: 'Beschreibung ändern',
        note: 'Hier können Sie die Beschreibung der Position bearbeiten.',
        description: 'Beschreibung',
      },
      timeValueCalculationDialog: {
        title: 'Zeitwert berechnen',
        note: 'Hier können Sie unter Angabe einiger Parameter der Zeitwert berechnen.',
        labels: {
          apply: 'Übernehmen',
          sum: 'Neuwert-Summe',
          maxUsageYears: 'Gesamtnutzungsdauer (Jahre)',
          age: 'Aktuelles Alter (Jahre)',
          deduction: 'Abzug',
          correctionConditon: 'Korrektur Zustand (%)',
          actualDeduction: 'Tatsächlicher Abzug',
          timeValue: 'Zeitwert',
        },
      },
      deleteDialog: {
        title: 'Zeile löschen?',
        message: 'Wollen Sie die Zeile wirklich löschen?',
      },
      titleSelect: {
        title: 'Titel',
        description: 'Beschreibung',
        category: 'Kategorie',
        price: 'Preis / Einheit',
        unit: 'Einheit',
      },
      toolbar: {
        create: 'Kategorie erstellen',
        editCategory: 'Kategorien verwalten',
        edit: 'Tabelle ändern',
        addCategory: 'Kategorie hinzufügen',
        addItem: 'Gewerk hinzufügen',
        enableTimeValue: 'Zeitwert aktivieren',
        enableNetCalculation: 'Netto-Kalkulation',
        hideAcceptedColumn: 'Spalte "Akzeptiert" ausblenden',
        exportPDF: 'PDF exportieren',
        exportExcel: 'Excel exportieren',
        exitEdit: 'Bearbeitung beenden',
        insertSum: 'Übernehme von Summe',
        save: 'Als Vorlage speichern',
        load: 'Aus Vorlage laden',
        settings: 'Einstellungen',
      },
      noRecords: {
        title: 'Keine Einträge gefunden',
        description: 'Noch sind keine Einträge vorhanden. Sie können gerne ein Gewerk hinzufügen.',
        descriptionWithoutCategory:
          'Noch sind keine Einträge vorhanden. Um einen Eintrag hinzufügen zu können muss zunächst eine Kategorie erstellt werden. Im Anschluss kann ein Gewerk hinzugefügt werden.',
      },
    },
    dialogs: {
      labels: {
        categoryTitle: 'Kategorie-Titel',
        index: 'Index',
        indexTooltip:
          'Damit können Sie die Reihenfolge bestimmen, diese wird aufsteigend vom Index bestimmt.',
        category: 'Kategorie',
        categoryFieldset: 'Kategorie-Titel',
        colConfig: 'Spaltenname',
        sum: 'Summe Spaltenname',
        accepted: 'Akzeptiert Spaltenname',
      },
      create: {
        title: 'Kategorie erstellen',
      },
      modify: {
        titleRequired: 'Kategorie-Titel ist erforderlich',
        title: 'Kategorien verwalten',
        note: 'Hier können Sie Gruppierungen für die Kalkulation anlegen. Wählen Sie auch einen Sortier-Index aus, um die Kategorien anhand dieser Werte aufsteigend zu sortieren. Beachten Sie: Es muss mindestens eine Kategorie geben, damit Sie ein Gewerk erstellen können.',
        cantCreateWithoutCategory:
          'Sie können kein Gewerk hinzufügen, wenn keine Kategorien vorhanden sind. Bitte erstellen Sie zuerst eine Kategorie.',
      },
      edit: {
        title: 'Tabelle bearbeiten',
      },
      update: {
        title: 'Kategorie aktualisieren',
      },
      createCalculationItem: {
        title: 'Gewerk hinzufügen',
        note: 'Wähle eine Kategorie für das Gewerk aus.',
        category: 'Kategorie',
        selectFromServiceCatalog: 'Aus Katalog wählen',
      },
      selectFromServiceCatalog: {
        title: 'Aus Katalog wählen',
        note: 'Wähle Leistungen aus aus dem Katalog aus und füge sie direkt in die Kalkulation ein.',
        submit: 'Gewählte Gewerke zur Kalkulation hinzufügen',
      },
    },
    notifications: {
      changeActiveServiceCatalog: {
        success: 'Aktiver Leistungskatalog wurde geändert.',
      },
    },
  },
  forms: {
    cancelTaskWithReason: {
      fields: {
        reason: {
          title: 'Grund für Stornierung',
        },
      },
    },
    declineTask: {
      fields: {
        reason: {
          title: 'Grund für Ablehnung',
        },
        note: {
          title: 'Notiz',
        },
      },
    },
    createDrivingtime: {
      fields: {
        driveLogDistance: 'Gefahrene Kilometer',
        driveLogNote: 'Anmerkungen zur Fahrt',
        initialMileage: 'Anfangskilometerstand',
        finalMileage: 'Endkilometerstand',
      },
      warnings: {
        finalMileage:
          'Endkilometerstand muss mindestens so groß sein, wie der Anfangskilometerstand',
      },
    },
    riskCreateTask: {
      resetForm: 'Formular zurücksetzen',
      identifier: 'Vorläufige Besichtigungsnummer: {{identifier}}',
      submit: 'Besichtigung erstellen',
      manualLocation: {
        inactive: 'Koordinateneingabe öffnen',
        active: 'Koordinateneingabe schließen',
      },
      fields: {
        policyHolder: 'Versicherungsnehmer',
        location: 'Risikoort',
        noLocationHint: 'Trage einen Ort ein um alle Besichtiger zu sehen.',
        owner: 'Besichtiger',
        template: 'Formular',
      },
      sections: {
        policyHolder: 'Versicherungsnehmer',
        location: 'Risikoort',
        forms: 'Formular',
        documents: 'Dokumente',
      },
      notifications: {
        submitSuccess: 'Besichtigung wurde erstellt.',
        submitError: 'Besichtigung konnte nicht erstellt werden.',
        uploadError: 'Fehler beim Hochladen der Dateien.',
        requiredFields: 'Bitte füllen Sie alle Pflichtfelder aus.',
        locationRequired: 'Adresse (Karte)',
        latitudeNotFound: 'Breitengrad nicht gefunden',
        longitudeNotFound: 'Längengrad nicht gefunden',
        openEntry: 'Besichtigung öffnen',
      },
    },
    createTask: {
      fields: {
        taskGroupId: {
          label: 'Gruppierung',
        },
        client: {
          label: 'Versicherung',
        },
        clientLocation: {
          label: 'Ort',
        },
        qualifications: {
          label: 'Qualifikationen',
        },
        clientContact: {
          label: 'Sachbearbeiter',
        },
        identifier: {
          label: 'Schadennummer',
        },
        parallelNumber: {
          label: 'Parallel- / Folgeauftrag',
        },
        externalNumber: {
          label: 'Fremdsystem-Nummer',
        },
        visitType: {
          label: 'Auftragstyp',
          nullOption: 'Nicht festgelegt',
        },
        urgent: {
          label: 'Eilschaden',
        },
        addContact: {
          label: 'Kontakt hinzufügen',
        },
        emailPrimary: {
          label: 'Primäre E-Mail',
        },
        emailSecondary: {
          label: 'Sekundäre E-Mail',
        },
        phonePrimary: {
          label: 'Primäre Telefonnummer',
        },
        phoneSecondary: {
          label: 'Sekundäre Telefonnummer',
        },
        addLocation: {
          label: 'Ort hinzufügen',
        },
        locationType: {
          label: 'Typ',
        },
        template: {
          label: 'Formular',
        },
        owner: {
          label: 'Regulierer',
          noLocationHint: 'Trage einen Ort ein um alle Regulierer zu sehen.',
        },
        leader: {
          label: 'Teamleiter',
        },
        reserve: {
          label: 'Reserve',
        },
        policyNumber: {
          label: 'Versicherungsnummer',
        },
        damageDate: {
          label: 'Datum Schaden',
        },
        assignedDate: {
          label: 'Datum Auftragseingang',
        },
        article: {
          label: 'Artikel',
        },
        description: {
          label: 'Schadenbeschreibung',
        },
        damageNote: {
          label: 'Hinweis zur Besichtigung',
        },
        activate: {
          label: 'Auftrag direkt freigeben',
        },
        notes: {
          label: 'Notizen zur Versicherung',
        },
        showManual: {
          active: 'Manuelle Eingabe schließen',
          inactive: 'Manuelle Eingabe öffnen',
        },
      },
      sections: {
        meta: 'Basisdaten',
        contacts: 'Kontakte',
        locations: 'Orte',
        template: 'Formular',
        damage: 'Schaden',
        documents: 'Dokumente',
        driving: 'Fahrzeiten',
      },
      copyToClipboard: 'Kopieren',
      copyToClipboardSuccess: 'Kopiert',
      toggleMap: 'Karte',
      advancedLabel: 'Erweitert',
      overwriteLocationLabel: 'Überschreiben',
      resetForm: 'Formular zurücksetzen',
      submit: 'Auftrag erstellen',
      notifications: {
        submitSuccess: 'Auftrag wurde erstellt.',
        submitError: 'Auftrag konnte nicht erstellt werden.',
        uploadError: 'Fehler beim Hochladen der Dateien.',
        requiredFields: 'Bitte füllen Sie alle Pflichtfelder aus.',
        locationRequired: 'Adresse (Karte)',
        latitudeNotFound: 'Breitengrad nicht gefunden',
        longitudeNotFound: 'Längengrad nicht gefunden',
        openEntry: 'Auftrag öffnen',
      },
      hints: {
        identifierExists: 'Achtung, die von Ihnen gewählte Schadennummer existiert bereits.',
      },
      warnings: {
        noTemplates: {
          title: 'Keine Formulare verfügbar',
          message:
            'Für die von Ihnen gewählte Versicherung sind keine Berichtsfragen verfügbar. Bitte kontaktieren Sie Ihren Administrator oder Teamleiter.',
          hasAbilityMessage:
            'Für die von Ihnen gewählte Versicherung sind keine Berichtsfragen verfügbar. Erstellen sie eine Vorlage für Berichtsfragen oder weisen sie die gewählte Versicherung einer bestehenden Vorlage zu.',
          goToTemplates: 'Zu den Berichtsfragen',
        },
        identifierExists: {
          title: 'Schadennummer existiert bereits',
          message:
            'Für die von Ihnen gewählte Schadennummer “{{identifier}}” existiert bereits ein Auftrag. Wollen Sie diesen Auftrag wirklich mit der selben Schadennummer erzeugen?',
        },
      },
    },
    calculation: {
      fields: {
        title: 'Name der Vorlage',
        template: 'Vorlage',
      },
    },
  },
};
