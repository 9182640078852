// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.97.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.97.1/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.97.1/node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 fill=%27none%27 stroke=%27%23D0D5DD%27 stroke-width=%271.5%27 width=%2724%27 height=%2724%27 viewBox=%27-8 -8 40 40%27%3E%3Crect width=%2718%27 height=%2718%27 x=%273%27 y=%273%27 rx=%272%27 ry=%272%27/%3E%3Ccircle cx=%279%27 cy=%279%27 r=%272%27/%3E%3Cpath d=%27m21 15-3-3a2 2 0 0 0-3 0l-9 9%27/%3E%3C/svg%3E", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FixedRatioImage.isLoading{background-blend-mode:multiply;background-color:inherit;background-image:url(${___CSS_LOADER_URL_REPLACEMENT_0___});background-position:center center;background-repeat:no-repeat;background-size:contain}.FixedRatioImage img{width:100%;height:100%;vertical-align:middle}`, "",{"version":3,"sources":["webpack://./src/modules/common/components/FixedRatioImage.scss"],"names":[],"mappings":"AACE,2BACE,8BAAA,CACA,wBAAA,CAEA,wDAAA,CACA,iCAAA,CACA,2BAAA,CACA,uBAAA,CAGF,qBACE,UAAA,CACA,WAAA,CACA,qBAAA","sourcesContent":[".FixedRatioImage {\n  &.isLoading {\n    background-blend-mode: multiply;\n    background-color: inherit;\n    // https://github.com/lucide-icons/lucide/blob/f4d887339eef15faa931853e2ae378031d33220b/icons/image.svg\n    background-image: url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' stroke='%23D0D5DD' stroke-width='1.5' width='24' height='24' viewBox='-8 -8 40 40'%3E%3Crect width='18' height='18' x='3' y='3' rx='2' ry='2'/%3E%3Ccircle cx='9' cy='9' r='2'/%3E%3Cpath d='m21 15-3-3a2 2 0 0 0-3 0l-9 9'/%3E%3C/svg%3E\");\n    background-position: center center;\n    background-repeat: no-repeat;\n    background-size: contain;\n  }\n\n  img {\n    width: 100%;\n    height: 100%;\n    vertical-align: middle;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
