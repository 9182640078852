import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type NumSubTasksOpenQueryVariables = Types.Exact<{
  task_id: Types.Scalars['ID']['input'];
}>;

export type NumSubTasksOpenQuery = {
  __typename?: 'Query';
  numSubTasksOpen: { __typename?: 'NumSubTasksOpenResponse'; count: number };
};

export const NumSubTasksOpenDocument = gql`
  query NumSubTasksOpen($task_id: ID!) {
    numSubTasksOpen(task_id: $task_id) {
      count
    }
  }
`;

export function useNumSubTasksOpenQuery(
  options: Omit<Urql.UseQueryArgs<NumSubTasksOpenQueryVariables>, 'query'>,
) {
  return Urql.useQuery<NumSubTasksOpenQuery, NumSubTasksOpenQueryVariables>({
    query: NumSubTasksOpenDocument,
    ...options,
  });
}
