import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ActivateTaskCalculationTemplateMutationVariables = Types.Exact<{
  taskId: Types.Scalars['ID']['input'];
  templateId: Types.Scalars['ID']['input'];
}>;

export type ActivateTaskCalculationTemplateMutation = {
  __typename?: 'Mutation';
  activateTaskCalculationTemplate: {
    __typename?: 'ResponseMessage';
    success?: boolean | null;
    message?: string | null;
  };
};

export const ActivateTaskCalculationTemplateDocument = gql`
  mutation ActivateTaskCalculationTemplate($taskId: ID!, $templateId: ID!) {
    activateTaskCalculationTemplate(task_id: $taskId, task_calculation_template_id: $templateId) {
      success
      message
    }
  }
`;

export function useActivateTaskCalculationTemplateMutation() {
  return Urql.useMutation<
    ActivateTaskCalculationTemplateMutation,
    ActivateTaskCalculationTemplateMutationVariables
  >(ActivateTaskCalculationTemplateDocument);
}
