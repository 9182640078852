import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type RenameTaskFolderMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  name: Types.Scalars['String']['input'];
}>;

export type RenameTaskFolderMutation = {
  __typename?: 'Mutation';
  renameTaskFolder?: { __typename?: 'TaskFolder'; id: string } | null;
};

export const RenameTaskFolderDocument = gql`
  mutation RenameTaskFolder($id: ID!, $name: String!) {
    renameTaskFolder(id: $id, name: $name) {
      id
    }
  }
`;

export function useRenameTaskFolderMutation() {
  return Urql.useMutation<RenameTaskFolderMutation, RenameTaskFolderMutationVariables>(
    RenameTaskFolderDocument,
  );
}
