import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import { TaskFileFieldsFragmentDoc } from './taskFoldersAndFiles.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type EditDuplicateFileMutationVariables = Types.Exact<{
  file_id: Types.Scalars['ID']['input'];
}>;

export type EditDuplicateFileMutation = {
  __typename?: 'Mutation';
  editDuplicateTaskFile: {
    __typename?: 'FileItem';
    id: string;
    name: string;
    original_file_name: string;
    size: number;
    mime_type?: string | null;
    created_at?: string | null;
    updated_at?: string | null;
    url: string;
    folder_id?: string | null;
    permissions: Array<Types.Permission>;
    client?: {
      __typename?: 'Client';
      name: string;
      job_title?: string | null;
      client_type?: { __typename?: 'ClientType'; title: string } | null;
    } | null;
    custom_properties: {
      __typename?: 'FileItemCustomProperties';
      invoice?: {
        __typename?: 'FileItemInvoiceMetaData';
        identifier: string;
        sum_gross: number;
        sum_net: number;
        sum_tax: number;
      } | null;
    };
    classification_tags: {
      __typename?: 'FileClassificationTagList';
      data: Array<{
        __typename?: 'FileClassificationTag';
        id: string;
        title: string;
        color: string;
        alias: string;
        type_id: Types.FileClassificationTagTypeId;
      }>;
    };
    links: Array<{
      __typename?: 'FileLink';
      file_id: string;
      linkable_type: string;
      linkable_id: string;
      file?: { __typename?: 'FileItem'; id: string; name: string } | null;
    }>;
  };
};

export const EditDuplicateFileDocument = gql`
  mutation EditDuplicateFile($file_id: ID!) {
    editDuplicateTaskFile(file_id: $file_id) {
      ...TaskFileFields
    }
  }
  ${TaskFileFieldsFragmentDoc}
`;

export function useEditDuplicateFileMutation() {
  return Urql.useMutation<EditDuplicateFileMutation, EditDuplicateFileMutationVariables>(
    EditDuplicateFileDocument,
  );
}
