import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UploadTaskReworkDocumentMutationVariables = Types.Exact<{
  taskId: Types.Scalars['ID']['input'];
  file: Types.UploadFileInput;
}>;

export type UploadTaskReworkDocumentMutation = {
  __typename?: 'Mutation';
  uploadTaskReworkDocuments: {
    __typename?: 'TaskReworkDocumentsPaginator';
    data: Array<{ __typename?: 'FileItem'; id: string }>;
  };
};

export const UploadTaskReworkDocumentDocument = gql`
  mutation UploadTaskReworkDocument($taskId: ID!, $file: UploadFileInput!) {
    uploadTaskReworkDocuments(task_id: $taskId, files: [$file]) {
      data {
        id
      }
    }
  }
`;

export function useUploadTaskReworkDocumentMutation() {
  return Urql.useMutation<
    UploadTaskReworkDocumentMutation,
    UploadTaskReworkDocumentMutationVariables
  >(UploadTaskReworkDocumentDocument);
}
