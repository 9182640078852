export default {
  tasks: {
    generalSection: {
      labels: {
        tasks_leader_visible_all:
          'Abteilungen können alle Aufträge sehen (nicht nur ihnen zugewiesene Aufträge)',
        auto_accept_task:
          'Automatische Auftragsannahme - der Auftrag wird automatisch um 22:00 von dem Experten auf “angenommen” gesetzt.',
        adjuster_can_create_tasks: 'Experte kann Aufträge erstellen',
        leader_can_assign: 'Abteilung kann Aufträge zuweisen',
        allow_adjuster_to_send_emails: 'Experte darf E-Mails senden',
        adjuster_can_duplicate_own_tasks: 'Experte kann eigene Aufträge duplizieren',
        adjuster_can_change_task_location: 'Experte kann Besichtigungsort anpassen',
        adjuster_can_set_rework: 'Experte kann den Auftrag in Nacherfassung setzen',
        adjuster_can_send_interface: 'Experte kann Dateien per Schnittstelle senden',
      },
    },
    tasksSection: {
      tasks_max_sub_owner_count_hint:
        'Wird hier ein Wert größer 0 eingetragen, ist es möglich, dass dem Auftrag zu dem normalen Experten, weitere Vertretungen hinzugefügt werden können. Diese Vertretungen können dann auch Aufgaben im Auftrag übernehmen.',
      labels: {
        tasks_notify_owner_on_create:
          'Experte erhält eine Nachricht, wenn ein Auftrag für ihn angelegt wurde',
        tasks_notify_owner_on_new_file:
          'Experte erhält eine Nachricht, wenn eine Datei in den Ordner Nacharbeiten hochgeladen wurde',
        tasks_auto_assign_leader_when_empty:
          'Abteilungen werden automatisch zugewiesen, falls diese nicht gesetzt wurden.',
        tasks_num_routes: 'Anzahl der Routen, die als Experte auf der Karte angezeigt werden',
        tasks_fields_group_active: 'Sparte kann bei Auftragsanlage eingetragen werden',
        allow_adjusters_rename_files: 'Experte darf alle Dokumente in seinen Aufträgen umbenennen',
        allow_adjusters_update_file_classification_tags:
          'Experte darf Datei-Klassifikationen für alle Dokumente in seinen Aufträgen setzen',
      },
    },
    reportSection: {
      labels: {
        show_adjuster_email_service_provider_block: 'Zeige Experten-E-Mail in Dienstleister-Block',
      },
    },
  },
};
