import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ActivateAppEmailTransportMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  active: Types.Scalars['Boolean']['input'];
}>;

export type ActivateAppEmailTransportMutation = {
  __typename?: 'Mutation';
  activateAppEmailTransport: { __typename?: 'AppEmailTransport'; id: string; active: boolean };
};

export const ActivateAppEmailTransportDocument = gql`
  mutation ActivateAppEmailTransport($id: ID!, $active: Boolean!) {
    activateAppEmailTransport(id: $id, active: $active) {
      id
      active
    }
  }
`;

export function useActivateAppEmailTransportMutation() {
  return Urql.useMutation<
    ActivateAppEmailTransportMutation,
    ActivateAppEmailTransportMutationVariables
  >(ActivateAppEmailTransportDocument);
}
