import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type RevertTaskFromControllingMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;

export type RevertTaskFromControllingMutation = {
  __typename?: 'Mutation';
  revertTaskFromControlling?: {
    __typename?: 'ResponseMessage';
    message?: string | null;
    success?: boolean | null;
  } | null;
};

export const RevertTaskFromControllingDocument = gql`
  mutation RevertTaskFromControlling($id: ID!) {
    revertTaskFromControlling(id: $id) {
      message
      success
    }
  }
`;

export function useRevertTaskFromControllingMutation() {
  return Urql.useMutation<
    RevertTaskFromControllingMutation,
    RevertTaskFromControllingMutationVariables
  >(RevertTaskFromControllingDocument);
}
