import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import { AppMessageFragmentDoc } from './appMessageFragment.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateAppMessageMutationVariables = Types.Exact<{
  app_message_id: Types.Scalars['ID']['input'];
  input?: Types.InputMaybe<Types.UpdateAppMessageInput>;
}>;

export type UpdateAppMessageMutation = {
  __typename?: 'Mutation';
  updateAppMessage: {
    __typename?: 'AppMessage';
    id: string;
    title: string;
    body: string;
    type: Types.PageNotificationType;
    author_id: string;
    active: boolean;
    visible_from?: string | null;
    visible_to?: string | null;
    author?: { __typename?: 'Client'; id: string; name: string } | null;
  };
};

export const UpdateAppMessageDocument = gql`
  mutation UpdateAppMessage($app_message_id: ID!, $input: UpdateAppMessageInput) {
    updateAppMessage(app_message_id: $app_message_id, input: $input) {
      ...AppMessage
    }
  }
  ${AppMessageFragmentDoc}
`;

export function useUpdateAppMessageMutation() {
  return Urql.useMutation<UpdateAppMessageMutation, UpdateAppMessageMutationVariables>(
    UpdateAppMessageDocument,
  );
}
