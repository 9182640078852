import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ActivateTaskMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;

export type ActivateTaskMutation = {
  __typename?: 'Mutation';
  activateTask?: {
    __typename?: 'ResponseMessage';
    success?: boolean | null;
    message?: string | null;
  } | null;
};

export const ActivateTaskDocument = gql`
  mutation ActivateTask($id: ID!) {
    activateTask(id: $id) {
      success
      message
    }
  }
`;

export function useActivateTaskMutation() {
  return Urql.useMutation<ActivateTaskMutation, ActivateTaskMutationVariables>(
    ActivateTaskDocument,
  );
}
