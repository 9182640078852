import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AddPdfTemplateToTaskDocumentsMutationVariables = Types.Exact<{
  taskId: Types.Scalars['ID']['input'];
  pdfTemplateId: Types.Scalars['ID']['input'];
}>;

export type AddPdfTemplateToTaskDocumentsMutation = {
  __typename?: 'Mutation';
  addPdfTemplateToTaskDocuments?: boolean | null;
};

export const AddPdfTemplateToTaskDocumentsDocument = gql`
  mutation AddPdfTemplateToTaskDocuments($taskId: ID!, $pdfTemplateId: ID!) {
    addPdfTemplateToTaskDocuments(task_id: $taskId, pdf_template_id: $pdfTemplateId)
  }
`;

export function useAddPdfTemplateToTaskDocumentsMutation() {
  return Urql.useMutation<
    AddPdfTemplateToTaskDocumentsMutation,
    AddPdfTemplateToTaskDocumentsMutationVariables
  >(AddPdfTemplateToTaskDocumentsDocument);
}
