import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UploadAlternativeReportLogoMutationVariables = Types.Exact<{
  file?: Types.InputMaybe<Types.Scalars['Upload']['input']>;
}>;

export type UploadAlternativeReportLogoMutation = {
  __typename?: 'Mutation';
  uploadAlternateReportLogo: {
    __typename?: 'AppSettings';
    login_logo?: { __typename?: 'FileItem'; file_name: string; url: string; id: string } | null;
  };
};

export const UploadAlternativeReportLogoDocument = gql`
  mutation UploadAlternativeReportLogo($file: Upload) {
    uploadAlternateReportLogo(file: $file) {
      login_logo {
        file_name
        url
        id
      }
    }
  }
`;

export function useUploadAlternativeReportLogoMutation() {
  return Urql.useMutation<
    UploadAlternativeReportLogoMutation,
    UploadAlternativeReportLogoMutationVariables | void
  >(UploadAlternativeReportLogoDocument);
}
