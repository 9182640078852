import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GeolocateQueryVariables = Types.Exact<{
  street: Types.Scalars['String']['input'];
  postcode: Types.Scalars['String']['input'];
  city: Types.Scalars['String']['input'];
  country_id: Types.Scalars['Int']['input'];
}>;

export type GeolocateQuery = {
  __typename?: 'Query';
  geolocate?: { __typename?: 'Geolocate'; latitude: number; longitude: number } | null;
};

export const GeolocateDocument = gql`
  query Geolocate($street: String!, $postcode: String!, $city: String!, $country_id: Int!) {
    geolocate(street: $street, postcode: $postcode, city: $city, country_id: $country_id) {
      latitude
      longitude
    }
  }
`;

export function useGeolocateQuery(
  options: Omit<Urql.UseQueryArgs<GeolocateQueryVariables>, 'query'>,
) {
  return Urql.useQuery<GeolocateQuery, GeolocateQueryVariables>({
    query: GeolocateDocument,
    ...options,
  });
}
