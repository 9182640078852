import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CreateTaskFilesDownloadMutationVariables = Types.Exact<{
  task_id: Types.Scalars['ID']['input'];
  input: Types.CreateDownloadInput;
}>;

export type CreateTaskFilesDownloadMutation = {
  __typename?: 'Mutation';
  createTaskFilesDownload: {
    __typename?: 'Download';
    id: string;
    state_id: Types.DownloadStateId;
    filename: string;
    file_ids: Array<string>;
    url_download: string;
  };
};

export const CreateTaskFilesDownloadDocument = gql`
  mutation CreateTaskFilesDownload($task_id: ID!, $input: CreateDownloadInput!) {
    createTaskFilesDownload(task_id: $task_id, input: $input) {
      id
      state_id
      filename
      file_ids
      url_download
    }
  }
`;

export function useCreateTaskFilesDownloadMutation() {
  return Urql.useMutation<
    CreateTaskFilesDownloadMutation,
    CreateTaskFilesDownloadMutationVariables
  >(CreateTaskFilesDownloadDocument);
}
