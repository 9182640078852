import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ToggleAppAllowlistMutationVariables = Types.Exact<{
  active: Types.Scalars['Boolean']['input'];
}>;

export type ToggleAppAllowlistMutation = {
  __typename?: 'Mutation';
  toggleAppAllowlist: {
    __typename?: 'ResponseMessage';
    success?: boolean | null;
    message?: string | null;
  };
};

export const ToggleAppAllowlistDocument = gql`
  mutation ToggleAppAllowlist($active: Boolean!) {
    toggleAppAllowlist(active: $active) {
      success
      message
    }
  }
`;

export function useToggleAppAllowlistMutation() {
  return Urql.useMutation<ToggleAppAllowlistMutation, ToggleAppAllowlistMutationVariables>(
    ToggleAppAllowlistDocument,
  );
}
