import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DeleteTaskRoomItemMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;

export type DeleteTaskRoomItemMutation = {
  __typename?: 'Mutation';
  deleteTaskRoomItem: {
    __typename?: 'TaskRoomItem';
    description?: string | null;
    id: string;
    size?: number | null;
    task_id: string;
    title?: string | null;
    category?: { __typename?: 'TaskRoomCategory'; id: string; title: string } | null;
  };
};

export const DeleteTaskRoomItemDocument = gql`
  mutation DeleteTaskRoomItem($id: ID!) {
    deleteTaskRoomItem(id: $id) {
      category {
        id
        title
      }
      description
      id
      size
      task_id
      title
    }
  }
`;

export function useDeleteTaskRoomItemMutation() {
  return Urql.useMutation<DeleteTaskRoomItemMutation, DeleteTaskRoomItemMutationVariables>(
    DeleteTaskRoomItemDocument,
  );
}
