import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateTaskFileMutationVariables = Types.Exact<{
  file_id: Types.Scalars['ID']['input'];
  file?: Types.InputMaybe<Types.Scalars['Upload']['input']>;
  meta_data?: Types.InputMaybe<Types.Scalars['JSON']['input']>;
}>;

export type UpdateTaskFileMutation = {
  __typename?: 'Mutation';
  updateTaskFile: { __typename?: 'FileItem'; id: string };
};

export const UpdateTaskFileDocument = gql`
  mutation UpdateTaskFile($file_id: ID!, $file: Upload, $meta_data: JSON) {
    updateTaskFile(file_id: $file_id, file: $file, meta_data: $meta_data) {
      id
    }
  }
`;

export function useUpdateTaskFileMutation() {
  return Urql.useMutation<UpdateTaskFileMutation, UpdateTaskFileMutationVariables>(
    UpdateTaskFileDocument,
  );
}
