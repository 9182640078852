import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CancelTaskMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  input: Types.CancelTaskInput;
}>;

export type CancelTaskMutation = {
  __typename?: 'Mutation';
  cancelTask?: {
    __typename?: 'ResponseMessage';
    success?: boolean | null;
    message?: string | null;
  } | null;
};

export const CancelTaskDocument = gql`
  mutation CancelTask($id: ID!, $input: CancelTaskInput!) {
    cancelTask(id: $id, input: $input) {
      success
      message
    }
  }
`;

export function useCancelTaskMutation() {
  return Urql.useMutation<CancelTaskMutation, CancelTaskMutationVariables>(CancelTaskDocument);
}
