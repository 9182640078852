import {
  DropDownList,
  DropDownListHandle,
  DropDownListProps,
} from '@progress/kendo-react-dropdowns';
import { FieldWrapper } from '@progress/kendo-react-form';
import { Loader } from '@progress/kendo-react-indicators';
import { CSSProperties, useId, useRef } from 'react';

import { Label } from '../components/Label';
import { FieldError } from './FieldError';
import { FieldHint } from './FieldHint';
import { CommonFieldRenderProps } from './types';

export type DropDownListInputProps = CommonFieldRenderProps &
  Pick<
    DropDownListProps,
    | 'size'
    | 'id'
    | 'value'
    | 'required'
    | 'disabled'
    | 'data'
    | 'dataItemKey'
    | 'itemRender'
    | 'valueRender'
    | 'textField'
    | 'defaultItem'
    | 'onBlur'
    | 'onChange'
    | 'onFocus'
    | 'filterable'
    | 'onFilterChange'
  > & {
    isLoading?: boolean;
    width?: string;
    hintClass?: string;
    hintTooltipStyle?: CSSProperties;
  };

export const DropDownListInput = (props: DropDownListInputProps) => {
  const defaultId = useId();
  const {
    size = 'large',
    value,
    id = defaultId,
    valid,
    required,
    disabled,
    data,
    dataItemKey = 'id',
    itemRender,
    textField = 'text',
    isLoading,
    label,
    filterable,
    onFilterChange,
    defaultItem,
    valueRender,
    onBlur,
    onChange,
    onFocus,
    name,
    width,
    tooltip,
    tooltipPosition,
  } = props;
  const labelId = label && `${id}_label`;

  const editorRef = useRef<DropDownListHandle>(null);

  return (
    <FieldWrapper style={{ width }}>
      {label && (
        <Label
          tooltip={tooltip}
          tooltipPosition={tooltipPosition}
          label={label}
          id={labelId}
          editorRef={editorRef}
          name={name}
          editorId={id}
          editorValid={valid}
        >
          {label}
        </Label>
      )}

      <div style={{ position: 'relative' }}>
        <DropDownList
          size={size}
          ref={editorRef}
          data={data}
          dataItemKey={dataItemKey}
          textField={textField}
          value={value ?? null}
          id={id}
          ariaLabelledBy={labelId}
          defaultItem={defaultItem}
          valueRender={valueRender}
          itemRender={itemRender}
          onFilterChange={onFilterChange}
          filterable={filterable}
          disabled={disabled}
          required={required}
          onBlur={onBlur}
          onChange={onChange}
          onFocus={onFocus}
        />
        {isLoading && (
          <Loader
            type="converging-spinner"
            size={'small'}
            style={{ position: 'absolute', right: '2rem', top: '50%', marginTop: '-0.5rem' }}
          />
        )}
      </div>
      <div className="HintAndError">
        <FieldHint {...props} />
        <FieldError {...props} />
      </div>
    </FieldWrapper>
  );
};
