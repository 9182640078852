import '@module/shared/yup/checkForbiddenPrefix';

import { ROUTER_BASENAME } from '@env';
import { AuthProvider, useAuthContext, useIsAuthenticated } from '@module/auth';
import { CanAppSettings, MeQueryProvider, useMeQueryContext } from '@module/casl';
import { DownloadsProvider, Loader } from '@module/common';
import { HistoryProvider } from '@module/common/components/history/HistoryContext';
import { IpAllowlistGuard } from '@module/common/components/IpAllowlistGuard';
import { OfflineNotification } from '@module/common/components/notifications/OfflineNotification';
import { UrqlClient } from '@module/graphql';
import { ThemeProvider } from '@module/layout';
import { PrivateLayoutOutlet } from '@module/private';
import {
  ForgotPasswordPage,
  ImprintPage,
  InitialLoginPage,
  LoginPage,
  MaintenancePage,
  NoPermissionPage,
  NotFoundPage,
  ResetPasswordPage,
  VerifyEmailPage,
} from '@module/public';
import { DialogsContainer, DialogsProvider } from '@module/shared/dialogs';
import { isNotNullish } from '@module/shared/helpers';
import { usePrevious } from '@module/shared/hooks';
import { LocaleProvider, LocalizationProvider } from '@module/shared/localization';
import { NotificationsContainer, NotificationsProvider } from '@module/shared/notifications';
import { PopupProps, PopupPropsContext } from '@progress/kendo-react-popup';
import * as Sentry from '@sentry/react';
import { lazy, ReactNode, Suspense, useEffect, useLayoutEffect } from 'react';
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import i18n from './localization';

const ClientsRoutes = lazy(() => import('@module/clients/ClientsRoutes'));
const PolicyHoldersRoutes = lazy(() => import('@module/policy-holders/PolicyHoldersRoutes'));
const DashboardRoutes = lazy(() => import('@module/dashboard/DashboardRoutes'));
const SubTasksRoutes = lazy(() => import('@module/tasks/pages/SubTasks/SubTasksRoutes'));
const TasksRoutes = lazy(() => import('@module/tasks/TasksRoutes'));
const EventRoutes = lazy(() => import('@module/events/EventsRoutes'));
const VideoRoutes = lazy(() => import('@module/video/VideoRoutes'));
const CustomerFormRoutes = lazy(() => import('@module/customer-form/CustomerFormRoutes'));
const ThemeDevPage = lazy(() => import('./modules/theme-dev/ThemeDevPage'));
const InvoicesRoutes = lazy(() => import('./modules/invoices/InvoicesRoutes'));
const ProposalsRoutes = lazy(() => import('./modules/proposals/ProposalsRoutes'));
const PayoutsRoutes = lazy(() => import('./modules/payouts/PayoutRoutes'));
const SettingsRoutes = lazy(() => import('@module/common/SettingsRoutes'));

/**
 * Scrolls the page up when navigating to a different path.
 *
 * PopStateEvents (back/forward) are excluded to allow browsers to restore
 * scroll positions for existing history entries by themselves.
 *
 * This can probably done better with React Router's <ScrollRestoration />
 * component after switching to the new data API:
 * https://reactrouter.com/en/main/components/scroll-restoration
 */
const ScrollManager = () => {
  const navigationType = useNavigationType();
  const { pathname } = useLocation();
  const prevPathname = usePrevious(pathname);

  useLayoutEffect(() => {
    if (pathname !== prevPathname && navigationType !== 'POP') {
      document.body.scrollTo(0, 0);
    }
  }, [pathname, prevPathname, navigationType]);

  return null;
};

const MeQueryProviderWithAuth = (props: { children: ReactNode }) => {
  const { authState } = useAuthContext();

  return <MeQueryProvider paused={!authState?.data.access_token} {...props} />;
};

const SyncUserToSentry = () => {
  const meQueryContext = useMeQueryContext();
  const { authStatePrevious } = useAuthContext();
  const role = meQueryContext.data?.me?.role;
  const hasAuthStatePrevious = isNotNullish(authStatePrevious);

  useEffect(() => {
    Sentry.setUser({
      Role: role ? `${role}${hasAuthStatePrevious ? ' (impersonated)' : ''}` : 'PUBLIC',
    });
  }, [role, hasAuthStatePrevious]);

  return null;
};

const NavigateToLoginOrDashboard = () => {
  const isAuthenticated = useIsAuthenticated();

  return <Navigate to={isAuthenticated ? '/dashboard' : '/login'} replace={true} />;
};

const popupPropsContextValue = (defaultProps: PopupProps): PopupProps => ({
  ...defaultProps,
  // Disable close animations globally. See IN-5523 for the reasoning behind this.
  animate: {
    openDuration: 200,
    closeDuration: 0,
  },
});
const PopupPropsProvider = (props: { children: ReactNode }) => (
  <PopupPropsContext.Provider {...props} value={popupPropsContextValue} />
);

const App = () => {
  return (
    <IpAllowlistGuard>
      <AuthProvider>
        <LocaleProvider i18n={i18n}>
          <UrqlClient>
            <MeQueryProviderWithAuth>
              <SyncUserToSentry />

              <ThemeProvider>
                <LocalizationProvider>
                  <BrowserRouter basename={ROUTER_BASENAME}>
                    <ScrollManager />

                    <PopupPropsProvider>
                      <DialogsProvider>
                        <DownloadsProvider>
                          <OfflineNotification />

                          <NotificationsProvider>
                            <Suspense
                              fallback={
                                <div>
                                  <Loader />
                                </div>
                              }
                            >
                              <HistoryProvider>
                                <Routes>
                                  <Route index element={<NavigateToLoginOrDashboard />} />
                                  <Route
                                    path="/initial-login/:token"
                                    element={<InitialLoginPage />}
                                  />
                                  <Route path="/login" element={<LoginPage />} />
                                  <Route path="/forgot-password" element={<ForgotPasswordPage />} />
                                  <Route path="/reset-password" element={<ResetPasswordPage />} />
                                  <Route path="/verify/:token" element={<VerifyEmailPage />} />
                                  <Route path="/imprint" element={<ImprintPage />} />
                                  <Route path="/maintenance" element={<MaintenancePage />} />
                                  <Route path="/video/*" element={<VideoRoutes />} />
                                  <Route
                                    path="/dev/customer-form/*"
                                    element={<CustomerFormRoutes />}
                                  />

                                  <Route element={<PrivateLayoutOutlet />}>
                                    <Route path="/dashboard/*" element={<DashboardRoutes />} />
                                    <Route path="/clients/*" element={<ClientsRoutes />} />
                                    <Route
                                      path="/policy-holders/*"
                                      element={<PolicyHoldersRoutes />}
                                    />
                                    <Route path="/tasks/*" element={<TasksRoutes />} />
                                    <Route path="/sub-tasks/*" element={<SubTasksRoutes />} />
                                    <Route path="/events/*" element={<EventRoutes />} />
                                    <Route path="/invoices/*" element={<InvoicesRoutes />} />
                                    <Route path="/proposals/*" element={<ProposalsRoutes />} />
                                    <Route path="/payouts/*" element={<PayoutsRoutes />} />
                                    <Route
                                      path="/settings/*"
                                      element={
                                        <CanAppSettings
                                          action="navigateAppSettings"
                                          failure={<NoPermissionPage />}
                                        >
                                          <SettingsRoutes />
                                        </CanAppSettings>
                                      }
                                    />
                                  </Route>
                                  <Route path="/theme-dev" element={<ThemeDevPage />} />
                                  <Route path="*" element={<NotFoundPage />} />
                                </Routes>
                              </HistoryProvider>
                            </Suspense>
                            <DialogsContainer />
                            <NotificationsContainer />
                          </NotificationsProvider>
                        </DownloadsProvider>
                      </DialogsProvider>
                    </PopupPropsProvider>
                  </BrowserRouter>
                </LocalizationProvider>
              </ThemeProvider>
            </MeQueryProviderWithAuth>
          </UrqlClient>
        </LocaleProvider>
      </AuthProvider>
    </IpAllowlistGuard>
  );
};

export default App;
