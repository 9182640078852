import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import { MisspellingFragmentDoc } from './misspellingFragment.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type SpellCheckQueryVariables = Types.Exact<{
  values: Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input'];
  locale: Types.Scalars['String']['input'];
}>;

export type SpellCheckQuery = {
  __typename?: 'Query';
  spellCheck: Array<{
    __typename?: 'SpellCheckResult';
    value: string;
    misspellings: Array<{
      __typename?: 'Misspelling';
      word: string;
      suggestions: Array<string>;
      lineNumber?: number | null;
      offset?: number | null;
    }>;
  }>;
};

export const SpellCheckDocument = gql`
  query SpellCheck($values: [String!]!, $locale: String!) {
    spellCheck(values: $values, locale: $locale) {
      value
      misspellings {
        ...Misspelling
      }
    }
  }
  ${MisspellingFragmentDoc}
`;

export function useSpellCheckQuery(
  options: Omit<Urql.UseQueryArgs<SpellCheckQueryVariables>, 'query'>,
) {
  return Urql.useQuery<SpellCheckQuery, SpellCheckQueryVariables>({
    query: SpellCheckDocument,
    ...options,
  });
}
