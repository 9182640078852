import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateTaskCustomReportSettingMutationVariables = Types.Exact<{
  task_id: Types.Scalars['ID']['input'];
  input: Types.TaskCustomReportSettingInput;
}>;

export type UpdateTaskCustomReportSettingMutation = {
  __typename?: 'Mutation';
  updateTaskCustomReportSetting: {
    __typename?: 'TaskCustomReportSetting';
    task_id: string;
    title: string;
    has_cover: boolean;
    report_template_id: string;
    hidden_questions_id?: Array<{
      __typename?: 'TaskCustomReportSettingQuestion';
      question_id: string;
    }> | null;
  };
};

export const UpdateTaskCustomReportSettingDocument = gql`
  mutation UpdateTaskCustomReportSetting($task_id: ID!, $input: TaskCustomReportSettingInput!) {
    updateTaskCustomReportSetting(task_id: $task_id, input: $input) {
      task_id
      title
      has_cover
      report_template_id
      hidden_questions_id {
        question_id
      }
    }
  }
`;

export function useUpdateTaskCustomReportSettingMutation() {
  return Urql.useMutation<
    UpdateTaskCustomReportSettingMutation,
    UpdateTaskCustomReportSettingMutationVariables
  >(UpdateTaskCustomReportSettingDocument);
}
