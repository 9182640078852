import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateTaskRoomItemMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  input: Types.TaskRoomItemInput;
}>;

export type UpdateTaskRoomItemMutation = {
  __typename?: 'Mutation';
  updateTaskRoomItem?: {
    __typename?: 'TaskRoomItem';
    description?: string | null;
    id: string;
    size?: number | null;
    height?: number | null;
    width?: number | null;
    uuid: string;
    length?: number | null;
    task_id: string;
    title?: string | null;
    task_room_category_id: string;
    category?: { __typename?: 'TaskRoomCategory'; id: string; title: string } | null;
    service_catalog_items?: Array<{
      __typename?: 'ServiceCatalogItem';
      id: string;
      title: string;
    }> | null;
  } | null;
};

export const UpdateTaskRoomItemDocument = gql`
  mutation UpdateTaskRoomItem($id: ID!, $input: TaskRoomItemInput!) {
    updateTaskRoomItem(id: $id, input: $input) {
      category {
        id
        title
      }
      description
      id
      size
      height
      width
      uuid
      length
      task_id
      title
      task_room_category_id
      service_catalog_items {
        id
        title
      }
    }
  }
`;

export function useUpdateTaskRoomItemMutation() {
  return Urql.useMutation<UpdateTaskRoomItemMutation, UpdateTaskRoomItemMutationVariables>(
    UpdateTaskRoomItemDocument,
  );
}
