import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type TaskArticlesQueryVariables = Types.Exact<{
  task_id: Types.Scalars['ID']['input'];
}>;

export type TaskArticlesQuery = {
  __typename?: 'Query';
  taskArticles: {
    __typename?: 'TaskArticlesPaginator';
    data: Array<{
      __typename?: 'Article';
      id: string;
      title: string;
      description?: string | null;
      sales_price?: number | null;
      tax: number;
      unit?: { __typename?: 'ArticleUnit'; id: string; title: string } | null;
    }>;
  };
};

export const TaskArticlesDocument = gql`
  query TaskArticles($task_id: ID!) {
    taskArticles(task_id: $task_id) {
      data {
        id
        title
        description
        sales_price
        tax
        unit {
          id
          title
        }
      }
    }
  }
`;

export function useTaskArticlesQuery(
  options: Omit<Urql.UseQueryArgs<TaskArticlesQueryVariables>, 'query'>,
) {
  return Urql.useQuery<TaskArticlesQuery, TaskArticlesQueryVariables>({
    query: TaskArticlesDocument,
    ...options,
  });
}
