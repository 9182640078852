import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type SyncMagicplanMutationVariables = Types.Exact<{
  taskId: Types.Scalars['ID']['input'];
}>;

export type SyncMagicplanMutation = {
  __typename?: 'Mutation';
  syncMagicPlan: {
    __typename?: 'ResponseMessage';
    success?: boolean | null;
    message?: string | null;
  };
};

export const SyncMagicplanDocument = gql`
  mutation SyncMagicplan($taskId: ID!) {
    syncMagicPlan(task_id: $taskId) {
      success
      message
    }
  }
`;

export function useSyncMagicplanMutation() {
  return Urql.useMutation<SyncMagicplanMutation, SyncMagicplanMutationVariables>(
    SyncMagicplanDocument,
  );
}
