import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DeleteCustomerPortalEventFileMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;

export type DeleteCustomerPortalEventFileMutation = {
  __typename?: 'Mutation';
  deleteCustomerPortalEventFile: { __typename?: 'CustomerPortalEventFile'; id: string };
};

export const DeleteCustomerPortalEventFileDocument = gql`
  mutation DeleteCustomerPortalEventFile($id: ID!) {
    deleteCustomerPortalEventFile(id: $id) {
      id
    }
  }
`;

export function useDeleteCustomerPortalEventFileMutation() {
  return Urql.useMutation<
    DeleteCustomerPortalEventFileMutation,
    DeleteCustomerPortalEventFileMutationVariables
  >(DeleteCustomerPortalEventFileDocument);
}
