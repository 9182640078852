import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DeleteAppAllowlistIpAddressMutationVariables = Types.Exact<{
  ip: Types.Scalars['IPv6']['input'];
}>;

export type DeleteAppAllowlistIpAddressMutation = {
  __typename?: 'Mutation';
  deleteAppAllowlistIpAddress: { __typename?: 'AppAllowlistIpAddress'; ip: string };
};

export const DeleteAppAllowlistIpAddressDocument = gql`
  mutation DeleteAppAllowlistIpAddress($ip: IPv6!) {
    deleteAppAllowlistIpAddress(ip: $ip) {
      ip
    }
  }
`;

export function useDeleteAppAllowlistIpAddressMutation() {
  return Urql.useMutation<
    DeleteAppAllowlistIpAddressMutation,
    DeleteAppAllowlistIpAddressMutationVariables
  >(DeleteAppAllowlistIpAddressDocument);
}
